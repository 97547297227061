import React, { FormEvent, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import classes from "./AddGoals.module.scss"
import { AccountService } from "@klutch-card/klutch-js"
import { Analytics } from "../../services/analytics"

const OPTIONS = [
  "Save on Subscriptions",
  "Understand my Spending",
  "Create and track a Budget",
  "Automate Actions to Save Time",
  "Protect my Data",
  "Manage my Split Bills",
  "Build my Own MiniApp",
  "Auto Categorize Expenses",
  "Track money flows in Personal and Business",
  "Add Controls on Spending",
  "Optimize Cash Flow",
  "Save Effort with Customized Features ",
]

interface OptionProps {
  label: string
  isSelected: boolean
  onPress: () => void
}

const Option: React.FC<OptionProps> = ({ label, isSelected, onPress }) => (
  <div
    className={classes.option}
    style={isSelected ? { border: "2px solid #EA3DEE" } : {}}
    onClick={onPress}
  >
    <div
      className={classes.optionLabel}
      style={isSelected ? { color: "#EA3DEE" } : {}}
    >{label}</div>
  </div>
)

export const AddGoals: React.FC = () => {
  const [selections, setSelections] = useState(new Set())
  const [serverError, setServerError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)

    try {
      const goals = OPTIONS.filter((_, idx) => selections.has(idx))
      await AccountService.addExtraData({ goals })
      Analytics.pushEvent("app_apply_goals_added"); 
      history.push("/apply/verifying")
      
    } catch (e) {
      console.error("Error on sending goals", e)
      setServerError((e as Error).message)
      setLoading(false)
    }
  }

  return (
    <div className={classes.container}>
      <h1 style={{ fontWeight: "bold" }}>Your Goals</h1>
      <div style={{textAlign: "center"}}>Klutch MiniApps are here to help you live your best life. Select all that apply.</div>
      <form onSubmit={handleFormSubmit} >
        <div className={classes.serverError}>{serverError}&nbsp;</div>

        <div
          className={classes.optionContainer}
        >
          {OPTIONS.map((option, idx) =>
            <Option
              key={`option-${option}`}
              label={option}
              isSelected={selections.has(idx)}
              onPress={() => {
                const clone = new Set(selections)
                clone.has(idx) ? clone.delete(idx) : clone.add(idx)

                setSelections(clone)
              }}
            />
          )}
        </div>

        <KButton loading={loading} design="primary" >SUBMIT</KButton>
      </form>
    </div>
  )
}
