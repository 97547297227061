import React, { FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import classes from "./ChoosePlan.module.scss"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { LoanApprovalStatus,  RevolvingLoanService, AccountService, ApprovalStatus  } from "@klutch-card/klutch-js"
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation"
import { useAccount } from "../../services/klutchHooks"

const ChoosePlan: React.FC = () => {

    const [verifiedState, setVerifiedState] = useState<boolean|undefined>()

    const history = useHistory()    
    const account = useAccount()



    useEffect(() => {
        if (!account) {
            return
        }
        if (account.approvalStatus == ApprovalStatus.NOT_VERIFIED) {
            setVerifiedState(false)
        } 
        if (account.approvalStatus == ApprovalStatus.APPROVED) {
            setVerifiedState(true)
        }

    }, [account])



    if (verifiedState === undefined) {
        return (<></>)
    }

    const securedCardClicked = async () => {
        if (!verifiedState) {
            await AccountService.addExtraData({cardType: "SECURED"})
            history.replace("/apply")
            
        } else {
            await RevolvingLoanService.createSecureLoan()
            history.replace("/apply/pulling-credit")    
        }
    }

    const unsecuredCardClicked = async () => {
        if (!verifiedState) {
            await AccountService.addExtraData({cardType: "UNSECURED"})
            history.replace("/apply")
        }
    }

    const offers = () => {
/*         if (!accountProducts) {
            return (<LoadingAnimation color="#000000" />)
        }
 */        
        
    

        return (
            <>
                <div className={`${classes.plan} ${classes.basic}`}>
                    <div className={!verifiedState ? "" : classes.unavailable}>
                        {/* <p>NOT ELIGIBLE</p> */}
                    </div>
                    <h4>Klutch Credit</h4>
                    <ul>
                        <li>Unsecured Credit Card</li>
                        <li>Up to 6% Cash back</li>
                        <li>Free</li>
                        <li>Multiple Virtual and physical cards</li>
                    </ul>
                    <KButton design="primary" onClick={unsecuredCardClicked}>
                            {!verifiedState ? "SELECT" : "NOT ELIGIBLE"}
                    </KButton>
                </div>
                <div className={`${classes.plan}`}>
                    <h4>Klutch Lite</h4>
                    <ul>
                        <li>Security Deposit Required</li>
                        <li>Up to 6% Cash back</li>
                        <li>Free</li>
                        <li>Multiple Virtual and physical cards</li>
                    </ul>
                    <KButton design="primary" onClick={securedCardClicked}>SELECT</KButton>
                </div>
            </>
        )
    }

    return (
        <div className={classes.container}>
            <h1>Your Offers</h1>
            <p>Please select what type of card you want:</p>
            {offers()}
        </div>
    )
}


export default ChoosePlan