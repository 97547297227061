import { AuthService } from "@klutch-card/klutch-js"
import { useEffect } from "react"
import config from "../../config"
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation"
import { useHistory } from "react-router-dom"

export const Logout = (): JSX.Element => {


    const history = useHistory()

    useEffect(() => {
        localStorage.setItem(`${config.userPoolClientId}-AUTH_KEY`, "")     
        AuthService.logout()        
        history.replace("/auth/login")
    })


    return (
        
        <LoadingAnimation  color="black" />
    )
}