import { KTextInput, ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import NavBody from "../NavBody"
import classes from "./ChangePassword.module.scss"
import { ChangeEvent, FormEvent, useRef, useState } from "react"
import { NewPasswordInput } from "../auth/NewPasswordInput"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { AuthService } from "@klutch-card/klutch-js"


export const ChangePassword = () : JSX.Element => {


    const [currentPassword, setcurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    
    const [loading, setLoading] = useState(false)

    const inputs =  [useRef<KTextInput>(null), useRef<KTextInput>(null),useRef<KTextInput>(null)]
    const [serverError, setServerError] = useState("")

    const handleFormSubmit = async (e: FormEvent) => {
        setLoading(true)
        setServerError("")
        e.preventDefault()
    

        const validated = inputs.map( i => {
            const v = i.current?.validate(true)
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)
        
        if (!validated) {
            setLoading(false)
            return false
        }

        if (newPassword != confirmPassword) {
            setServerError("Passwords do not match")
            setLoading(false)
            return false
        }

        try {
            await AuthService.changePassword(currentPassword, newPassword)
            setServerError("Password changed succesffully!")
          } catch (e) {
            setServerError("Current Password is invalid")
            setLoading(false)
            return false
          } 



        return false
    }


    return (
        <NavBody title="Change Password">
            <div className={classes.main}>               
                <form onSubmit={handleFormSubmit}>
                    <div className={classes.serverError}>{serverError}&nbsp;</div>
                    <KTextInput 
                        label="CURRENT PASSWORD"
                        name="password"
                        value={currentPassword}                    
                        type="password"
                        ref={inputs[0]}
                        onChange={e => setcurrentPassword(e.target.value)} 
                        
                        validations={[                            
                            {type: ValidationType.required, errorMessage: "Password is required"}]}                       
                        />  
                <NewPasswordInput 
                    value={newPassword}
                    ref={inputs[1]}
                    onChange={text => setNewPassword(text)}
                     />
                <KTextInput 
                    label="CONFIRM PASSWORD"
                    name="password"
                    value={confirmPassword}                    
                    type="password"
                    ref={inputs[2]}
                    onChange={e => setConfirmPassword(e.target.value)} 
                    
                    validations={[                            
                        {type: ValidationType.required, errorMessage: "Password is required"}]}                       
                    /> 
                <KButton 
                    loading={loading}                
                    design="primary">CHANGE PASSWORD</KButton>                                                                                       
                </form>
            </div>
        </NavBody>
    )
}