import { FormEvent, useEffect, useRef, useState } from "react"
import classes from "./PlaidSecurityDepositTransfer.module.scss"
import {KTextInput, KChangeEvent } from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import { CardPaymentService, PaymentType, TransferSource, TransferSourceService, TransferSourceStatus } from "@klutch-card/klutch-js"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { defer,  filter,  map, repeat,  take } from "rxjs"
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation"
import {KButton} from "@klutchcard/klutch-webcomponents"

export const PlaidSecurityDepositTransfer: React.FC = () => {
    const [amount,setAmount] = useState<string>("")

    const [transferSource, setTransferSource] = useState<TransferSource>()

    const location = useLocation<{transferSource: any}>()
    const [loading, setLoading] = useState(false)
    const input = useRef<KTextInput>()
    const history = useHistory()
    const [paymentSent, setPaymentSent] = useState(false)
    const [serverError, setServerError] = useState("")


    useEffect(() => {
        const source = defer(() => TransferSourceService.getTransferSources())
        
        const p = source.pipe(
            map(c => {                            
               return c.find(x => x.id == location.state?.transferSource.id)!
            }), 
            repeat({count: 10, delay: 3000}),
            filter(c => c.status == TransferSourceStatus.ACTIVE),
            take(1)            
        )
        p.subscribe((c: TransferSource) => {            
           setTransferSource(c)
        })
    }, [])

    const changeAmount = (e: KChangeEvent) => {
        const value = e.target.value
        const currentValue = value.replace(/[^\d]/g, "")

        setAmount(previousValue => {
            if (!previousValue || value.length > previousValue.length) {
                return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
              return ""            
        } )
    }

    const handleSubmit = async (e: FormEvent) => {    
        e.preventDefault()
        const valid = input.current?.validate(true)
        if (valid != ValidationState.VALID) {            
            return
        }
        setLoading(true)                     
       try {
            const am = amount.replace(/[^\d]/g, "")
            const p = await CardPaymentService.createPayment(transferSource!.id, PaymentType.SECURITY_DEPOSIT, + am )
            setPaymentSent(true)
        } catch (e) {
            setServerError((e as Error).message)            
        } 
        setLoading(false)
        
    }

/*export const CheckMark = ({ color, size = 10, ...props }: any) => (
  <Svg
    width={size * 1.2}
    height={size}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path d="M11 1L4.125 8 1 4.818" stroke={color || "#34865F"} strokeWidth={1.5} />
  </Svg>
)
*/

    const body = () => {
        if (paymentSent) {
           return (
           <div className={classes.paymentSent}>
                <h2>Your payment is on the way!</h2>
                <svg height={100}  viewBox="0 0 12 10" fill="none">
                    <path d="M11 1L4.125 8 1 4.818" stroke={"#34865F"} strokeWidth={1.5} />
                </svg>
                <p>We will send you an email when we received your payment and we are ready to issue your card.
                    This might take up to 2 weeks.                    
                </p>                
           </div>
            )
        }
        if (transferSource === undefined) {
            return (
            <div>
                Loading your bank information
                <LoadingAnimation  color="black"/>
            </div>)
        }
        
        if (transferSource === null || transferSource.maxTransferAmount < 200 || transferSource.riskIndex >= 80) {
            return (
                <div className={classes.ineligble}>
                    <p>Your bank account is ineligble to be used as a funding source for your security deposit.</p>
                    <p>Please use our "Wire" option to submit a deposit.</p>
                    <KButton design="primary" onClick={() => history.replace("/apply/security-deposit")}>USE WIRE</KButton>
                </div>                
            )
        }

        const maxPayment = Math.min(transferSource.maxTransferAmount, 5000)
        
        return (
            <>
            <p>Please transfer an amount between 200 and {maxPayment}</p>
            <div className={classes.transferSource}>
                Pay from: {transferSource.name}
            </div>

            <form className={classes.form} onSubmit={handleSubmit}>
                <KTextInput 
                        label= "SECURITY DEPOSIT AMOUNT"
                        value={amount}
                        name="amount"
                        ref={input as any}
                        onChange={ changeAmount}                    
                        inputMode="numeric"
                        validations={[
                            { type: ValidationType.required, errorMessage: "" },
                            { type: ValidationType.number, errorMessage: "Income has to be a number" },
                            { type: ValidationType.minNumber, config: 200, errorMessage: "Amount has to be greater than $200" },
                            { type: ValidationType.maxNumber, config: maxPayment, errorMessage: `Amount has to be lesss than ${maxPayment}` }
                        ]} 
                />  
                <KButton design="primary">SEND PAYMENT</KButton>
                <span className={classes.smallPrint}>By clicking "SEND PAYMENT", you are authorizing us to initiate a debit on your behalf in the details listed above</span>
                </form>              
            </>
        )
    }

    return (
        <div className={classes.container}>
            <h1>Security Deposit</h1>
            <div className={classes.info}> 
                {serverError}           
                {body()}
            </div>

        </div>
    )

}

