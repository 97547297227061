import { Route, Switch } from "react-router-dom";
import { CardsList } from "./CardLists";
import { AddCard } from "./AddCard";
import { ActivateCard } from "./ActivateCard";


export const CardsRouter = (): JSX.Element => (
    <Switch>
        <Route path="/cards/new" component={AddCard} />
        <Route path="/cards/:cardId/activate" component={ActivateCard} />
        <Route path="/cards/:cardId" component={AddCard} />

        <Route path="/cards" component={CardsList} />
    </Switch>
)