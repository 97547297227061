
import { AccountService, Address, GraphQLException, USState, UserService } from "@klutch-card/klutch-js"
import useUser from "../../hooks/useUser"
import { useAccount } from "../../services/klutchHooks"
import NavBody from "../NavBody"
import classes from "./UpdateDetails.module.scss"
import { Link } from "react-router-dom"
import { KChangeEvent, KTextInput, ValidationState, ValidationType, Dialog } from "@klutchcard/klutch-webcomponents"
import { FormEvent, useEffect, useMemo, useRef, useState } from "react"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { KPlacesAutoCompleteInput } from "../../components/KTextInput"


export const normalizeAddress = (a?: Address): string => a ? `${a.streetNumber} ${a.street} ${a.complement}, ${a.city} ${a.state} ${a.zipCode}` : ""

export const UpdateDetails = (): JSX.Element => {

    const stateList = Object.keys(USState)

    const account = useAccount()
    
    const user = useUser()
    const [phone, setPhone] = useState(user?.phone || "")
    const [street, setStreet] = useState(account?.address?.street || "")
    const [complement, setComplement] = useState(account?.address?.complement || "")
    const [city, setCity] = useState(account?.address?.city || "")
    const [state, setState] = useState(account?.address?.state || "")
    const [zipCode, setZipCode] = useState(account?.address?.zipCode || "")
    const [showConfirmPhoneDialog, setShowConfirmPhoneDialog] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)
    const [resendLoading, setResndLoading] = useState(false)
    const [confirmNumberLoading, setConfirmNumberLoading] = useState(false)
    const [serverError, setServerError] = useState("")

    const [confirmationPin, setConfirmationPin] = useState("")

    
    useEffect(() => {
        if (!account?.address) {
            return
        }
        setStreet(`${account?.address?.streetNumber} ${account?.address?.street}`)
        setComplement(account?.address?.complement)        
        setCity(account?.address?.city)        
        setState(account?.address?.state)        
        setZipCode(account?.address?.zipCode)        

    }, [account?.id])

    useEffect(() => {
        if (!user) return
        setPhone(user?.phone)
    }, [user?.id])

    const inputs = [useRef<KTextInput>(null), useRef<KTextInput>(null), useRef<KTextInput>(null), useRef<KTextInput>(null),useRef<KTextInput>(null),]
    
    const changePhoneNumber = (event: KChangeEvent) => {

        const text: string = event.target.value
        
        if (!text) {
            setPhone("")
            return
        }
        let currentValue = text.replace(/[^\d]/g, "")
        if (text.startsWith("1")) {
            currentValue = currentValue.slice(1)
        }
        if (text.startsWith("+1")) {
            currentValue = currentValue.slice(1)
        }

        const cvLength = currentValue.length

        setPhone(prev => {
            let normalized = currentValue
            if (!prev || text.length > prev.length) {
                if (cvLength < 4) {
                    normalized = currentValue
                } else if (cvLength < 7) {
                    normalized = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
                } else {
                    normalized = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
                }
            }
            if (normalized.length >= 14) {
//                phoneField?.blur()
            }
            return normalized
        })
    }

    const normalizePhoneInput = (value: string, previousValue: string) => {
        if (!value) return value

        let currentValue = value.replace(/[^\d]/g, "")
        if (value.startsWith("1")) {
            currentValue = currentValue.slice(1)
        }
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {

            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
        }
    }

    const addressCompleted = (address: Address) => {
        setStreet(`${address.streetNumber || ""} ${address.street}`)
        setComplement(address.complement || "")
        setCity(address.city)
        setState(address.state)
        setZipCode(address.zipCode || "")        
    }


    const formSubmit = async (e: FormEvent) => {
        setSaveLoading(true)
        e.preventDefault()
        e.stopPropagation()        

        const validated = inputs.map( i => {
            const v = i.current?.validate(true) 
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setSaveLoading(false)            
            return
        }

        
        const p = phone.replace(/[^\d]/g, "")
        UserService.changePhone(p)
        setShowConfirmPhoneDialog(true)
        return false
    }

    const resendPhoneCode = async () => {     
        setResndLoading(true)   
        try {
            const p = phone.replace(/[^\d]/g, "")
            await UserService.changePhone(p)
        } finally {
            setResndLoading(false)
        }
        
    }

    const cancelConfirmDialog = () => {
        setShowConfirmPhoneDialog(false) 
        setSaveLoading(false)
    }

    const confirmPhoneNumber = async () => {
        setConfirmNumberLoading(true)
        try {
            const p = phone.replace(/[^\d]/g, "");
            await UserService.changePhone(p, confirmationPin)
            const sn = (street.match(/^\d+/gm) ?? [""])[0] 
            const s = street.substring(street.indexOf(sn) + sn.length).trim()
    
            console.log("S", {s, sn})
            await AccountService.changeAddress(sn, s,  complement || "", city, state, zipCode)                
            setShowConfirmPhoneDialog(false)       

        } catch (e) {
            if (e instanceof GraphQLException) {
                const errorTypes = e.getAlloyCoreExceptionType()     
                if (errorTypes && errorTypes.includes("com.alloycard.core.entities.user.PhoneVerificationException")) {
                  setServerError("The code you typed does not match the one we sent you.")
                } else {
                  setServerError(e.message)
                }                
            } else {
                setServerError((e as Error).message)
              }      
        }
        finally {
            setConfirmNumberLoading(false)
            setSaveLoading(false)
        }
        
    }


    return (
        <>
        <NavBody title="EDIT DETAILS" className={classes.navbody}>
            <div className={classes.main}>
                <p>To change your name or email, please contact support at support@klutchcard.com</p>
                <form className={classes.form} onSubmit={formSubmit}>
                    <KTextInput                        
                        className={classes.firstName}
                        label="First Name"
                        value={user?.firstName}
                        disabled name="firstName" />
                    <KTextInput
                        className={classes.lastName}
                        label="Last Name"
                        value={user?.lastName}
                        disabled name="lastName" />
                    <KTextInput
                        className={classes.email}
                        label="Email"
                        value={user?.email}
                        disabled
                        name="email" />
                    <KTextInput
                        className={classes.phone}
                        label="Phone"
                        value={normalizePhoneInput(phone || "", "")}
                        onChange={changePhoneNumber}
                        name="phone" />                         
                <KPlacesAutoCompleteInput 
                    label="STREET"
                    value={street}
                    name="street"
                    className={classes.street}
                    onAddressCompleted={addressCompleted}
                    onChange={(e: any) => setStreet(e.target.value)}
                    ref={inputs[0]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "Street is a required field"},
                        {type: ValidationType.minLength, config: 3, errorMessage: "Must contain at least 3 characters"},
                        {type: ValidationType.regex, config: /^([A-Z0-9a-z\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]}                    
                />
                <KTextInput 
                    label= "APARTMENT #"
                    value={complement}                    
                    name="complement"
                    className={classes.complement}
                    onChange={(e: any) => setComplement(e.target.value)}                    
                    validations={[
                        {type: ValidationType.regex, config: /^([A-Z0-9a-z\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]} />
                <KTextInput 
                    label= "CITY"
                    value={city}
                    name="city"
                    className={classes.city}
                    onChange={(e: any) => setCity(e.target.value)}
                    ref={inputs[2]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "City is a required field"},
                        {type: ValidationType.minLength, config: 3, errorMessage: "Must contain at least 3 characters"},
                        {type: ValidationType.regex,
                            config: /^([A-Za-z\s])*$/g,
                            errorMessage: "Please enter a valid city name"
                        }
                    ]} />
                <KTextInput 
                    label= "STATE"
                    value={state}
                    name="state"
                    className={classes.state}
                    onChange={(e: any) => setState(e.target.value)}
                    ref={inputs[3]}
                    maxLength={2}
                    validations={[
                        {type: ValidationType.required, errorMessage: "State is a required field"},
                        {type: ValidationType.list, config: stateList, errorMessage: "Invalid State"},
                        {type: ValidationType.regex, config: /^([A-Za-z\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]} />
                <KTextInput 
                    label= "ZIP CODE"
                    value={zipCode}
                    name="zipcode"
                    maxLength={10}
                    className={classes.zipcode}
                    onChange={(e: any) => setZipCode(e.target.value)}
                    ref={inputs[4]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "Zip is a required field"},
                        {type: ValidationType.regex, config: /^(\d{5}(?:-\d{4})?)$/gm, errorMessage: "Zip format is invalid"}]} />        
 
                    <KButton 
                        design="primary"
                        loading={saveLoading}
                        className={classes.save}>
                            SAVE
                    </KButton>
                </form>
            </div>
        </NavBody>
        <Dialog 
            title={"CONFIRM CHANGES"}             
            buttons={[
                {label: "CANCEL", type: "cancel", onClick:cancelConfirmDialog},
                {label: "RESEND CODE", type: "outline", loading: resendLoading, onClick: resendPhoneCode},
                {label: "CONFIRM", type: "primary", loading: confirmNumberLoading, onClick: confirmPhoneNumber}
            ]} 
            show={showConfirmPhoneDialog} 
            onDismiss={cancelConfirmDialog}>
                <p>We just sent a text to {normalizePhoneInput(phone || "", "")}.</p>
                <p className={classes.error}>{serverError}</p>
                <KTextInput
                        className={classes.phonePin}
                        labelClassName={classes.label}
                        inputClassName={classes.label}
                        placeholder=""
                        maxLength={6}
                        inputMode="numeric"                        
                        label="CONFIRMATION PIN"
                        value={confirmationPin}                        
                        onChange={e => setConfirmationPin(e.target.value)}
                        name="phonePin" />                    
        </Dialog>
        </>
    )
}

