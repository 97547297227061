import { FormEvent, useRef, useState } from "react"
import {KTextInput} from "@klutchcard/klutch-webcomponents"
import { Select } from "@klutchcard/klutch-webcomponents"
import NavBody from "../NavBody"
import classes from "./AddManual.module.scss"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { GraphQLException, TransferSourceService } from "@klutch-card/klutch-js"
import { useHistory } from "react-router-dom"

export const AddManual = (): JSX.Element => {

    const [nickName, setNickName] = useState("")
    const [routingNumber, setRoutingNumber] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [type, setType] = useState("CHECKING")
    const [loading, setLoading] = useState(false)

    const inputs = [useRef<KTextInput>(null), useRef<KTextInput>(null), useRef<KTextInput>(null)]
    const [added, setAdded] = useState(false)
    const [serverError, setServerError] = useState("")

    const history = useHistory()


    const addBankSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        setServerError("")
        const validated = inputs.map(i => {
            const v = i.current?.validate(true)
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setLoading(false)
            return
        }
        try {
            const params = { name: nickName, routingNumber, accountNumber, type }
            await TransferSourceService.addTransferSource(nickName, "MicroDeposits", params)
            setAdded(true)    
        } catch (e) {
            if (e instanceof GraphQLException) {
                setServerError(e.getAlloyDescriptions().join(", "))
                
            } 
        } finally {
            setLoading(false)
        }
        
        
    }


    const drawBody = () => {
        if (added) {
            return (
                <>
                    <div className={classes.added}>
                        <span className={`material-symbols-outlined ${classes.checkmark}`}>done</span>
                        <p>We will make two small deposits of less than $1 to the bank account you provided.</p>
                        <p>These deposits need to be verified to complete your account opening.</p>
                        <p>Once you’ve receive the deposits, please come back here and follow the steps to verify the transactions. If you have any questions please contact us at support@klutchcard.com</p>
                        <KButton design="primary" onClick={() => history.replace("/home")}>GO HOME</KButton>
                    </div>
                </>
            )
        } else {

            return (
                <>
                    <KTextInput
                        label="BANK NICKNAME"
                        value={nickName}
                        onChange={e => setNickName(e.target.value)}
                        ref={inputs[0]}
                        name="nickName"
                        validations={[
                            { type: ValidationType.required, errorMessage: "Nickname is required" },
                            { type: ValidationType.minLength, config: 2, errorMessage: "Nickname must be at least 2 characters long" }
                        ]}
                    />
                    <KTextInput
                        label="ROUTING NUMBER"
                        value={routingNumber}
                        ref={inputs[1]}
                        onChange={e => setRoutingNumber(e.target.value)}
                        name="routingNumber"
                        validations={[
                            { type: ValidationType.required, errorMessage: "Routing number is required" },
                            { type: ValidationType.minLength, config: 9, errorMessage: "Routing number needs to be 9 characters long" },
                            { type: ValidationType.maxLength, config: 9, errorMessage: "Routing number needs to be 9 characters long" }
                        ]}
                    />
                    <KTextInput
                        label="ACCOUNT NUMBER"
                        value={accountNumber}
                        ref={inputs[2]}
                        onChange={e => setAccountNumber(e.target.value)}
                        name="accountNumber"
                        validations={[
                            { type: ValidationType.required, errorMessage: "Account number is required" },
                            { type: ValidationType.minLength, config: 2, errorMessage: "Routing number needs to be 2 at least characters long" },
                            { type: ValidationType.regex, config: /^[0-9]*$/, errorMessage: "Account needs to be a number" },
                        ]}
                    />
                    <label>TYPE</label>
                    <Select
                        options={[{ label: "CHECKING", value: "CHECKING" }, { label: "SAVINGS", value: "SAVINGS" }]}
                        optionsComponent={option => (<span className={classes.option}>{option.label}</span>)}
                        className={classes.select}
                        arrowColor="#6B6B6B"
                        value={type}
                        onValueChanged={e => setType(e.value)}
                    />
                    <KButton className={classes.button} design="primary" loading={loading}>ADD BANK</KButton>
                </>
            )
        }
    }

    return (
        <NavBody title="Add Bank">
            <form className={classes.main} onSubmit={addBankSubmit}>
                <p className={classes.error}>{serverError}</p>
                {drawBody()}
            </form>
        </NavBody>
    )
}