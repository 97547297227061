/* eslint-disable */
  
import React, {  FormEvent, useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import classes from "./SecondaryInspection.module.scss"
import loadVouched from "./vouched-script"
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation"
import { AccountService, User, UserService } from "@klutch-card/klutch-js"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { Analytics } from "../../services/analytics"


const SecondaryInspection : React.FC = () => {

    const [user, setUser] = useState<User | null>()
    const [initialized, setInitialized] = useState(false)
    const [failed, setFailed] = useState(false)

    const history = useHistory()

    useEffect(() => {
      Analytics.pushEvent("app_apply_secondary_verification"); 
    }, [])

    useEffect(() => {
        const run = async () => {
          const user = await UserService.getUser(true)
          setUser(user)
        }
        run()
    }, [])

    useEffect(() => {

        if (!user) {
            return
        }


        
        const config = {
            appId: "cDSq-MxFm~#GMDorAp#7OCw.TRu1yM",          
            // optional verification information for comparison
            verification: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phone: user.phone,
            },
          
            // mobile handoff fields, a job will be created automatically if true
            crossDevice: true,
            crossDeviceQRCode: true,
            crossDeviceSMS: true,
            content: {
              review: "Verification complete. Please return to your computer for next steps to complete your application"
            },
            showTermsAndPrivacy: false, 
            idLiveness: "distance",
            liveness: "mouth",
            includeBarcode: true,
            maxRetriesBeforeNext: 5,
            numForceRetries: 5,
            includeBackId: true,
            enableGeoLocation: true,
            face: "camera",
            id: "camera",
            // have the user confirm information
            userConfirmation: {
              confirmData: true,
              confirmImages: true,
            },
          
            // callback during initialization of the web app
            onInit: ({ token, job }: any) => {
              console.log("initialization");
              setInitialized(true)
            },
          
            // callback when a user submits a photo
            onSubmit: ({ stage, attempts, job }: any) => {                
              console.log("photo submitted");
            },
          
            // called when a job is completed.
            onDone: async (job: any) => {
              // token used to query jobs
              await AccountService.addExtraData({vouched_job: job}) 
              if (job.result.success) {
                console.log("scanning complete", { token: job.token });              
                history.push("/apply/verifying")    
              } else {
                setFailed(true)
              }
          
              // An alternative way to update your system based on the
              // results of the job. Your backend could perform the following:
              // 1. query jobs with the token
              // 2. store relevant job information such as the id and
              //    success property into the user's profile
              // fetch(`/yourapi/idv?job_token=${job.token}`);
          
              // Redirect to the next page based on the job success
              // if (job.result.success) {
              //   window.location.replace("https://www.vouched.id/");
              // } else {
              //   window.location.replace("https://www.vouched.id/");
              // }
            },
          
            // callback executed after attempt to find camera device
            onCamera: ({ hasCamera, hasPermission }: any) => {
              console.log("attempted to find camera");
            },
          
            // callback when there are changes to the Camera DOM element
            onCameraEvent: (cameraEvent: any) => {
              console.log("camera DOM element updated");
            },
          
            // callback when a reverification job is complete
            onReverify: (job: any) => {
              console.log("reverification complete");
            },
          
            // callback when a survey is submitted, (per customer basis)
            onSurveyDone: (job: any) => {
              console.log("survey done");
            },
          
            // callback when user confirms data and photos
            onConfirm: (userConfirmEvent: any) => {
              console.log("user confirmation");
            },
          
            // theme
            theme: {
              name: "avant",
            },
          };

        loadVouched(config); 
      }, [user?.id, failed]);

    if (failed) {
      return (
        <div className={classes.container}>               
              <h1>Scan ID</h1>
              <div className={classes.failed}>We could not validate your documents, please try again...</div>
              <KButton design="primary" onClick={() =>location.reload()}>NEXT</KButton>
        </div>
      )
    }

    return (
        <div className={classes.container}>   
            { !initialized && (
              <>
              <h1>Scan ID</h1>              
              <div>
                <LoadingAnimation />
                Loading Scanning Component...
                </div>
              </>
            )}
            <div className={`${classes.vouched} ${initialized && classes.vouchedInitialized}`}>
              <div id="vouched-element" className={classes.vouchedElement}></div>
            </div>
        </div>
    )
}


export default SecondaryInspection