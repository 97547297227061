import { useEffect, useState } from "react"
import NavBody from "../NavBody"
import classes from "./SelectPayment.module.scss"
import { CardPaymentService, PaymentBalance, TransferSourceStatus } from "@klutch-card/klutch-js"
import { toCurrency } from "@klutchcard/klutch-webcomponents"
import { useTransferSources } from "../../hooks/useTransfersSources"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { useHistory } from "react-router-dom"

export const SelectPayment = (): JSX.Element => {

    const [paymentBalances, setPaymentBalances] = useState<Array<PaymentBalance>|undefined>(undefined)

    const transferSources = useTransferSources()

    const history = useHistory()
    
    const desc: {[key: string]: any } = {
        "OUTSTANDING_BALANCE": {
            title: "Outstanding Balance",
            description: "Pay the entire balance"
        },
        "MINIMUM_DUE": {
            title: "Minimum due",
            description: "Paying less than your balance will result in interest charges"
        },
        "BALANCE_DUE": {
            title: "Balance Due",
            description: "Pay the balance due on this biling cycle"
        },
    }

    useEffect(() => {
        const run = async () => {
            const paymentBalances = await CardPaymentService.listPaymentBalances()
            setPaymentBalances(paymentBalances)
            
        }

        run()
    }, [])

    
    const drawBody = () => {

        if (!transferSources|| transferSources.length == 0 || transferSources.every(c => c.status != TransferSourceStatus.ACTIVE)) {
            return (
                <>
                <span>Before you can pay your bill, you need to link a bank account.</span>
                <KButton design="primary" onClick={() => history.push("/transfersources")}>CONNECT BANK ACCOUNT</KButton>
                </>
            )
        }

        return (
            <>
                <span>How much would you like to pay?</span>
                {paymentBalances?.map(p => (
                    <div key={p.paymentType} className={classes.paymentBox} onClick={() => history.push(`/payments/confirm?type=${p.paymentType}&amount=${p.amount}`)}>
                        <h4 className={classes.title}>{desc[p.paymentType].title}</h4>
                        <h4 className={classes.amount}>{toCurrency(p.amount)}</h4>
                        <span className={classes.description}>{desc[p.paymentType].description}</span>
                        <span className={`material-symbols-outlined ${classes.arrow}`}>arrow_forward</span>
                    </div>
                ))}
                <div className={classes.paymentBox} onClick={() => history.push("/payments/confirm?type=OTHER")}>
                    <h4 className={classes.title}>Other Amount</h4>
                    <h4 className={classes.amount}></h4>
                    <span className={classes.description}>Paying less than your balance will result in interest charges</span>
                    <span className={`material-symbols-outlined ${classes.arrow}`}>arrow_forward</span>
                </div>            
            </>
        )
    }

    return (
        <NavBody title="Pay my Bill">
            <div className={classes.main}>
                {drawBody()}
            </div>
        </NavBody>
    )
}