
import { Address } from "@klutch-card/klutch-js"
import useUser from "../../hooks/useUser"
import { useAccount } from "../../services/klutchHooks"
import NavBody from "../NavBody"
import classes from "./AccountPage.module.scss"
import { Link, useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"

export const normalizeAddress = (a?: Address): string => a ? `${a.streetNumber} ${a.street} ${a.complement}, ${a.city} ${a.state} ${a.zipCode}` : ""

export const AccountPage = (): JSX.Element => {

    const account = useAccount()
    const user = useUser()

    const history = useHistory()



    const normalizePhoneInput = (value: string, previousValue: string) => {
        if (!value) return value;

        let currentValue = value.replace(/[^\d]/g, "");
        if (value.startsWith("1")) {
            currentValue = currentValue.slice(1)
        }
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {

            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    return (
        <NavBody title="Account" className={classes.navbody}>
            <div className={classes.main}>
                <div className={classes.infoPanel}>
                    <div className={classes.info}>
                        <label>LEGAL NAME</label>
                        <span>{user?.firstName} {user?.lastName}</span>
                    </div>
                    <div className={classes.info}>
                        <label>EMAIL</label>
                        <span>{user?.email}</span>
                    </div>
                    <div className={classes.info}>
                        <label>PHONE NUMBER</label>
                        <span>{normalizePhoneInput(user?.phone || "", "")}</span>
                    </div>
                    <div className={classes.info}>
                        <label>ADDRESS</label>
                        <span>{normalizeAddress(account?.address)}</span>
                    </div>
                    <div className={classes.button}>
                        <KButton design="primary" onClick={() => history.push("/account/edit")}>EDIT</KButton>
                    </div>
                </div>
                <div className={classes.spacer} />
                <div className={classes.accountMenu}>
                    <AccountMenuItem url="/account/refer-a-friend" label="Refer a Friend" />
                    <AccountMenuItem url="/account/change-password" label="Change Password" />
                    <AccountMenuItem url="/account/security-and-legal" label="Security & Legal" />
                    <AccountMenuItem url="mailto:help@klutchcard.com?subject=Contact us" label="Contact Us" />
                    {/* <AccountMenuItem url="/account/autopay" label="Auto Pay" /> */}
                    <AccountMenuItem url="/transfersources" label="Bank Accounts" />
                    <AccountMenuItem url="/payments" label="Pay my Bill" />
{/*                      <AccountMenuItem url="/account/credit-terms" label="Credit Terms" /> */}
                    <AccountMenuItem url="/account/statements" label="Statements" />
                    <AccountMenuItem url="/account/logout" label="Log out" />
                </div>
            </div>
        </NavBody>
    )
}

export const AccountMenuItem = ({ url, label, onClick }: any) => (
    <div className={classes.accountMenuItem} onClick={onClick}>
        {url?.startsWith("/") ? (
            <Link to={url}>{label}</Link>
        ) : (
            <a href={url} target="_blank">{label}</a>
        )}        
        <span className="material-symbols-outlined">arrow_forward</span>
    </div>)