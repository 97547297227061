import { useEffect, useState } from "react"
import { Dialog } from "@klutchcard/klutch-webcomponents"
import classes from "./InboundTransferSource.module.scss"
import { TransferSource, TransferSourceService } from "@klutch-card/klutch-js"
import useUser from "../../hooks/useUser"
import { useAccount } from "../../services/klutchHooks"

export interface InboundTransferSourceProps {
    show: boolean
    dismiss: () => void
}

export const InboundTransferSource = ({show, dismiss}: InboundTransferSourceProps): JSX.Element => {
    const [transferSource, setTransferSource] = useState<TransferSource|undefined>()
    
    const user = useUser()
    const account = useAccount()

    const address = account?.address

    useEffect(() => {
            const run = async () => {
                const transferSource = await TransferSourceService.addTransferSource("", "Inbound", {})
                setTransferSource(transferSource)
                console.log(transferSource)
            }
            run()    
    }, [])

    return (
        <Dialog
            show={show}
            onDismiss={dismiss}
            title="PAY VIA WIRE"
            buttons={[{label: "CLOSE", type: "primary", onClick: dismiss}]}>
            <div className={classes.content}>
                <span>Please use the details below to make a payment directly from your bank account. </span>
                <span>Wire transfers can take up to 1 business day</span>
                <div className={classes.infoPair}>
                    <label>ROUTING NUMBER</label>
                    <span>{transferSource?.routingNumber}</span>
                </div>
                <div className={classes.infoPair}>
                    <label>ACCOUNT NUMBER</label>
                    <span>{transferSource?.accountNumber}</span>
                </div>      
                <div className={classes.infoPair}>
                    <label>ACCOUNT NAME</label>
                    <span>{user?.firstName} {user?.lastName}</span>
                </div>   
                <div className={classes.infoPair}>
                    <label>ACCOUNT ADDRESS</label>
                    <span>{address?.streetNumber} {address?.street} {address?.complement}, {address?.city}, {address?.state} {address?.zipCode}</span>
                </div>                                            
                <div className={classes.infoPair}>
                    <label>BANK ADDRESS</label>
                    <span>Evolve Bank and Trust, 6070 Poplar Ave Suite 200 - Memphis, TN 38119</span>
                </div>                
            </div>
        </Dialog>
        
    )
}