import { useEffect, useState } from "react"
import { LockSwitch, Modal } from "@klutchcard/klutch-webcomponents"
import NavBody from "../NavBody"
import { AccountMenuItem } from "./AccountPage"
import classes from "./SecurityAndLegal.module.scss"
import { AccountService, AgreementType, AuthService, UserService } from "@klutch-card/klutch-js"
import config from "../../config"

import {KButton} from "@klutchcard/klutch-webcomponents"

export const SecurityAndLegal =  () : JSX.Element => {

    const [savePassword, setSavePassword] = useState(true)
    const [enableMFA, setEnableMFA] = useState(false)
    const [cardHolderAgreement, setCardHolderAgreement] = useState<string>()
    const [showCloseModal, setShowCloseModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reason, setReason] = useState("")
    const [closureRequested, setClosureRequested] = useState(false)

    const key = config.userPoolClientId + "-PREFS";

    useEffect(()=> {

        const run = async () => {
            const agreements = await AccountService.getAgreements()
            const la = agreements.find(x => x.agreementType == AgreementType.LOAN_AGREEMENT)
            setCardHolderAgreement(la?.url)
        }

        const runMFA = async () => {
            const mfaSetting = await  AuthService.getMFAStatus()
            setEnableMFA(mfaSetting)
        }
        runMFA()
        run()

    }, [])

    useEffect(() => {
        const run = async () => {
            const prefs = JSON.parse(localStorage.getItem(key) || "null") || {savePassword: true}
            setSavePassword(prefs.savePassword)
        }   
        run()
    }, [])    

    const passwordChanged = async (value: boolean) => { 
        localStorage.setItem(key, JSON.stringify({savePassword: !value}))
        setSavePassword(!value)
    }

    const enableMFAChanged = async (value: boolean) => {
        const resp = await UserService.changeMFA(!value)
        setEnableMFA(resp)
    }

    const onCloseAccountRequest = async () => {
        setLoading(true)
        await AccountService.close(reason)
        setLoading(false)
        setClosureRequested(true)        
    }
    

    return (
        <NavBody title="Security & Legal">
            <div className={classes.main}>
                <div className={classes.switches}>
                    <label>Save Password</label>
                    <LockSwitch locked={!savePassword} onLockStatusChanged={passwordChanged} />
                 {/*}   <label>Enable Multi Factor Authentication</label>                    
                    <LockSwitch locked={!enableMFA} onLockStatusChanged={enableMFAChanged} /> */}
                </div>
                <div className={classes.submenus}>
                    <AccountMenuItem url={cardHolderAgreement} label="Cardholder Agreement" />
                    <AccountMenuItem url="https://www.klutchcard.com/terms-of-service" label="Terms of Use" />
                    <AccountMenuItem url="https://www.klutchcard.com/privacy-policy" label="Privacy Policy" />
                    <AccountMenuItem onClick={() => setShowCloseModal(true)} label="Close Account" />
                </div>
            </div>
            <Modal show={showCloseModal} onDismiss={() => setShowCloseModal(false)}>
                <div className={classes.closeModal}>
                    <h4>CLOSE ACCOUNT</h4>
                    {closureRequested ? (
                        <>
                            <span className={classes.requestReceived}>Your Request has been received. We will get in touch with you when your account has been closed.</span>
                        </>
                    ) : (
                        <>
                            <span>Are you sure you want to close your account?</span>
                            <label>Reason:</label> 
                            <textarea aria-label="reason" rows={5} value={reason} onChange={e => setReason(e.target.value)}/>
                            <div className={classes.buttonBar}>
                                <KButton className={classes.cancelButton} design="outline" onClick={() => setShowCloseModal(false)}>CANCEL</KButton>
                                <KButton className={classes.closeButtton} design={"primary"} loading={loading} onClick={onCloseAccountRequest}>REQUEST CLOSURE!</KButton>
                            </div>    
                        </>    
                    )}
                </div>  
            </Modal>
        </NavBody>
    )
}

