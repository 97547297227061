import { Transaction, TransactionService } from "@klutch-card/klutch-js";
import { TransactionCategory } from "@klutch-card/klutch-js/lib/entities/TransactionCategory";
import { useEffect, useState } from "react";


export function useTransactionCategories(callback?: (categories: TransactionCategory[]) => void) {
    const [categories, setCategories] = useState<Array<TransactionCategory> | null>()

    useEffect(() => {
        let timeout: NodeJS.Timeout
        const subscription = TransactionService.addCategoriesSubscriber(c => {
         timeout = setTimeout(() => {
                if (c) {
                    setCategories([...c])
                } else {
                    setCategories([])
                }
                
                callback && callback(c!)       
            }, 0) 
        })

        return () => {
            clearTimeout(timeout)
            subscription.unsubscribe()
        }
    }, [])

    return categories
}



export function useTransaction(transactionId: string) {
    const [transaction, setTransaction] = useState<Transaction> ()
    useEffect(() => { 
        if (transactionId) {            
            TransactionService.getTransactionDetails(transactionId).then(t => setTransaction(t))        
        }        
    }, [transactionId])    
    return transaction
}