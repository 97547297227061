import React, { FormEvent, useEffect, useState } from "react"
import classes from "./DownloadApp.module.scss"

import { useCookies } from "react-cookie"
import { AuthService } from "@klutch-card/klutch-js";
import {KButton} from "@klutchcard/klutch-webcomponents";
import { Analytics } from "../../services/analytics";


export const DownloadApp: React.FC = () =>  {

  const [cookies, setCookie, removeCookie] = useCookies(["provider"]);
  const [token, setToken] = useState<any>()
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    Analytics.pushEvent("app_apply_downloadapp");   
    AuthService.addSubscriber(auth => {
      setToken(auth?.RefreshToken)
    })
      
  }, [])

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({"action": "goto", "page": "/apply/pre-approved", "auth":  token}))    
    }    
    setLoading(false)
  }

if (cookies["provider"] == "mobile") {
  return (
    <div className={classes.container}>
    <h1 style={{ fontWeight: "bold" }}>You are in :)</h1>
    <form onSubmit={handleFormSubmit} >
      <div>Click continue to view your offer and create your first card!</div>
      <br /><br />        
      <KButton loading={loading} design="primary">CONTINUE</KButton>
    </form>
  </div>
  )
}

return  (
    <div className={classes.container}>
      <h1 style={{ fontWeight: "bold" }}>You are in :)</h1>
      <form>
        <div>Download the Klutch App to get your first card and start spending!</div>
        <div>No need to sign up again, just log in.</div>
        <br /><br />        
        <a href="https://www.klutchcard.com/download-klutch-app" target="_blank" className={classes.button}>
          GO TO APP STORE
        </a>
      </form>
    </div>
  )

}