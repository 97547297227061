import React, { useEffect } from "react"

import classes from "./AddGoals.module.scss"
import { Analytics } from "../../services/analytics";


export const Reject: React.FC = () => {

  useEffect(() => {
    Analytics.pushEvent("app_apply_user_rejected"); 
  }, [])

  return (
    <div className={classes.container}>
      <h1 style={{ fontWeight: "bold" }}>OPEN CREDIT CARD</h1>
      <div style={{ textAlign: "center", flexDirection: "column" }}>
        <h3>WE'RE UNABLE TO PRE-QUALIFY YOU TODAY</h3>
        <br />
        Thank you for applying for the Klutch Card. You should receive an email from us with more information regarding your application.
        <br />
        No hard credit inquiry was taken during this process.
      </div>

    </div>
  )
}
