import { AccountService, Address,  USState } from "@klutch-card/klutch-js"
import React, {  FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import  { KPlacesAutoCompleteInput } from "../../components/KTextInput"
import {KTextInput} from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import classes from "./AddAddress.module.scss"
import { Analytics } from "../../services/analytics"
import { useAccount, useUser } from "../../services/klutchHooks"

export const availableStates = ["AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "KS", "LA", "MA", "MD", "ME",  "MN", "MO",  "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT"]

const AddAddress: React.FC = () => {

    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)
    const [street, setStreet] = useState("")
    const [complement, setComplement] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [showEdit, setShowEdit] = useState<boolean>(true)

    const [valid, setValid] = useState(ValidationState.PRISTINE)

    const inputs = [useRef<KTextInput>(null), useRef<KTextInput>(null), useRef<KTextInput>(null), useRef<KTextInput>(null),useRef<KTextInput>(null),]

    const history = useHistory()
    const apt = useRef<any>()

    const account = useAccount()
    const user = useUser()

    const address = account?.address

    const addressCompleted = (address: Address) => {
        setStreet(`${address.streetNumber || ""} ${address.street}`)
        setComplement(address.complement || "")
        setCity(address.city)
        setState(address.state)
        setZipCode(address.zipCode || "")        
    }


    useEffect(() => {
        if (address?.state) {
            setStreet(`${address.streetNumber || ""} ${address.street}`)
            setComplement(address.complement || "")
            setCity(address.city)
            setState(address.state)
            setZipCode(address.zipCode || "")   
            setShowEdit(false)
        }
    }, [address?.state])

    
    
    const stateList = Object.keys(USState)


    const decideNext = () => {
        let to =  "/apply/ssn"
        if (user?.hasSSN) {
            to = "/apply/annual-income"
        }
        if (!availableStates.includes(state.toUpperCase())) {
            to = "/apply/state-not-available"
        }        

        Analytics.pushEvent("app_apply_address_added");                     
        history.push(to)
    }


    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)    
        const validated = inputs.map( i => {
            const v = i.current?.validate(true) 
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setLoading(false)
            return
        }
        

        const sn = (street.match(/^\d+/gm) ?? [""])[0] 
        const s = street.substr(street.indexOf(sn) + sn.length).trim()

        const fullAddress = `${sn} ${s} ${complement}`

        const pobox = /^\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+/i;
        if (fullAddress.match(pobox) != null) {
            setServerError("PO BOXes are not accepted. Please use your residential address.")
            setLoading(false)
            return
        }

        try {
            await AccountService.changeAddress(sn, s,  complement || "", city, state, zipCode)
            decideNext()
            
        } catch (e: any) {
            setServerError(e.message)
            setLoading(false) 
        }

    }

    if (!showEdit) {
        return (
            <div className={classes.container}>
                <h1>Primary Address</h1>
                <p>We have identified your current location as your place of residence. Please check and correct if needed</p>
                <div className={classes.address}>
                    <label>Street:</label>
                    <span>{address?.streetNumber} {address?.street}</span>
                    <label>Apt #:</label>
                    <span>{address?.complement}</span>
                    <label>City</label>
                    <span>{address?.city}</span>
                    <label>State</label>
                    <span>{address?.state}</span>
                </div>
                <div className={classes.buttonBar}>
                    <KButton loading={loading} design="outline" onClick={() => setShowEdit(true)}>CHANGE</KButton>
                    <KButton loading={loading} design="primary" onClick={() => decideNext() }>LOOKS GOOD</KButton>
                </div>
                
            </div>
        )
    }


    return (
        <div className={classes.container}>            
            <h1>Primary Address</h1>
            <div>Your card will be shipped to this address</div>            
            <form onSubmit={handleFormSubmit} >
                <div className={classes.serverError}>{serverError}&nbsp;</div>
                <KPlacesAutoCompleteInput 
                    label="STREET"
                    value={street}
                    name="street"
                    onAddressCompleted={addressCompleted}
                    onChange={(e: any) => setStreet(e.target.value)}
                    ref={inputs[0]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "Street is a required field"},
                        {type: ValidationType.minLength, config: 3, errorMessage: "Must contain at least 3 characters"},
                        {type: ValidationType.regex, config: /^([A-Z0-9a-z\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]}                    
                />
                <KTextInput 
                    label= "APARTMENT #"
                    value={complement}                    
                    name="complement"
                    onChange={(e: any) => setComplement(e.target.value)}                    
                    validations={[
                        {type: ValidationType.regex, config: /^([A-Z0-9a-z\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]} />
                <KTextInput 
                    label= "CITY"
                    value={city}
                    name="city"
                    onChange={(e: any) => setCity(e.target.value)}
                    ref={inputs[2]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "City is a required field"},
                        {type: ValidationType.minLength, config: 3, errorMessage: "Must contain at least 3 characters"},
                        {type: ValidationType.regex,
                            config: /^([A-Za-z\s])*$/g,
                            errorMessage: "Please enter a valid city name"
                        }
                    ]} />
                <KTextInput 
                    label= "STATE"
                    value={state}
                    name="state"
                    onChange={(e: any) => setState(e.target.value)}
                    ref={inputs[3]}
                    maxLength={2}
                    validations={[
                        {type: ValidationType.required, errorMessage: "State is a required field"},
                        {type: ValidationType.list, config: stateList, errorMessage: "Invalid State"},
                        {type: ValidationType.regex, config: /^([A-Za-z\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]} />
                <KTextInput 
                    label= "ZIP CODE"
                    value={zipCode}
                    name="zipcode"
                    maxLength={10}
                    onChange={(e: any) => setZipCode(e.target.value)}
                    ref={inputs[4]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "Zip is a required field"},
                        {type: ValidationType.regex, config: /^(\d{5}(?:-\d{4})?)$/gm, errorMessage: "Zip format is invalid"}]} />                                    

                <KButton loading={loading} design="primary" >VERIFY</KButton>                                                     
            </form>
        </div>
    )
}


export default AddAddress