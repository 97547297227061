import { SVGProps } from "react"

interface LogoProps extends SVGProps<SVGSVGElement>  {
  backgroundColor?: string
  color?: string
}

export const Logo = ({ backgroundColor = "transparent", color = "black", ...props }: LogoProps): JSX.Element => (
  <svg
    width={90}
    height={22}
    style={{ backgroundColor }}
    {...props}
  >
    <path
      d="M9.82 21.69l-6.403-7.155v7.156H0V0h3.417v13.08l6.099-6.543h4.279l-6.931 7.285 7.388 7.869H9.819zM19.261 21.69h-3.417V.005h3.417V21.69zM35.71 6.541V21.69h-3.448v-2.385C31.308 21.01 29.738 22 27.738 22c-3.88 0-6.249-2.602-6.249-6.846V6.54h3.417v8.21c0 2.386 1.446 3.934 3.663 3.934 2.216 0 3.693-1.548 3.693-3.934V6.54h3.447zM37.943 6.541V2.483h3.447v4.058h3.94v3.285h-3.94v6.102c0 1.921 1.17 2.695 2.832 2.695.373.007.746-.034 1.108-.122v3.189c-.523.122-1.293.184-1.847.184-3.324 0-5.54-2.045-5.54-5.888V6.541zM46.71 14.132c0-4.492 3.386-7.9 7.788-7.9 3.632 0 6.74 2.447 7.203 5.73h-3.51c-.37-1.393-1.908-2.415-3.693-2.415-2.494 0-4.402 1.982-4.402 4.585 0 2.571 1.908 4.553 4.402 4.553 1.785 0 3.355-1.022 3.724-2.54h3.508C61.33 19.584 58.16 22 54.497 22c-4.401 0-7.787-3.377-7.787-7.868zM78 13.063v8.64h-3.448v-8.21c0-2.386-1.447-3.935-3.632-3.935-2.247 0-3.724 1.549-3.724 3.934v8.212h-3.417V.018h3.417V8.94c.954-1.704 2.555-2.695 4.555-2.695C75.6 6.245 78 8.847 78 13.063z"
      fill={color}
    />
  </svg>
)
