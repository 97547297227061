import { FormEvent,  useEffect, useRef, useState } from "react"
import NavBody from "../NavBody"
import classes from "./ConfirmPayment.module.scss"
import { useTransferSources } from "../../hooks/useTransfersSources"
import { CardPaymentService, GraphQLException, PaymentType, TransferSource, TransferSourceStatus, TransferSourceType } from "@klutch-card/klutch-js"
import {KTextInput, KChangeEvent } from "@klutchcard/klutch-webcomponents"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { KSelectInput } from "@klutchcard/klutch-webcomponents"
import { useHistory, useParams } from "react-router-dom"
import { useQuery } from "../../hooks/useQuery"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"

export const ConfirmPayment = (): JSX.Element => {

    const transferSources = useTransferSources()?.filter(x => x.type != TransferSourceType.Inbound && x.status == TransferSourceStatus.ACTIVE)
    const [selectedTransferSource, setSelectedTransferSource] = useState<TransferSource>()
    const [loading, setLoading] = useState(false)
    const [serverError, setServerError] = useState("")
    const [paid, setPaid] = useState(false)

    const query = useQuery()

    const type = query.get("type")
    const queryAmount = (query.get("amount") || 0)
    const [amount, setAmount] = useState<string>(queryAmount + "")
    const inputs = [useRef<KTextInput>(null)]

    const history = useHistory()


    useEffect(() => {
        if (transferSources && !selectedTransferSource) {
            setSelectedTransferSource(transferSources[0])
        }
    }, [transferSources])

    const changeAmount = (e: KChangeEvent) => {
        const value = e.target.value
        const currentValue = value.replace(/[^\d.]/g, "")

        setAmount(previousValue => {
            if (!previousValue || value.length > previousValue.length) {
                return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return ""
        })

    }


    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        setServerError("")
        const validated = inputs.map(i => {
            const v = i.current?.validate(true)
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setLoading(false)
            return
        }

        try {
            if (!selectedTransferSource) {
                return
            }
            await CardPaymentService.createPayment(selectedTransferSource.id, type! as PaymentType, +amount)
            setPaid(true)

        } catch (e) {
            if (e instanceof GraphQLException) {
                const [alloyException] = e.getAlloyCoreExceptionType()
                setServerError(e.getAlloyDescriptions().join(", "))

            }
        }
        finally {
            setLoading(false)
        }
    }


    const drawBody = () => {
        if (!paid) {
            return (
                <>
               <p>Select a payment source and confirm your payment</p>
                <p>NOTICE: If amount is less than your balance, you will incurr in interest fees.</p>
                <form onSubmit={handleFormSubmit}>
                    {transferSources && (
                        <KSelectInput
                            label="PAY FROM"
                            options={transferSources.map(c => ({ label: c.name, value: c }))}
                            value={selectedTransferSource}
                            onValueChanged={v => setSelectedTransferSource(v.value)}
                        />
                    )}
                    <KTextInput
                        value={amount}
                        onChange={changeAmount}
                        label="AMOUNT"
                        ref={inputs[0]}
                        disabled={type != "OTHER"}
                        name="amount"
                        validations={[
                            { type: ValidationType.required, errorMessage: "Amount is required" },
                            { type: ValidationType.number, errorMessage: "Deposit Amount has to be a number" },
                        ]}
                    />
                    <KButton design="primary">PAY NOW</KButton>
                </form>
                </>
            )
        }
        else {
            return (
            <>
                <div className={classes.paidMain}>                    
                    <span className={`material-symbols-outlined ${classes.checkMark}`}>done</span>
                    <h3>Payment Sent</h3>
                    <p>Please allow up to 3 business days for your payment to be reflected on your account</p>
                    <KButton design="primary" className={classes.button} onClick={() => history.replace("/home")}>HOME</KButton>
                </div>
            </>
            )
        }
    }

    return (
        <NavBody title="Pay my bill">
            <div className={classes.main}>
                {drawBody()}
            </div>
        </NavBody>
    )
}

