import { TransferSource, TransferSourceService, TransferSourceStatus, TransferSourceType } from "@klutch-card/klutch-js"
import { useTransferSources } from "../../hooks/useTransfersSources"
import NavBody from "../NavBody"
import classes from "./BankAccounts.module.scss"
import { useState } from "react"
import { Dialog } from "@klutchcard/klutch-webcomponents"
import { InboundTransferSource } from "./InboundTransferSource"
import { Link, useHistory } from "react-router-dom"

export const BankAccounts =  () : JSX.Element => {
    
    const transferSources = useTransferSources()?.filter(t => t.status != TransferSourceStatus.DELETED)

    const [deleteId, setDeleteId] = useState("")
    const [deleting, setDeleting] = useState(false)
    const [showInboundTransferSource, setShowInboundTransferSource] = useState(false)

    const history = useHistory()

    
    const deleteBankAccount = async () => {
        if (!deleteId) {
            return
        }
        setDeleting(true)
        await TransferSourceService.deleteTransferSource(deleteId)
        setDeleteId("")
        setDeleting(false)
    }

    const transferSourceClicked = (t: TransferSource) => {
        if (t.type == TransferSourceType.Inbound) {
            setShowInboundTransferSource(true)
            return
        }
        if (t.type == TransferSourceType.MicroDeposits && t.status ==  TransferSourceStatus.PENDING) {
            history.push(`/transfersources/${t.id}/verify`)            
            return
        }
    }
    
    
    return (
        <NavBody title="Bank Accounts">
            <div className={classes.main}>
                {transferSources?.map(t => {

                        const clickable = (t.type == TransferSourceType.Inbound)  || (t.type == TransferSourceType.MicroDeposits && t.status == TransferSourceStatus.PENDING)
                        
                        let status: string = t.status.toString()

                        if (t.type == TransferSourceType.Plaid && t.status != TransferSourceStatus.ACTIVE) status = "Waiting for Plaid Validation"
                        if (t.type == TransferSourceType.MicroDeposits && t.status == TransferSourceStatus.PENDING) status = "Click to Verify Micro-deposits"
                        
                        return (
                        <div 
                            key={t.id} 
                            className={`${classes.transferSource} ${clickable ? classes.clickable : ""}`}
                            onClick={() => transferSourceClicked(t)}>
                            <span className={classes.name}>{t.name}</span>
                            <span className={classes.status}>{status}</span>
                            {t.type != TransferSourceType.Inbound && (
                                <span className={`material-symbols-outlined ${classes.closebutton}`} onClick={() => setDeleteId(t.id)}>close</span>
                            )}
                            
                        </div>                    
                    )
                })}
                <div className={`${classes.transferSource} ${classes.addBank}`} onClick={() => history.push("/transfersources/add")}>
                    <span className={classes.name}>ADD BANK ACCOUNT</span>
                    <span className={`material-symbols-outlined ${classes.addButton}`}>add</span>
                </div>
                {(transferSources?.length || 0) > 0 && (
                    <Link to="/payments">To pay your Bill, click here or select "Pay my bill" from the account menu</Link>
                )}
                
            </div>            
            <Dialog 
                buttons={[
                    {label: "CANCEL", type: "cancel", onClick:() => setDeleteId("")}, 
                    {label: "DELETE", type: "delete", loading: deleting, onClick:deleteBankAccount}
                ]}
                onDismiss={() => setDeleteId("")}
                content={`Are you sure you want to delete this Bank Account?

                            If you delete this bank account, you will not be able to pay off your Klutch credit card using this account. 

                            You will need to add another Bank account for all future payments.`}
                title="DELETE BANK ACCOUNT"
                show={deleteId != ""}
            />
            <InboundTransferSource show={showInboundTransferSource} dismiss={() => setShowInboundTransferSource(false)} />
        </NavBody>
    )
}