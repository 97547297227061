import { useState } from "react"
import useRecipes, { useInstalledRecipes } from "../../hooks/useRecipes"
import NavBody from "../NavBody"
import classes from "./MiniApps.module.scss"
import { Recipe } from "@klutch-card/klutch-js"
import { useHistory } from "react-router-dom"


export const MiniApps = (): JSX.Element => {

    const recipes = useRecipes()
    const [currentTab, setCurrentTab] = useState<"gallery" | "myminiapps">("gallery")

    const installs = useInstalledRecipes()

    const history = useHistory()
    
    const showGallery = () => {
        return (
            <>
                <h4>FEATURED MINIAPPS</h4>
                <div className={classes.featuredMiniapps}> 
                    <div className={classes.featuredMiniapp} onClick={() => history.push("/miniapps/CarbonNeutral") }>
                        <img src="https://uploads-ssl.webflow.com/5e28a482a91384f1b91b12df/660c5501b57fdb8bfa41ab4f_Klutch%20banner%20featured%20miniapps%402x.jpg" alt="Featured miniapp" />
                        <span className={classes.itemName}>Climate Impact</span>
                        <span>Help the planet with every transaction</span>
                    </div>                
                    <div className={classes.featuredMiniapp} onClick={() => history.push("/miniapps/BinkeyHSA")} >
                        <img src="https://uploads-ssl.webflow.com/5e28a482a91384f1b91b12df/660209e72487658687e62875_binkey.png" alt="Featured miniapp" />
                        <span className={classes.itemName}>FSA / HSA Reimbursement</span>
                        <span>Flexible Spending Account Reimbursement</span>
                    </div>

                </div>
                <div className={classes.miniapps}>
                    <h4>MINIAPPS</h4>
                    {!recipes || recipes.length == 0 && (
                        <>
                            <MiniAppSkeleton />
                            <MiniAppSkeleton />
                            <MiniAppSkeleton />
                        </>
                    )}
                    {recipes?.map(r => (
                        <MiniAppItem key={r.id} miniapp={r} />
                    ))}
                    <div>
                    </div>
                </div>

            </>
        )
    }

    const showMyMiniApps = () => {

        return (
            <div className={classes.miniapps}>
               {installs?.map(c => (
                <MiniAppItem key={c.id} miniapp={c.recipe!} />
               ))} 
            </div>
        )
    }

    return (
        <NavBody title="MINI APPS" className={classes.navBody}>
            <div className={classes.main}>
                <div className={classes.tabs}>
                    <span className={currentTab == "gallery" ? classes.selected : ""} onClick={() => setCurrentTab("gallery")}>GALLERY</span>
                    <span className={currentTab == "myminiapps" ? classes.selected : ""} onClick={() => setCurrentTab("myminiapps")}>MY MINIAPPS</span>
                </div>
                {currentTab == "gallery" ? showGallery() : showMyMiniApps()}

            </div>
        </NavBody>
    )
}


const MiniAppItem: React.FC<{miniapp:Recipe}> = ({miniapp}) => (

    <a className={classes.miniappItem} href={`/miniapps/${miniapp.id}`}>
        <img src={miniapp.iconUrl} alt={miniapp.name} />
        <div className={classes.itemRow}>
            <span className={classes.itemName}>{miniapp.name}</span>
            <span className={classes.itemDescription}>{miniapp.description}</span>
        </div>
    </a>
)

const MiniAppSkeleton: React.FC = () => (
    <a className={classes.miniappItem} >
    <div className={classes.skeletonIcon} />
    <div className={classes.itemRow}>
        <span className={`${classes.itemName} ${classes.skeletonName}`}></span>
        <span className={`${classes.itemDescription} ${classes.skeletonDescription}`}></span>
    </div>
</a>
)