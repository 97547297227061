import { useEffect, useState } from "react"
import classes from "./InfoBanner.module.scss"
import { useHistory } from "react-router-dom"

const INVITE_FRIENDS_BANNER_SHOW = "klutchcard.banners.inviteFriends.show"

export const InfoBanner: React.FC = () => {

    const [showBanner, setShowBanner] = useState(false)

    const history = useHistory()

    useEffect(() => {
        const value = localStorage.getItem(INVITE_FRIENDS_BANNER_SHOW)
        console.log("Valeu", value)
        if (!value || value != "hide") {
            setShowBanner(true)
        }
    })
    

    if (!showBanner) {
        return null;        
    }

    const closeClicked = () => {
        localStorage.setItem(INVITE_FRIENDS_BANNER_SHOW, "hide")
        setShowBanner(false)
    }


    return (
        <div className={classes.infoBanner} >
            <span className={classes.text} onClick={() => history.push("/account/refer-a-friend")}>Share Klutch with your friends and earn $20 each!</span>
            <span className={`${classes.closeButton} material-symbols-outlined`} onClick={closeClicked}>close</span>
        </div>
    )

}