
import { Address } from "@klutch-card/klutch-js"
import { KTextInput, KTextInputProps } from "@klutchcard/klutch-webcomponents"
import { usePlacesWidget } from "react-google-autocomplete"


export interface KPlacesAutoCompleteInputProps extends KTextInputProps {
    onAddressCompleted?: (address: Address) => void    
}

export const KPlacesAutoCompleteInput =  (props: KPlacesAutoCompleteInputProps):  JSX.Element => {
    const { ref } = usePlacesWidget<KTextInput>({
      apiKey:"AIzaSyBKiB-yB8m3TLnsoIeiU-umpUnMuvxQe-U",
      options: {
        types: ["address"],
        componentRestrictions: { country: "us" },
      },
      onPlaceSelected: (place) => { 
               
        const addr =place.address_components
        if (addr) {
          const streetNumber = addr.find((c: any) => c.types.includes("street_number"))?.long_name
          const street = addr.find((c:any) => c.types.includes("route"))?.long_name
          const complement = addr.find((c: any) => c.types.includes("subpremise"))?.long_name
          const city = addr.find((c:any) => c.types.includes("locality"))?.long_name
          const state = addr.find((c:any) => c.types.includes("administrative_area_level_1"))?.short_name
          const zipCode = addr.find((c:any) => c.types.includes("postal_code"))?.long_name
          const address = new  Address({streetNumber, street, complement, city, state, zipCode})
          props.onAddressCompleted && props.onAddressCompleted(address)  
        }
      }
    });
    //@ts-ignore: type of inputs are the same
    return (<KTextInput inputRef={ref as any} {...props} />)
  }







