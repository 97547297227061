import { AccountService, Agreement, AgreementType } from "@klutch-card/klutch-js"
import React, { FormEvent, useEffect, useState } from "react"
import { Spinner } from "react-activity"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { retry } from "../../helper"

import classes from "./PreApproved.module.scss"
import { Analytics } from "../../services/analytics"
import useRevolvingLoan from "../../hooks/useRevolvingLoan"


export const PreApproved: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [loanAgreement, setLoanAgreement] = useState<Agreement>()
  const history = useHistory()
  
  const revolvingLoan = useRevolvingLoan()

  useEffect(() => {
    const run = async () => {
      setLoading(true)
      Analytics.pushEvent("app_apply_preapproved");
      let la: Agreement|undefined
      try {
        const agreements: Agreement[] = await retry(async () => {
          const a = await AccountService.getAgreements()          
          la = a.find((x: Agreement) => x.agreementType === AgreementType.LOAN_AGREEMENT)
          if (!la) {
            throw new Error()
          }
          return la
      }, 6, 5000) 
     
      if (!agreements) history.push("/apply/thankyou")
      setLoanAgreement(la)
      setLoading(false)
      } catch (e) {
        history.push("/apply/thankyou")
      }

    }
    run()
    
     
  }, [])

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
         
    history.push("/apply/download-app")    
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <div style={{justifyContent: "center", marginTop: 250}}>
          <Spinner size={30}/>
        </div>
      ) : (
      <>
      <h1 style={{ fontWeight: "bold" }}>You are Pre-Approved!</h1>

      {revolvingLoan?.type == "UNSECURED" && (
        <>
          Success! By pressing “agree and continue” you are accepting these terms and Klutch will do a hard pull on credit score to issue your Klutch card. This may impact your credit score.
          <br /><br />
          Terms & Conditions
          <br /><br />
          Your credit limit is flexible. Unlike a traditional card with a set limit, the amount you can spend adapts based on factors such as your purchase, payment, payment, and credit history. The bank services are provided by Synapse's partner banks, Members FDIC. By accepting the terms of this loan on Klutch, you agree to Synapse's <a href={loanAgreement?.url} style={{ fontWeight: "bold" }}>Consumer Loan Agreement</a> and <a href={"https://www.klutchcard.com/docs/agreements/cardholder_agreement.pdf"} style={{ fontWeight: "bold" }}>Consumer Cardholder Agreement</a>.
          <br /><br />
          Certifications
          <br /><br />
          I certify that the SSN I provided is correct; I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and I am a U.S. citizen.
          <br /><br />
        </>        
      )}
      {revolvingLoan?.type == "SECURED" && (
          <>
            Success! By pressing “agree and continue” you are accepting these terms.
            <br /><br />
            Terms & Conditions
            <br /><br />
            Your credit limit amoutn is your security deposit amount. Your security deposit will be returned to you when you graduate to our unsecured card or when you close your account. . The bank services are provided by Synapse's partner banks, Members FDIC. By accepting the terms of this loan on Klutch, you agree to Synapse's <a href={loanAgreement?.url} style={{ fontWeight: "bold" }}>Consumer Loan Agreement</a> and <a href={"https://www.klutchcard.com/docs/agreements/cardholder_agreement.pdf"} style={{ fontWeight: "bold" }}>Consumer Cardholder Agreement</a>.
            <br /><br />
            Certifications
            <br /><br />
            I certify that the SSN I provided is correct; I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and I am a U.S. citizen.
            <br /><br />
        </>
      )}

      <form onSubmit={handleFormSubmit} >
        <KButton loading={loading} design="primary">AGREE & CONTINUE</KButton>
      </form>
      </>
      )}
    </div>
  )
}
