import React, {  FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { KChangeEvent, KTextInput } from "@klutchcard/klutch-webcomponents"
import {  ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import classes from "./Verifying.module.scss"
import { AccountService, ApprovalStatus, UserService } from "@klutch-card/klutch-js"
import {DateTime} from "luxon"
import { Spinner } from "react-activity"
import "react-activity/dist/library.css";
import { retry } from "../../helper"

const Verifying : React.FC = () => {

    const history = useHistory()


    const decideNextStep = async () => {
        const account = await AccountService.getAccount()
        console.log("Acc :>> ", account.approvalStatus);
        switch (account.approvalStatus) {
            case ApprovalStatus.APPROVED: 
                    history.push("/apply/pulling-credit")
                    break;
            case ApprovalStatus.SECONDARY_INSPECTION: 
                history.push("/apply/verify-document")
                break;
            case ApprovalStatus.REJECTED: 
                    history.push("/apply/rejected")
                    break;  
            case ApprovalStatus.CLOSED: 
                history.push("/apply/rejected")
                break;                                      
            default:
                history.push("/apply/thankyou")
        }  
    }

    useEffect(() => {
        const run = async () => {
            try {

                await AccountService.requestApproval()
                const approvalStatus = await retry(async () => {
                    const account = await AccountService.getAccount()
                    if ([ApprovalStatus.REQUESTED, ApprovalStatus.NOT_VERIFIED, undefined].includes(account.approvalStatus)) {
                        throw new Error()
                    }
                    return account.approvalStatus
                }, 6, 5000)
                await decideNextStep()
            } catch (e) {       
                console.error(e)         
                decideNextStep()
            }
        }
        run()
    },[])



    return (
        <div className={classes.container}>            
            <h1>Verifying</h1>
            <span>Please wait while we verify your identity. <br />This usually takes less than 30 seconds.</span>            
            <div className={classes.spinnerDiv}>
                <Spinner size={30}/>
            </div>
            
            
        </div>
    )
}


export default Verifying