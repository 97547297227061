import React, {  FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { KChangeEvent, KTextInput } from "@klutchcard/klutch-webcomponents"
import {  ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import classes from "./AddSSN.module.scss"
import { AccountService, UserService } from "@klutch-card/klutch-js"
import {DateTime} from "luxon"
import { Analytics } from "../../services/analytics"

const AddAnnualIncome : React.FC = () => {

    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)

    const [income, setIncome] = useState<string>("")


    const history = useHistory()


    const [cardType, setCardType] = useState("UNSECURED")


    useEffect(() => {
        (async () => {
            const cardType = await AccountService.getExtraData("cardType")
            setCardType(cardType)                     
        })()
    }, [])
    
    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)


        if (!income || income.trim() == "") {
            setLoading(false)
            return
        }
        try {            
            const incomeNumber = parseFloat(income.replace(/,/g, ""))
            if (!incomeNumber || incomeNumber < 0 || incomeNumber > 9_999_999 ) {
                setServerError("Invalid Income")
                setLoading(false)
                return
            }
            await AccountService.addExtraData({ income: incomeNumber })
        } catch (e) {
            setServerError((e as Error).message) 
            setLoading(false)
        }
        Analytics.pushEvent("app_apply_income_added"); 
        
        history.push("/apply/verifying")
    }

    const changeIncome = (e: KChangeEvent) => {
        const value = e.target.value
        const currentValue = value.replace(/[^\d]/g, "")

        setIncome(previousValue => {
            if (!previousValue || value.length > previousValue.length) {
                return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
              return ""            
        } )
    }


    return (
        <div className={classes.container}>            
            <h1>Annual Income</h1>
            <div>Please provide your estimated total annual income including all sources of income. Include shared income not earned by you that is regularly deposited into your individual or joint account.</div>            
            <form onSubmit={handleFormSubmit} >
                <div className={classes.serverError}>{serverError}&nbsp;</div>
                    <KTextInput 
                        label= "TOTAL ANNUAL INCOME"
                        value={income}
                        name="income"
                        onChange={changeIncome}                    
                        inputMode="numeric"
                        validations={[
                            { type: ValidationType.required, errorMessage: "" },
                            { type: ValidationType.number, errorMessage: "Income has to be a number" },
                        ]} 
                    />
                {cardType == "UNSECURED" && (
                    <>
                        <div className={classes.consentTitle}>Your Consent</div>                
                        <div className={classes.consent}>                
                        You understand that by clicking on “submit” button below,
                        you are providing 'written instructions' to us under the Fair Credit Reporting Act
                        authorizing us to obtain information from your personal credit report or other information from Equifax.
                        You authorize Klutch to obtain such information solely to conduct a pre-qualification for credit.
                        </div>                    
                    </>
                )}           

                <KButton loading={loading} design="primary" >SUBMIT</KButton>                                                     
            </form>
        </div>
    )
}


export default AddAnnualIncome