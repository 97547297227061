
import { useEffect, useState } from "react"
import classes from "./SecurityDeposit.module.scss"
import { CardPaymentService, Payment, TransferSource, TransferSourceService } from "@klutch-card/klutch-js"

import Plaidbutton from "../../components/PlaidButton/plaidbutton";
import { useHistory } from "react-router-dom";
import { toCurrency } from "@klutchcard/klutch-webcomponents";

export const SecurityDeposit: React.FC = () => {


    const [transferSource, setTransferSource] = useState<TransferSource|undefined>()
    const [selectedOption, setSelectedOption] = useState<"WIRE" | "PLAID" | undefined>()
    const [linkToken, setLinkToken] = useState("")
    const [payment, setPayment] = useState<Payment>()
    

    const history = useHistory()

    useEffect(() => {
        (async () => {
            const payments =  await CardPaymentService.listPayments()
            if (payments && payments.length > 0) {
                setPayment(payments[payments.length -1])
            }            
        })()
    }, [])

    useEffect(() => {        
        if (!selectedOption) {
            return
        }

        switch (selectedOption) {
            case "WIRE": 
                (async () => {
                    const transferSource = await TransferSourceService.addTransferSource("", "Inbound", {})
                    setTransferSource(transferSource)
                    console.log(transferSource)
                })() ;
                break
            case "PLAID": 
                (async () => {
                    const linkToken = (await TransferSourceService.getTransferSourceConfig("Plaid", {
                        redirectUrl: window.location.href
                    })).linkToken
                    setLinkToken(linkToken)

                })() ;
                break
        }

        
        
                       
    }, [selectedOption])

    


    const onPlaidSuccess = (ts: TransferSource) => {       
        history.replace("/apply/security-deposit/plaid", {transferSource: ts})
    }

    const onPlaidError = (err: string) => {
       console.log("Plaid Error" , err)
    }

    return (
        <div className={classes.container}>
            <h1>Security Deposit</h1>
            <div className={classes.info}>
            <p>A security deposit is required to use your card. </p>
            <p>Please deposit an amount between $200 and $5,000. </p>
            <p>The deposit will be your card limit. </p>
            <p>You will receive your deposit back if you close your account or when converted to the Kluth Credit product.</p>
            <p>We will notify you via email when we receive your security deposit to finish setting up your account</p>            
            </div>
            {payment ? (
                <div className={classes.paymentProcessing}>
                    We are processing your payment below. Please wait until the payment is fully settled to create your card.
                    <div className={classes.paymentTable}>
                    <span>Amount:</span><span>${toCurrency(payment.amount)}</span>
                    <span>Status:</span><span>{payment.status}</span>
                    <span>Funding Source:</span><span>{payment.transferSource.name}</span>
                    </div>
                </div>
            ) : (
                <div className={classes.chooseMethod}>
                    Choose how you want to send your security deposit:
                    <div className={classes.methodOption} onClick={() => setSelectedOption("WIRE")}>
                        <header>Wire (fastest)</header>
                        <span>Send us a wire from your bank. You can start using the card as soon as we receive the funds</span>
                    </div>
                    <div className={classes.methodOption} onClick={() => setSelectedOption("PLAID")}>
                        <header>Automatic Transfer</header>
                        <span>Connect your bank account via Plaid and we transfer the money. Approval in 1-2 weeks after we receive the funds</span>
                    </div>
                </div>
            )}
            {selectedOption == "WIRE" && (
                <div className={classes.wireDiv}>
                    <h3>Wire Information</h3>
                    <p>We only accept wires for security deposits. We do not accept ACHs or checks</p>
                    <p>Please use your name and address as receipient of the wire. Use the same info you used when signing up at Klutch.</p>
                    <div className={classes.wireInfo}>
                        <div>
                            <p>Routing Number: {transferSource?.routingNumber ?? "Loading..."}</p>
                            <p>Account Number: {transferSource?.accountNumber ?? "Loading..."}</p>
                        </div>
                        <div>
                            <p>Bank Address:</p>
                            <p>Evolve Bank and Trust</p>
                            <p>6070 Poplar Ave</p>
                            <p>Suite 200</p>
                            <p>Memphis, TN 38119</p>
                        </div>
                    </div>
                </div>
            )}
            {selectedOption == "PLAID" && linkToken && (
            <div className={classes.plaidDiv}>

                <Plaidbutton 
                    token={linkToken} 
                    onSuccess={onPlaidSuccess} 
                    onError={onPlaidError}/>
            </div>
            )}
        </div>
    )
}