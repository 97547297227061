/* eslint-disable */
  
  const loadVouched = (config) => {
    const existingScript = document.getElementById("vouched");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://static.vouched.id/widget/vouched-2.0.0.js";
      script.id = "vouched";
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        var vouched = window["Vouched"]({ ...config });
        vouched.mount("#vouched-element");
      };
    }
  };
  
  export default loadVouched;