import { GraphQLException, UserService } from "@klutch-card/klutch-js"
import React, {   FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { KTextInput } from "@klutchcard/klutch-webcomponents"
import   { KChangeEvent } from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"

import classes from "./AddPhone.module.scss"
import { Analytics } from "../../services/analytics"
import {DateTime} from "luxon"
import { useUser } from "../../services/klutchHooks"
import { Spinner } from "react-activity"

const AddPhone: React.FC = () => {

    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)
    const [duplicatePhone, setDuplicatePhone] = useState(false)

    const [phone, setPhone] = useState("")
    const [birthDate, setBirthDate] = useState("")

    

    const history = useHistory()

    const inputs = [useRef<KTextInput>(null), useRef<KTextInput>(null)]
    
    const phoneField = useRef<HTMLInputElement>(null)

    const user = useUser()

    useEffect(() => {
        if (user && user.birthDate) {
            setBirthDate(DateTime.fromJSDate(user.birthDate).toFormat("MM/dd/y"))
        }
    }, [user?.id])

    const handleSubmit = async (e: FormEvent) => {           
        e.preventDefault()
        const valid = inputs.map( i => {
            const v = i.current?.validate(true) 
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)
        if (!valid) {            
            return
        }
        setLoading(true)
                     
        try {        
            const phoneNumber = phone.replace(/[^\d]/g, "")            

            const dob = DateTime.fromFormat(birthDate, "MM/dd/y", {zone: "utc"})
            await UserService.changeBirthDate(dob.toJSDate())                                

            await UserService.changePhone(phoneNumber)    
            Analytics.pushEvent("app_apply_phone_added");   

            history.push(`/apply/verifyPhone?phone=${phoneNumber}`)
        } catch (e) {
            if (e instanceof GraphQLException) {
                const [alloyException] = e.getAlloyCoreExceptionType()
                if (alloyException == "com.alloycard.core.entities.DuplicateEntityException") {
                    setDuplicatePhone(true)
                    setLoading(false)
                    return
                }                
            }             
            setServerError((e as Error).message)            
        }
        
        
    }

    const normalizeBirthdateInput = (value: string, previousValue: string) => {
        if (!value) return value
      
        const currentValue = value.replace(/[^\d]/g, "")
        const cvLength = currentValue.length 
      
        if (!previousValue || value.length > previousValue.length) {

          if (cvLength < 2) return currentValue       
          if (cvLength < 4) return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`       
          return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}/${currentValue.slice(4, 8)}` 
        }
      }

    const changeBirthDate = (e: KChangeEvent) => {
    const text = e.target.value

    setBirthDate(prev => {
        const normalized = normalizeBirthdateInput(text, prev) ?? text
        if (normalized.length >= 10) {
            phoneField.current?.focus()
        }
        return normalized
    })            
  }

    const changePhoneNumber = (event: KChangeEvent) => {

        const text: string = event.target.value
        
        if (!text) {
            setPhone("")
            return
        }
        let currentValue = text.replace(/[^\d]/g, "")
        if (text.startsWith("1")) {
            currentValue = currentValue.slice(1)
        }
        if (text.startsWith("+1")) {
            currentValue = currentValue.slice(1)
        }

        const cvLength = currentValue.length

        setPhone(prev => {
            let normalized = currentValue
            if (!prev || text.length > prev.length) {
                if (cvLength < 4) {
                    normalized = currentValue
                } else if (cvLength < 7) {
                    normalized = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
                } else {
                    normalized = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
                }
            }
            if (normalized.length >= 14) {
//                phoneField?.blur()
            }
            return normalized
        })
    }

    const adultAgo =  new Date((Date.now() - (1000 * 60 * 60 * 24 * 365.25 * 18)))
    const oldestAgo = new Date((Date.now() - (1000 * 60 * 60 * 24 * 365.25 * 100)))



    return (
        <div className={classes.container}>            
            <h1>PHONE AND BIRTH DATE </h1>
            <form onSubmit={handleSubmit}>
                <div className={classes.serverError}>{serverError}&nbsp;</div>
                {duplicatePhone && <a className={classes.serverError} href="/auth/login">This phone is already associated with an account. Click here to login.&nbsp;</a>}
                <h4>We need to make sure you're you. Please let us know what number to send a code to</h4>
                <KTextInput 
                    label= "BIRTH DATE"
                    value={birthDate}
                    name="birthDate"
                    onChange={changeBirthDate}
                    inputMode="numeric"
                    maxLength={10}
                    ref={inputs[0]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "Birthdate is a required field"},
                        {type: ValidationType.regex, config: /^\d{2}\/\d{2}\/\d{4}$/gm , errorMessage: "Birthdate format needs to be MM/DD/YYYY"},
                        {type: ValidationType.beforeDate, config: adultAgo, errorMessage: "Must be over 18 years of age to apply (19 years in AL)" },
                        {type: ValidationType.afterDate, config: oldestAgo, errorMessage: "Must be less than 100 years of age to apply" }
                    ]} />                
                <KTextInput 
                    label="MOBILE NUMBER"
                    value={phone}
                    ref={inputs[1]}
                    name="phone"                    
                    onChange={changePhoneNumber}
                    inputRef={phoneField}
                    type="tel"
                    validations={[
                        { type: ValidationType.required, errorMessage: "Mobile number is a required field" },
                        { type: ValidationType.regex, config: /^\(\d{3}?\) \d{3}[-]\d{4}$/gm, errorMessage: "Phone format should be (XXX) XXX-XXXX" }
                    ]} />
                
                <KButton loading={loading}                    
                    design="primary">SEND CODE</KButton>                                                     
            </form>
        </div>
    )
}


export default AddPhone