import { RecipePanel, RecipePanelSize, RecipesService } from "@klutch-card/klutch-js"
import { useHistory, useLocation } from "react-router-dom"
import classes from "./MiniAppDynamicComponent.module.scss"
import { LegacyRef, useEffect, useRef, useState } from "react"

export interface MiniAppDynamicComponentProps {
    panel: RecipePanel
}

type KlutchMessage  = {
    action: string, 
    params?: any
}



export const MiniAppDynamicComponent = ({panel}: MiniAppDynamicComponentProps): JSX.Element => {
    
    const history = useHistory()
    const location = useLocation()
    const frame = useRef<HTMLIFrameElement>()
    
    if (!panel || !panel.recipeInstall || !panel.recipeInstall.recipe)  {
        return <></>
    }

    const recipeInstall = panel.recipeInstall
    const recipe = panel.recipeInstall.recipe
    




    let templateUrl = recipe.templates?.find(c => c != null && c.name == panel.templateFileName.substring(1) || c.name == panel.templateFileName)?.url 

    
    if (templateUrl) {
        if (templateUrl.indexOf("?") >= 0) {
            templateUrl = templateUrl + location.search.replace("?", "&")
        } else {
            templateUrl = templateUrl + location.search 
        }    
    }


    

    

    const [token, setToken] = useState("")
    
    useEffect(()=> {
        const runOnce = async () => {
            const token = await RecipesService.getRecipeInstallToken(recipeInstall.id)
            setToken(token)
        }
        runOnce()
    }, [])





    useEffect(() => { 
        if (!frame || !templateUrl || !token)   {
            return
        }

        let port: MessagePort

        const frameLoadHandler = (e: any) => {
            const channel = new MessageChannel()
            port = channel.port1
            port.onmessage = messageHandler
            frame.current?.contentWindow?.postMessage("KlutchInit", templateUrl, [channel.port2]) 
            
        }

        frame.current?.addEventListener("load", frameLoadHandler)

        


        const messageHandler = (event: MessageEvent) => {
            const obj = JSON.parse(event.data)  as KlutchMessage                        
            switch (obj.action) {
                case "ready": {
                    port.postMessage({
                            templateData: panel.data, 
                            token: token, 
                            recipeInstallConfig: recipeInstall.configuration,
                            recipeInstallId: recipeInstall.id,
                            locationState: location.state
                    })  
                    break;
                }
                case "openTemplate": {
                    const {name, replace, state} = obj.params 
                    const location = `/miniapps/${recipe.id}/${name}`.replaceAll("//", "/")
                    if (replace) {
                        history.replace(location, state)
                    } else {
                        history.push(location, state)
                    }
                    break;
                }  
                case "changePanelData": {                    
                    const {data} = obj.params
                    RecipesService.changePanelData(panel, data)
                    break;
                }     
                case "changeRecipeInstallConfiguration": {
                    const {data} = obj.params
                    RecipesService.changeRecipeInstallConfig(recipeInstall.id, data)
                    break;
                }
                case "goto": {                                             
                    const {location, replace, state} = obj.params
                    if (replace) {
                        history.replace(location, state)
                    } else {
                        history.push(location, state)
                    }
                    break;
                }
                case "goBack": {
                    history.goBack()
                    break;
                }  
                case "configPanel": {                
                    //props.panelConfigCallback && props.panelConfigCallback(obj.params)
                    break;
                }      
                case "addPanel" : {
                    const {templateName, data, entity, order, size}  = obj.params
                    RecipesService.addPanel(recipeInstall.id, templateName, data, entity, order, size)
                    break;
                }
                case "openExternalUrl": {
                    const {url}  = obj.params
                    window.open(url)
                }
            }
        }

        //window.addEventListener("message", messageHandler)    

        

        return () => {

            frame.current?.removeEventListener("load", frameLoadHandler)
        }
    }, [frame, token])

    const drawBody = () => {
        if (/* recipe.version < 2 || */ !templateUrl) {
            return (
                <div className={classes.sorry}>This miniapp is only available on the Klutch App for now</div>
            )
        } else {
            if (!token) {
                return (<></>)
            }
            return (
                <iframe title={recipe.name} ref={frame as LegacyRef<HTMLIFrameElement>} className={classes.iframe}  src={templateUrl} allow="camera;"></iframe>
            )
        }
    }
    
    return (
        <div className={`${classes.panel} ${classes[panel.size]}`}>
            <h2>{panel?.recipeInstall.recipe?.name}</h2>
            <div className={classes.body}>
                {drawBody()}                
            </div>
        </div> 
    )
}   