import { RevolvingLoan, RevolvingLoanService } from "@klutch-card/klutch-js"
import { useEffect, useState } from "react"

export default function useRevolvingLoan(callback?: (revolvingLoan: RevolvingLoan) => void, refreshCache = false) {
    const [revolvingLoan, setRevolvingLoan] = useState<RevolvingLoan | null>(null)
    
    useEffect(() => {
       const subscription = RevolvingLoanService.addSubscriber(r => {
        setRevolvingLoan(r)
           if (r) {
               if (callback) {
                   callback(r)
               }
           }
       }, refreshCache) 
       return () => subscription.unsubscribe()
    }, [revolvingLoan?.id, revolvingLoan?.approvalStatus])
    return revolvingLoan
}