import { EcommerceService, SKU, SKURecurrence } from "@klutch-card/klutch-js"
import { Modal } from "@klutchcard/klutch-webcomponents"
import classes from "./PaymentsModal.module.scss"
import { toCurrency } from "@klutchcard/klutch-webcomponents"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { useState } from "react"

export interface PaymentsModalProps {
    show: boolean
    onPaymentCancelled: () => void
    note?: string
    buttonLabel?: string
    sku: SKU    
    onPaid: () => void     
}

export default ({show, onPaid, onPaymentCancelled, sku, note, buttonLabel}: PaymentsModalProps): JSX.Element   => {

    const isSubscription = sku.recurrence != SKURecurrence.ONE_TIME
    const [paid, setPaid] = useState(false)
    const [loading, setLoading] = useState(false)
    
    let term = ""
    switch (sku.recurrence) {
        case SKURecurrence.ANNUALLY: term = "/ year"; break;
        case SKURecurrence.MONTHLY: term = "/ month"; break;           
    }

    const onPaymentPressed = async () => {
        setLoading(true)
        if (isSubscription) 
            await EcommerceService.subscribe(sku.id)
        else {
            await EcommerceService.order(sku.id)
        }
        setPaid(true)
        setTimeout(() => {
            onPaid && onPaid()
            setLoading(false)
        }, 1000)
    }

    const body = () => {
        if (!paid) {
            return (
                <>
                    <div className={classes.priceSection}>
                        <span>{isSubscription ? "Starting today" : "Due today"}</span>
                        <span>{`$ ${toCurrency(sku.price)} ${term}`}</span>
                    </div>
                    <span className={classes.note}>{isSubscription ? "Cancel anytime by going to your Account -> Subscriptions" : note}</span>
                    <span className={classes.disclaimer}>{isSubscription ? `You will be charged $${sku.price} automatically every month on your Klutch credit card` : 
                                "Payment is going to be made on your Kluch credit card"}</span>
                    <div className={classes.buttonBar}>
                        <KButton className={classes.buttonCancel} design="outline"  onClick={onPaymentCancelled}>CANCEL</KButton>                        
                        <KButton 
                            className={classes.buttonPay} 
                            design="outline" loading={loading} 
                            onClick={onPaymentPressed}
                            loadingColor="black"
                            >{buttonLabel || (isSubscription ? "SUBSCRIBE": "PAY")}</KButton>                        
                    </div>                
                </>
            )
        } else {
            return (
                <div className={classes.paidSection}>
                    <span className="material-symbols-outlined">check</span>
                    <span>{isSubscription? "SUBSCRIBED" : "PAID"}</span>
                </div>
            )
        }
    }

    return (
        <Modal show={show} onDismiss={onPaymentCancelled}>
            <div className={classes.mainBox}>
                <h3 className={classes.title}>KLUTCH PAYMENT</h3>
                <div className={classes.skuSection}>
                    <img src={sku.iconUrl} alt="Payment Icon" />
                    <div className={classes.nameDescription}>
                        <span className={classes.name}>{sku.name}</span>
                        <span className={classes.description}>{sku.description}</span>
                    </div>
                </div>
                {body()}
            </div>
        </Modal>
    )
}