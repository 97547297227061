import { Route, Switch } from "react-router-dom"
import { Logo } from "../../components/Logo"
import classes from "./home.module.scss"
import { useEffect } from "react"
import { LoanApprovalStatus, RecipePanel, RevolvingLoanService } from "@klutch-card/klutch-js"
import useRevolvingLoan from "../../hooks/useRevolvingLoan"
import NavBody from "../NavBody"
import { usePanels } from "../../hooks/useRecipes"
import { MiniAppDynamicComponent } from "../../components/MiniAppDynamicComponent/MiniAppDynamicComponent"
import { MiniAppPanelSkeleton } from "../../components/Skeleton/MiniAppSkeleton"
import { Analytics } from "../../services/analytics"
import { PopupView } from "./PopupView"

export const Home: React.FC = () => {

    const revolvingLoan = useRevolvingLoan()

    const panels = usePanels(undefined)?.sort((a: RecipePanel, b: RecipePanel) => (a.order - b.order))

    const maxLimit = revolvingLoan?.approvalStatus == LoanApprovalStatus.APPROVED ?  revolvingLoan?.maxLimit : 0

    return (
        <NavBody title="Home">
           <div className={classes.firstPanelRow}>
                <Panel title="CREDIT LIMIT" >
                    {revolvingLoan?.limit} / {maxLimit}
                </Panel>
                <Panel title="CARD BALANCE" >
                    {revolvingLoan?.balance}
                </Panel>                
           </div>
           <div className={classes.panels}>
                {!panels && (
                    <>
                    <MiniAppPanelSkeleton />
                    <MiniAppPanelSkeleton />
                    <MiniAppPanelSkeleton />
                    </>
                )}
                
                {panels?.filter(p => (p.recipeInstall?.recipe?.version ||1) > 1).map(p => (
                    <MiniAppDynamicComponent panel={p} key={p.id} />
                ))}
                {panels?.filter(p => (p.recipeInstall?.recipe?.version ||1) == 1).map(p => (
                    <MiniAppDynamicComponent panel={p} key={p.id} />
                ))}

           </div>
           <PopupView />
        </NavBody>
    )
}


export const Panel: React.FC<any> = ({title, children}: any) => {
    return (
        <div className={classes.panel} >
            <h2>{title}</h2>
            <div className={classes.body}>
                {children}
            </div>
        </div>
    )
}

export default Home