import { Route, Switch, useLocation } from "react-router-dom"
import { SignUpBar } from "./signupbar"
import { MiniApps } from "./MiniApps"
import { MiniappDetails } from "./MiniAppDetails"
import { MiniAppFullScreen } from "./MiniAppFullScreen"

export const MiniAppsRouter = (): JSX.Element => {
    
    const location = useLocation()

    return (
    <Switch>
        <Route path="/miniapps/:id/*" key={location.key} component={MiniAppFullScreen} />
        <Route path="/miniapps/:id" component={MiniappDetails} />
        <Route path="/miniapps/" component={MiniApps} />
        <SignUpBar />
    </Switch>
)}

export default MiniAppsRouter