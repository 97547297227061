import { UserMessage, UserService } from "@klutch-card/klutch-js"
import { Dialog, Modal } from "@klutchcard/klutch-webcomponents"
import { useEffect, useState } from "react"
import classes from "./PopupView.module.scss"

export const PopupView: React.FC = () => {

    const [message, setMessage] = useState<UserMessage>()

    
    useEffect(() => {
        (async () => {
            const messages = await UserService.getUserMessages()   
            if (messages?.length > 0) {
                const m = messages.filter(c => !c.isRead)
                console.log("M", m)
                if (m) {
                    setMessage(m[0])
                }
                
            }
            
        })()
        
    }, [])

    if (!message) {
        return null
    }

    const closeClicked = async () => {
        if (message) {
            await UserService.markUserMessageAsRead(message.id)            
            setMessage(undefined)
        }
    }

    return (
        <Modal show={true} onDismiss={closeClicked}>
            <div className={classes.container}>
                <div className={classes.header}>
                <h2>{message.body}</h2>
                <span className="material-symbols-outlined" onClick={closeClicked}>close</span>
                </div>
                <iframe src={message.url} className={classes.contentFrame}/>                
            </div>
        </Modal>
    )
}