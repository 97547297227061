import React, { useEffect } from "react"


import classes from "./StateNotAvailable.module.scss"
import { Analytics } from "../../services/analytics";

export const StateNotAvailable: React.FC = () => {

    useEffect(() => {
        Analytics.pushEvent("app_apply_state_not_available"); 
    }, [])
   

    return (
        <div className={classes.container}>            
            <h2>KLUTCH IS NOT YET AVAILABLE IN YOUR STATE</h2>            
            <pre>{`


Thank you for applying for the Klutch Card.




Unfortunately, Klutch cannot currently serve new users residing in your state.

We are working hard to be able to serve you soon.

We will contact you with updates as soon as possible.




No credit inquiry was taken during this process.`}
            </pre>
        </div>
    )
}


export default StateNotAvailable