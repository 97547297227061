import { AccountService, AuthService, GraphQLException, SMSChallenge } from "@klutch-card/klutch-js"
import React, { ChangeEvent, FormEvent,  useRef, useState } from "react"
import { useHistory, useLocation, } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import {KTextInput} from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import { Logo } from "../../components/Logo"
import qs from "qs"

import classes from "./Signup.module.scss"
import { useCookies } from "react-cookie"

export interface LoginPageProps {
    redirectTo?: string
}

const LoginPage: React.FC<LoginPageProps> = ({redirectTo}) => {

    const loc = useLocation()
    const query = qs.parse(loc.search, {ignoreQueryPrefix: true})

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [code, setCode] = useState("")

    const [authChallenge, setAuthChallenge] = useState<SMSChallenge>()

    
    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)

    const inputs =  [useRef<KTextInput>(null), useRef<KTextInput>(null),useRef<KTextInput>(null)]
    
    const history = useHistory()

    

    const handleSubmit = async (e: FormEvent) => {
        setServerError("")
        setLoading(true)
        e.preventDefault()
        const validated = inputs.map( i => {
            const v = i.current?.validate(true)
            if (!i.current) {
                return true
            }

            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)
        
        if (!validated) {
            setLoading(false)
            return false
        }
        try {
            let authResult
            if (code && authChallenge) {
                authResult = await AuthService.respondToChallenge(authChallenge, code)
            } else {
                authResult = await AuthService.signIn(email, password) 
            }            
            if ("ChallengeName" in  authResult)  {
                setLoading(false)
                setAuthChallenge(authResult)
                return
            }
            if (query && query["redirectTo"]) {
                history.replace(query["redirectTo"] as string)
            } else {
                if (redirectTo) {
                    history.replace(redirectTo)
                } else {
                    history.replace("/apply")    
                }                
            }
            
        } catch (e) {
            if (e instanceof GraphQLException) {
                const [alloyException] = e.getAlloyCoreExceptionType()
                if (alloyException == "com.alloycard.core.entities.DuplicateEntityException") {
                    setServerError("An account with this email address already exists")
                    setLoading(false)
                    return
                }                
                if (alloyException == "com.alloycard.core.entities.user.InvalidInviteCodeException") {
                    setServerError("Invalid Referral Code")
                    setLoading(false)
                    return
                }
            }             
            setServerError((e as Error).message)
            setLoading(false)
        }        
        
    }




    const handlePasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {        
        const text = event.target?.value        
        setPassword(text)
        
    }

    const userNameOrAuthChallenge = () => {
        if (!authChallenge) {
            return (
                <>
                <KTextInput 
                    label="EMAIL"
                    name="email"
                    value={email}                    
                    type="email"  
                    onChange={event => setEmail(event.target.value)} 
                    ref={inputs[0]}                   
                    validations={[
                        {type: ValidationType.required, errorMessage: "Email is required"},
                        {type: ValidationType.regex, 
                        config: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        errorMessage: "Please enter a valid email address"}
                        ]}                      
                    />                                           
                <KTextInput 
                    label="PASSWORD"
                    name="password"
                    value={password}                    
                    type="password"
                    ref={inputs[1]}
                    onChange={handlePasswordChanged} 
                    
                    validations={[                            
                        {type: ValidationType.required, errorMessage: "Password is required"}]}                       
                    />                   
                </>
            )
        }
        else {
            return (
                <>
                <KTextInput 
                    label="One Time Code"
                    name="code"
                    value={code}                    
                    type="number"  
                    onChange={event => setCode(event.target.value)} 
                    ref={inputs[2]}                   
                    validations={[
                        {type: ValidationType.required, errorMessage: "Code is required"}                       
                        ]}                       
                    />                 
                </>
            )
        }
    }

    return (
        <div className={classes.container}>   
          <div className={classes.logoContainer}>
            <Logo />         
            <h4>Welcome to Klutch! Please login below.</h4>
          </div>
            <form onSubmit={handleSubmit}>
                <div className={classes.serverError}>{serverError}&nbsp;</div>
                 {userNameOrAuthChallenge()}
                <KButton 
                    loading={loading}                
                    design="primary">LOGIN</KButton>   
                <a style={{marginTop: 10}} href="/auth/forgotpassword">Forgot your password?</a>
            </form>
            <a href="/auth/signup">
                Do not have an account? Click here to Sign up
            </a>
            <span className={classes.disclaimer}>
                By signing in, you accept our <a href="https://www.klutchcard.com/terms-of-service" target="_blank">Terms and Conditions</a> and acknowledge you have read the Klutch <a href="https://klutchcard.com/privacy-policy" target="_blank">Privacy Policy</a>
            </span>

            <span className={classes.version}>
                Version: 2.1.16
            </span>
        </div>
    )
}


export default LoginPage