import React from "react"
import { ChangeEvent, useState } from "react"
import {KTextInput} from "@klutchcard/klutch-webcomponents"
import { ValidationType } from "@klutchcard/klutch-webcomponents"


import classes from "./NewPasswordInput.module.scss"

export interface NewPasswordProps {
    value: string,
    onChange: (text: string) => void  
    label?: string
}


export const NewPasswordInput = React.forwardRef(({value, onChange, label}: NewPasswordProps, ref) => {

    const [passwordReqs, setPassswordReqs] = useState(["", "", ""])

    const handlePasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {        
        const text = event.target?.value        
        onChange(text)
        let newReqs = [classes.passwordreqsRed, classes.passwordreqsRed, classes.passwordreqsRed]
        if (text.length == 0) {
            newReqs = ["", "", ""]
        }
        if (text.length >= 8) {
            newReqs[2] = classes.passwordreqsGreen
        }        
        if (/(?=.*[a-z])(?=.*[A-Z]).*/.test(text)) {
            newReqs[1] = classes.passwordreqsGreen
        }
        if (/(?=.*[A-Za-z])(?=.*[0-9])/.test(text)) {
            newReqs[0] = classes.passwordreqsGreen
        }
        setPassswordReqs(newReqs)    
        
    }
    
    return (
        <>
            <KTextInput 
                label={label || "PASSWORD"}
                name="password"
                value={value}                    
                type="password"
                ref={ref as any}
                onChange={handlePasswordChanged} 
                
                validations={[                            
                    {type: ValidationType.regex, config: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}.*$/, errorMessage: ""}]}                       
                />
                <ul className={classes.passwordReqs}>
                    <li className={passwordReqs[0]}>Must include letters and numbers</li>
                    <li  className={passwordReqs[1]}>Must include lower and upper cases</li>
                    <li  className={passwordReqs[2]}>Must be at lest 8 characters</li>
                </ul>        
        </>
    )
})