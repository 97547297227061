import { useParams } from "react-router-dom"
import NavBody from "../NavBody"
import { useRecipe, useRecipeInstall } from "../../hooks/useRecipes"
import { MiniAppDynamicComponent } from "../../components/MiniAppDynamicComponent/MiniAppDynamicComponent"
import { RecipePanel, RecipePanelSize, RecipesService } from "@klutch-card/klutch-js"
import { useEffect } from "react"
import classes from "./MiniAppFullScreen.module.scss"

export const MiniAppFullScreen = (): JSX.Element => {

    const p = useParams()    
    const { id, "0": templateName } = useParams<{ id: string, "0": string }>()

    const [miniapp] = useRecipe(id)
    
    const recipeInstall = useRecipeInstall(id)
    
     if (!recipeInstall) {
        return (<></>)
    }
    
    
    return (
        <div className={classes.main}>
            <MiniAppDynamicComponent 
                panel={new RecipePanel({recipeInstall, templateFileName: templateName, size: RecipePanelSize.FULLSCREEN})}
            />
        </div>
    )
}