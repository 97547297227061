import {  Route, Switch } from "react-router-dom"
import { AccountPage } from "./AccountPage"
import { ReferAFriend } from "./ReferAFriend"
import { SecurityAndLegal } from "./SecurityAndLegal"
import { CreditTerms } from "./CreditTerms"
import { Logout } from "./Logout"
import { UpdateDetails } from "./UpdateDetails"
import { ChangePassword } from "./ChangePassword"
import { Statements } from "./Statements"



export const AccountsRouter = (): JSX.Element => {

    return (

        <Switch>            
            <Route path="/account/statements" component={Statements} />
            <Route path="/account/refer-a-friend" component={ReferAFriend} />
            <Route path="/account/security-and-legal" component={SecurityAndLegal} />
            <Route path="/account/change-password" component={ChangePassword} />
            <Route path="/account/credit-terms" component={CreditTerms} />
            <Route path="/account/logout" component={Logout} />
            <Route path="/account/edit" component={UpdateDetails} />
            <Route path="/account" component={AccountPage} />
        </Switch>
    )
}