import NavBody from "../NavBody"



export const CreditTerms = (): JSX.Element => { 

    return (
        <NavBody title="Credit Terms">
            <span>Credit Limit</span>
            <span></span>
        </NavBody>
    )
}