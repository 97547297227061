import { Card, CardMedia } from "@klutch-card/klutch-js"
import classes from "./index.module.scss"
import { Logo } from "../Logo"

export interface CardImageProps {
    card?: Card,
    cardNumber?: string,
    cvv?: string,
    expirationDate?: string
}

export default ({card, cardNumber, cvv, expirationDate}: CardImageProps) => {


    const cardColor = card?.color.substring(1) ||"2B2B2B"
    const fontColor = cardColor == "2B2B2B" ? "#e0e0e0" : "black"

    const copyNumber = (text?: string) => {
        navigator.clipboard.writeText(text || "");
    }


    return (
    <div className={classes.cardImage}>
        <img src={`/images/cards/${cardColor}.png`} alt="card" />
        <Logo className={classes.logo} color={fontColor} />
        <span className={classes.cardName} style={{color: fontColor}}>{card?.name || "NO NAME"}</span>
        {card?.media == CardMedia.VIRTUAL && (
            <>                
                <span className={classes.cardNumber} onClick={() => copyNumber(cardNumber)} style={{color: fontColor}}>{`${cardNumber?.substring(0, 4) ||"####"}\n${cardNumber?.substring(4, 8) ||"####"}\n${cardNumber?.substring(8, 12) ||"####"}\n${cardNumber?.substring(12, 16) || card?.lastFour || "####"}`}</span>
                <span className={classes.cvv} onClick={() => copyNumber(cvv)} style={{color: fontColor}} >CVV {cvv || "###"}</span>
                <span className={classes.expirationDate} onClick={() => copyNumber(expirationDate)} style={{color: fontColor}}>EXP {expirationDate || "##.####"}</span>
            </>
        )}
    </div>
    )
}