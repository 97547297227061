import { NavLink, Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { Logo } from "../components/Logo"
import classes from "./NavRouter.module.scss"
import Home from "./home/Home"
import TransactionsList from "./transactions/TransactionsList"
import { TransactionRouter } from "./transactions"
import { CardsRouter } from "./cards"
import MiniAppsRouter from "./miniapps"
import { AccountsRouter } from "./account"
import { TransferSourcesRouter } from "./transfersources"
import { PaymentsRouter } from "./payment"
import { NotFound } from "./notfound/NotFound"
import { InfoBanner } from "./InfoBanner"

export const NavRouter: React.FC = () => {

    const history = useHistory()
    


    return (
        <div className={classes.container}>
            <header className={classes.header}>
                <Logo color="white" />
            </header>
            <div className={classes.menu}>
                <NavLink to="/home" activeClassName={classes.selected}>HOME</NavLink>
                <NavLink to="/transactions" activeClassName={classes.selected}>TRANSACTIONS</NavLink>
                <NavLink to="/miniapps" activeClassName={classes.selected}>MINIAPPS</NavLink>
                <NavLink to="/cards" activeClassName={classes.selected}>CARDS</NavLink>
                <NavLink to="/account" activeClassName={classes.selected}>ACCOUNT</NavLink>
                <div className={classes.spacer}></div>
                <div className={classes.links}>
                    Download our App at:
                    <a className={classes.googlePlay} href='https://play.google.com/store/apps/details?id=com.klutchcard.klutchapp&utm_source=app_website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                    </a>
                    <a href="https://apps.apple.com/us/app/klutch-card/id1563986090" className={classes.appleStore}>
                        <object data="/images/applestore-badge.svg" height="50"> </object>
                    </a>
                    Get our Chrome Extension:
                    <a className={classes.webstore} href="https://chrome.google.com/webstore/detail/klutch/ddcafmeiemojonpdpdebbobfemafhfno">
                        <img src="/images/chrome-webstore.png" />
                    </a>
                </div>
            </div>

            <div className={classes.bodyArea}>
                <InfoBanner />
                <Switch>
                    <Route path="/home" component={Home} />
                    <Route path="/transactions" component={TransactionRouter} />
                    <Route path="/cards" component={CardsRouter} />
                    <Route path="/miniapps" component={MiniAppsRouter} />
                    <Route path="/payments" component={PaymentsRouter} />
                    <Route path="/transfersources" component={TransferSourcesRouter} />
                    <Route path="/account" component={AccountsRouter} />
                    <Route exact path="/">
                        <Redirect to="/home" />
                    </Route>
                    <Route path="*" component={NotFound} />        
                </Switch>
            </div>
        </div>
    )
}


export default NavRouter