import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./reset.css"
import "./global.css"
import ChromeExtension from "./ChromeExtension"
import * as Sentry from "@sentry/react";

declare global {
  interface Window { 
    dataLayer: any,
    ReactNativeWebView: any
  }
}



Sentry.init({
  dsn: "https://726cb7d64eec700cf7a5ea1fe8c274cd@o566070.ingest.us.sentry.io/4507058652708864",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== "development"
});



if (process.env.REACT_APP_BUILD_TARGET === "chrome-extension") {
  ReactDOM.render(
    <React.StrictMode>
      <ChromeExtension />
    </React.StrictMode>,
    document.getElementById("root")
  )
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  )
}


