import classes from "./MiniAppSkeleton.module.scss"

export const MiniAppPanelSkeleton: React.FC = () => {
    return (
        <div className={classes.panel}>
            <div className={classes.title} />
             <div className={classes.circle} />
            
            <div className={classes.line} />            
            <div className={classes.line} />     
            <div className={classes.line} />     
        </div>
    )
}