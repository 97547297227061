import * as React from "react";
import {KButton} from "@klutchcard/klutch-webcomponents";
import classes from "./plaidbutton.module.scss"

import {
    usePlaidLink,
    PlaidLinkOptions,
    PlaidLinkOnSuccess,
    PlaidLinkOnExitMetadata,
    PlaidLink,
    PlaidLinkError,
  } from "react-plaid-link";
import { TransferSource, TransferSourceService } from "@klutch-card/klutch-js";
import { useEffect } from "react";


export interface PlaidButtonProps {
    token: string
    onSuccess: (transferSource: TransferSource) => void,
    onError: (error: string) => void;
}
  
export default ({token, onSuccess, onError}: PlaidButtonProps) => {

    const [loading, setLoading] = React.useState(true)

    const plaidSuccess = async  (publicToken: string, metadata: any)  => {
        
        console.log("Succcess", {publicToken, metadata})        
        const resp = await TransferSourceService.addTransferSource(
            "",
            "Plaid",
            {
                publicToken,
                providerName: metadata.accounts[0].name,
                accountId: metadata.accounts[0].id
            }
        )
        onSuccess && onSuccess(resp)
        setLoading(false)
    }

    const plaidError =  (err: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
        console.log("EXIT", err)
        onError && onError(err?.display_message || "")
        setLoading(false)
    }
    

    const config: PlaidLinkOptions = {
        onSuccess: plaidSuccess,
        onExit: plaidError,        
        onEvent: (eventName, metadata) => {undefined},
        token: token,
        //required for OAuth; if not using OAuth, set to null or omit:
       // receivedRedirectUri: window.location.href + "?oauth_state_id=1234",
      };
      const { open, exit, ready } = usePlaidLink(config);



      const openPlaid = () => {
        
        setLoading(true)
        open();
      }

      useEffect(() => {
        if (token) {
            setLoading(false)
        }
      }, [token])

    return (        
        <KButton className={classes.plaidButton} design="primary"  onClick={openPlaid} loading={loading} >CONNECT VIA PLAID</KButton>
    )
}