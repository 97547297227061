import { useEffect, useState } from "react"
import classes from "./CardLists.module.scss"
import { Card, CardLockState, CardMedia, CardStatus, CardsService } from "@klutch-card/klutch-js"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { useHistory } from "react-router-dom"
import NavBody from "../NavBody"
import { LockSwitch } from "@klutchcard/klutch-webcomponents"
import useCards from "../../hooks/useCards"

export const CardsList = (): JSX.Element =>  {

    const cards = useCards()

    

    const [searchString, setSearchString] = useState("")    

    const cardFilter = (c: Card) => {
        if (c.status == CardStatus.TERMINATED) return false;

        if (!searchString) {
            return true
        }

        return c.name?.toUpperCase().startsWith(searchString.toUpperCase()) || c.lastFour?.startsWith(searchString)
    
    }

    return (
    <NavBody title="Cards">
        <div className={classes.container}>
        <form className={classes.actionForm}>
            <div className={classes.searchBox}>
                <svg width={19} height={19} fill="none" className={classes.searchIcon} >
                        <path d="M8.708 15.042a6.333 6.333 0 1 0 0-12.667 6.333 6.333 0 0 0 0 12.667ZM16.626 16.625l-3.443-3.444"
                        stroke="#6B6B6B"
                        strokeWidth={1.583} 
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                        <path d="M16.6264 16.6251L13.1826 13.1814" stroke="#BCBCBC" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                <input type="text" aria-label="Search" value={searchString} onChange={e => setSearchString(e.target.value)} />
            </div>
            <a href="/cards/new">
                <span className={`material-symbols-outlined ${classes.addIcon}`}>add</span>                
            </a>
        </form>
        <ul>

            {cards?.filter(cardFilter).map(c => (
              <DrawCard key={c.id} card={c} />
            ))}
        </ul>
        </div>
    </NavBody>
    )
}


const DrawCard = ({card}: {card: Card}) => {
    
    const history = useHistory()
    const [refreshing, setRefreshing] = useState(false)

    const c = card;

    const onLockStatusChanged = async (locked: boolean) => {
        setRefreshing(true)
        try {
            if (locked)
            await CardsService.lock(card)
            else await CardsService.unlock(card)
        } finally {
            setRefreshing(false)
        }
    }

    let displayStatus = ""
    switch (c.status) {
        case CardStatus.ACTIVATING: displayStatus = "Activating"; break;
        case CardStatus.ACTIVE: displayStatus = "Active"; break;
        case CardStatus.CANCELLING: displayStatus = "Canceling"; break;
        case CardStatus.NOT_ACTIVATED: displayStatus = "Not Activated"; break;
        case CardStatus.PENDING: displayStatus = "Pending"; break;
        case CardStatus.PRINTED: displayStatus = "Printed"; break;
        case CardStatus.PROCESSING: displayStatus = "Processing"; break;
        case CardStatus.TERMINATED: displayStatus = "Terminated"; break;
        
    }

    const cardClicked = () => {
        if (card.status == CardStatus.NOT_ACTIVATED) {
            history.push(`/cards/${c.id}/activate`)
            return
        }
        history.push(`/cards/${c.id}`)
    }

    return (
        <li>
            <a onClick={cardClicked}>
                <div className={classes.cardIcon}>
                    <img  alt="Card Icon" src={`/images/cards/${c.color.replace("#", "")}.png`} />                            
                </div>
                <div className={classes.detailsColumn}>
                    <span  className={classes.cardName}>{c.name}</span>
                    <span>{c.media == CardMedia.VIRTUAL ? "Virtual" : "Physical"} Card</span>                            
                    <span className={classes.last4}>{c.lastFour || displayStatus}</span>
                </div>                                                
                <LockSwitch 
                    locked={ c.lockState == CardLockState.LOCKED || c.lockState == CardLockState.LOCKING }
                    onLockStatusChanged={locked => onLockStatusChanged(locked)} 
                    refreshing={refreshing}
                />
            </a>
        </li>
    )
}