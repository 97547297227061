import { useEffect, useState } from "react"
import { Modal } from "@klutchcard/klutch-webcomponents"
import { CardStatus, TransactionFilter,  TransactionSortOrder, TransactionStatus, TransactionType } from "@klutch-card/klutch-js"
import { DateTime } from "luxon"
import classes from "./TransactionFilter.module.scss"
import { LabelValue, Select } from "@klutchcard/klutch-webcomponents"
import {KButton} from "@klutchcard/klutch-webcomponents"
import useCards from "../../hooks/useCards"
import { useTransactionCategories } from "../../hooks/useTransactions"


export interface TransactionFilterViewProps {
    visible: boolean,
    onDismiss: () => void,
    onSelected: (t: TransactionFilter, sortOrder: TransactionSortOrder) => void
    filter: TransactionFilter
    sortOrder: TransactionSortOrder
}

interface FilterValue {
    label: string,
    value: string
    
}
type FilterValues = Array<FilterValue>

export const sortByFilters = [
    {label: "NEWEST", value: TransactionSortOrder.TRANSACTION_DATE_DESC},
    {label: "OLDEST", value: TransactionSortOrder.TRANSACTION_DATE_ASC}, 
    {label: "LARGEST", value: TransactionSortOrder.AMOUNT_DESC}, 
    {label: "SMALLEST", value: TransactionSortOrder.AMOUNT_ASC}
]


export const TransactionFilterView: React.FC<TransactionFilterViewProps> = ({visible, filter, sortOrder, onDismiss, onSelected}) => {
    const [searchString, setSearchString] = useState("")
    const [dateFilters, setDateFilters] = useState<FilterValues>([])
    const [currentFilter,setCurrentFilter] = useState<TransactionFilter>(filter || {})

    const [currentSortOrder, setCurrentSortOrder] = useState<TransactionSortOrder>(sortOrder)


    useEffect(() => {
        const datetime = DateTime.now().set({day: 1, hour: 0, minute: 0, second: 0, millisecond: 0})
        const currMonth = datetime.month
        
        const months: FilterValues = []

        for (let i=1;i<=12;i++) {
            const curr = datetime.minus({year: currMonth < i ? 1: 0}).set({month: i})
            months.push({
                label: curr.monthShort.toUpperCase(),
                value: curr.toUTC().toISO()
            })
        }        
        setDateFilters(months)
    }, [])

    useEffect(() => {
        setSearchString(filter.merchantName || "" )
        setCurrentFilter(filter)
        setCurrentSortOrder(sortOrder)
    }, [filter, sortOrder])
    
    const statusFilters = Object.keys(TransactionStatus).map(c=> ({label: c, value: c}))
    const typeFilters = Object.keys(TransactionType).map(c => ({label: c, value: c}))
    const categories = useTransactionCategories()  
    
    const cards = useCards()

    
    const onApplyPressed = () => {             
        onSelected && onSelected({...currentFilter, merchantName: searchString}, currentSortOrder)
    }

    const reset = () => {
        setCurrentFilter({})
        setCurrentSortOrder(TransactionSortOrder.TRANSACTION_DATE_DESC)
        setSearchString("")
    }

    

    const cardValues = currentFilter.cardIds?.map(ci => cards?.find(x => x.id == ci))    
        

    return (
        <Modal 
            show={visible}
            onDismiss={onDismiss}>
            <div className={classes.mainBox}>    
                <div className={classes.reset} onClick={reset}>RESET</div>              
                <form>
                    <div className={classes.searchBox}>
                        <svg width={19} height={19} fill="none" className={classes.searchIcon} >
                                <path d="M8.708 15.042a6.333 6.333 0 1 0 0-12.667 6.333 6.333 0 0 0 0 12.667ZM16.626 16.625l-3.443-3.444"
                                stroke="#6B6B6B"
                                strokeWidth={1.583}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                />
                                <path d="M16.6264 16.6251L13.1826 13.1814" stroke="#BCBCBC" strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        <input type="text" aria-label="Search" value={searchString} onChange={e => setSearchString(e.target.value)} />
                    </div>
                    <div className={classes.twoColumns}>
                        <div className={classes.column}>
                            <label>DATE</label>
                            <Select 
                                className={classes.select} 
                                options={dateFilters}
                                arrowColor="#6B6B6B"                                
                                value={currentFilter.startDate ? [DateTime.fromJSDate(currentFilter.startDate).toUTC().toISO()] : undefined}
                                textClassName={classes.selectedText}
                                optionsComponent={((o, selected) => (<div className={`${classes.options}  ${selected && classes.itemSelected}`}>{o.label}</div>))}
                                onValueChanged={o => setCurrentFilter(f => ({...f, startDate: DateTime.fromISO(o.value).toJSDate(), endDate: DateTime.fromISO(o.value).plus({month: 1}).toJSDate()}))}
                            />                        

                            
                            <label>CARD</label>
                            <Select 
                                className={classes.select} 
                                multiselect
                                options={cards?.map(c => ({label: c.name.toUpperCase(), value: c as any})) as LabelValue[]}
                                arrowColor="#6B6B6B"
                                value={cardValues}
                                textClassName={classes.selectedText}
                                optionsComponent={((o, selected) => (<div className={`${classes.options} ${o.value.status == CardStatus.TERMINATED && classes.cardTerminated} ${selected && classes.itemSelected}`}>{o.label}</div>))}
                                onValueChanged={o => setCurrentFilter(f => ({...f, cardIds: o.map((c: any) => c.value.id)}))}
                            />   
                            <label>CATEGORY</label>
                            <Select 
                                className={classes.select} 
                                multiselect
                                options={categories?.map(c => ({label: c.name, value: c.id})) as LabelValue[]}
                                arrowColor="#6B6B6B"
                                value={currentFilter.categoryIds}
                                textClassName={classes.selectedText}
                                optionsComponent={((o, selected) => (<div className={`${classes.options}  ${selected && classes.itemSelected}`}>{o.label}</div>))}
                                onValueChanged={s => setCurrentFilter(f => ({...f, categoryIds: s.map((c: any) => c.value)}))}
                            />                                                         
                        </div>
                        <div className={classes.column}>
                            <label>STATUS</label>
                            <Select 
                                className={classes.select} 
                                options={statusFilters}
                                multiselect
                                arrowColor="#6B6B6B"
                                value={currentFilter.transactionStatus}
                                textClassName={classes.selectedText}
                                optionsComponent={((o, selected) => (<div className={`${classes.options}  ${selected && classes.itemSelected}`}>{o.label}</div>))}
                                onValueChanged={o => setCurrentFilter(f => ({...f, transactionStatus: o.map((x: any) => x.value)}))}
                            />                              
                            <label>TYPE</label>
                            <Select 
                                className={classes.select} 
                                options={typeFilters}
                                multiselect
                                arrowColor="#6B6B6B"
                                value={currentFilter.transactionTypes}
                                textClassName={classes.selectedText}
                                optionsComponent={((o, selected) => (<div className={`${classes.options}  ${selected && classes.itemSelected}`}>{o.label}</div>))}
                                onValueChanged={o => setCurrentFilter(f => ({...f, transactionTypes: o.map((x: any) => x.value)}))}
                            />   
                           <label>SORT BY</label>
                            <Select 
                                className={classes.select} 
                                options={sortByFilters}
                                arrowColor="#6B6B6B"
                                value={currentSortOrder}
                                textClassName={classes.selectedText}
                                optionsComponent={(o => (<div className={classes.options}>{o.label}</div>))}
                                onValueChanged={o => setCurrentSortOrder(o.value as TransactionSortOrder)}
                            />                              
                        </div>
                    </div>
                    <div className={classes.actionBar}>
                        <KButton design="outline" className={classes.button} onClick={() => onDismiss()}>CANCEL</KButton>
                        <KButton design="primary" className={classes.buttonApply} onClick={onApplyPressed}>APPLY</KButton>                        
                    </div>
                </form>                
            </div>
        </Modal>
    )
}