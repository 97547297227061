import { BrowserRouter, Route, Switch, Redirect, useParams, useHistory } from "react-router-dom"
import classes from "./App.module.scss"
import {AuthRouter} from "./pages/auth"
import { useEffect, useState } from "react"
import AlloyJS, { AuthenticationKeys, AuthService, InvalidUserNamePasswordException } from "@klutch-card/klutch-js"
import config from "./config"
import ApplyRouter from "./pages/apply"
import { useLocation } from "react-router-dom"
import {  Analytics } from "./services/analytics"

import NavRouter from "./pages/NavRouter"
import { TokenSwap } from "./pages/TokenSwap"

function App(): JSX.Element {

  const [authState, setAuthState] = useState<AuthenticationKeys | null>()
  const [ready, setReady] = useState(false)
 

  const history = useHistory()

  useEffect(() => {
    AlloyJS.configure({ 
      serverUrl: config.klutchServerUrl,
      userPoolClientId: config.userPoolClientId,
      userPoolServer: config.userPoolServer,      
     });
     

   (async () => {
      const loadedAuth = localStorage.getItem(`${config.userPoolClientId}-AUTH_KEY`)
      if (loadedAuth && loadedAuth != "") {    
        try {
          await AuthService.getAuthToken(loadedAuth)
        } catch (e) {
          if (e instanceof InvalidUserNamePasswordException) {
            history.replace("/auth/login")
          }          
          
        }
       
      }    
      setReady(true)   
    })();

     
  }, [])



  
  
  Analytics.startAnalytics()



  useEffect(() => {
      const subscription = AuthService.addSubscriber(auth => {
        setAuthState(auth)   
        if (auth?.RefreshToken)  {
          localStorage.setItem(`${config.userPoolClientId}-AUTH_KEY`, (auth?.RefreshToken || "") )     
        }
        if (auth?.AccessToken == "") {
          // user logged out
          localStorage.setItem(`${config.userPoolClientId}-AUTH_KEY`, "")     

        } 
      })
      return () => subscription.unsubscribe()
  }, [authState])
  


  const authenticatedRoutes = () => {
    if (authState != null) {
      return (
        <Switch>          
          <Route path="/apply" component={ApplyRouter} />
          <Route exact path ="/" component={ApplyRouter} />
          <Route path="/" component={NavRouter} />            
        </Switch> 
      )
    } else {
      return (
        <Switch>
          <Route exact path="/">
              <Redirect to="/auth/signup" />
          </Route>
          <Route path="/:path*" component={() => {
            const { path } = useParams<{ path: string }>()
            return (
              <Redirect to={`/auth/login?redirectTo=/${path || ""}`} />
            )}} />
        </Switch>
      )
    }
  }

  if (!ready) {
    return (<h1>loading...</h1>)
  }

  return (
    <>       
      <div className={classes.mainDiv}>       
          <Switch>
              <Route path="/r/:code" component={AuthRouter} />
              <Route exact path="/tokenswap" component={TokenSwap} />
              <Route path="/auth" component={AuthRouter} />              
              <Route exact path="/apply/pre-approved">
                <Redirect to="/apply/download-app" />
              </Route>    


              {authenticatedRoutes()}                          
              <Redirect to="/auth/signup" />
          </Switch> 
      </div>
    </>  
  )
}

export default () => (
  <BrowserRouter>
      <App />
  </BrowserRouter>    
)
