import { Entity, Recipe, RecipeInstall, RecipePanel, RecipesService } from "@klutch-card/klutch-js"
import { useEffect, useState } from "react"

let allrecipes: Recipe[] = []

export default function useRecipes(callback?: (recipes: Recipe[]) => void)  {
    const [recipes, setRecipes] = useState<Recipe[] | null>(allrecipes)


    useEffect(() => {
        const subscription = RecipesService.addRecipesSubscriber((r: Recipe[]) => {
            allrecipes = r
            setRecipes(r)
            callback && callback(r)
        })
        return () => subscription.unsubscribe()
    }, [recipes])    
    return recipes
}

export  function useInstalledRecipes(callback?: (recipes: RecipeInstall[]) => void)  {
    const [recipes, setRecipes] = useState<RecipeInstall[] | null>(null)

    useEffect(() => {
        const subscription = RecipesService.addRecipeInstallSubscriber((r: RecipeInstall[]) => {
            setRecipes(r)
            callback && callback(r)
        })
        return () => subscription.unsubscribe()
    }, [])    
    return recipes
}

export function useRecipe(recipeId: string): [Recipe | null, () => void] {
    const [recipe, setRecipe] = useState<Recipe|null>(null)
    const updateRecipe = () => {
        RecipesService.getRecipe(recipeId).then(r => {
            setRecipe(r)
        })
    }
    useEffect(() => {
        updateRecipe()
    }, [recipeId])
    return ([recipe, updateRecipe])
}


export function usePanels(entity: Entity | undefined): RecipePanel[] | null {
    const [panels, setPanels] = useState<RecipePanel[] | null>(null)
    useEffect(() => {
        const sub = RecipesService.addRecipePanelsSubscriber(entity, setPanels)
        return () => sub.unsubscribe()
    }, [entity?.entityID])
    return panels
}

export function useRecipeInstall(recipeId: string): RecipeInstall | undefined {
    const [recipeInstall, setRecipeInstall] = useState<RecipeInstall>()
    useEffect(() => {
        (async () => {
            const recipeInstall = await RecipesService.findRecipeInstallByRecipe(recipeId)
            setRecipeInstall(recipeInstall)
        })()
    }, [recipeId])
    return recipeInstall
}