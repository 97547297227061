import { Redirect, Route, Switch } from "react-router-dom"
import SignUpPage from "./SignUp"
import classes from "./index.module.scss"
import { useParams } from "react-router"
import LoginPage from "./Login"
import ForgotPassword from "./ForgotPassword"
import ProspectPage from "./Prospect"

export const AuthRouter = (): JSX.Element => {
    const { code } = useParams<{ code: string }>()

    return (
    <div className={classes.authDiv}>
        <Switch>
            <Route path="/auth/signup">
                    <Redirect to="/auth/new" />
            </Route>
            <Route path="/auth/login" component={LoginPage} />
            <Route path="/auth/new" component={ProspectPage} />
            <Route path="/auth/forgotpassword" component={ForgotPassword} />
            <Route path="/r/:code"> 
                <Redirect to={`/auth/signup?referralCode=${code}`}  />
            </Route>

            <Redirect to="/auth/signup" />

        </Switch>
    </div>
    )
}
export default AuthRouter