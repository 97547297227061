import { Card, CardsService } from "@klutch-card/klutch-js"
import { useEffect, useState } from "react"

export default function useCards(callback?: (cards: Array<Card>) => void) {
    const [cards, setCards] = useState<Array<Card>|null>(null)

    useEffect(() => {
        const subscription = CardsService.addSubscriber(c => {
            setCards(c)
            if (callback) {
                callback(c)
            }
        })
        return () => subscription.unsubscribe()
    }, [cards])

    useEffect(() => {
        CardsService.getCards()
    }, [])

    return cards
}

export function useCard(cardId: string) {
    const [card, setCard] = useState<Card | undefined>()
    useEffect(() => {
        if (cardId) {
            CardsService.getCardDetails(cardId).then(c => {
                setCard(c)
            })
        }
    }, [cardId])
    return card
}
