import { AccountService, UserService } from "@klutch-card/klutch-js"
import React, {   FormEvent, useRef, useState, useCallback } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import {KTextInput, KChangeEvent } from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"

import classes from "./UploadDocument.module.scss"
import {useDropzone} from "react-dropzone"



const getBase64 = async (file: Blob): Promise<string | undefined> => {
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
  
    return new Promise((reslove, reject) => {
      reader.onload = () => reslove(reader.result as any);
      reader.onerror = (error) => reject(error);
    })
  }


const UploadDocument: React.FC = () => {

    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)

    const [documentName, setDocumentName] = useState("")
    const [documentDescription, setDocumentDescription] = useState("")
    
    const [file, setFile] = useState<File>()

    const history = useHistory()

    const inputs = [useRef<KTextInput>(null)]

    const [uploaded, setUploaded] = useState(false)


    const onDrop = useCallback(async acceptedFiles => {
        console.log(acceptedFiles)
        setFile(acceptedFiles[0])
      }, [])
      const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  


    const handleSubmit = async (e: FormEvent) => {    
        e.preventDefault()
        setLoading(true)
        const validated = inputs.map( i => {
            const v = i.current?.validate(true) 
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setLoading(false)
            return
        }

        
                     
        try {
            if (!file) {
                setServerError("Please select a file")
                setLoading(false)                
                return
            }
            const data = await getBase64(file!) as string
            const type = data.substring(5, data.indexOf(";base64,"))
            const base64 = data.substring(data.indexOf(";base64,") + 8)
            await AccountService.uploadDocument(documentName, documentDescription, type, base64)
           setUploaded(true)
        } catch (e) {
            setLoading(false)
            setServerError((e as Error).message)
        }
                
    }

    const reUpload = () => {
        setServerError("")
        setFile(undefined)
        setDocumentName("")
        setDocumentDescription("")
        setUploaded(false)
        setLoading(false)
    }

    if (uploaded) {
        return (
            <div className={classes.container}>    
            <h1>Upload Document</h1>
            <div className={classes.thankyouparagraphs}>
                <p style={{fontWeight: 400}}>Thank you for uploading your document</p>
                <p>Our team is going to review it and get in touch with you</p>
                <p>If you want to upload another document, please press the button below.</p>
                <KButton onClick={reUpload}
                        design="primary">UPLOAD ANOTHER DOCUMENT</KButton>                    
            </div>    
        </div>
        )
    }



    return (
        <div className={classes.container}>            
            <h1>Upload Document</h1>
            <form onSubmit={handleSubmit}>
                <div className={classes.serverError}>{serverError}&nbsp;</div>
                <h4>Use this form to securely upload documents to us.</h4>
                <KTextInput 
                    label="Document Name" 
                    name="documentName" 
                    value={documentName}
                    ref={inputs[0]}
                    onChange={e => setDocumentName(e.target.value)}
                    validations={[
                        {type: ValidationType.required, errorMessage: "Name is a required field"},
                        {type: ValidationType.minLength, config: 3, errorMessage: "Must contain at least 3 characters"},
                        {type: ValidationType.regex, config: /^([A-Z0-9a-z\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]} 
                />                
                <KTextInput 
                        label="Description (OPTIONAL)" 
                        name="documentDescription"
                        value={documentDescription}
                        onChange={e => setDocumentDescription(e.target.value)}
                />  
                {file ? (
                    <div className={classes.fileName}>
                        File: {file?.name}
                    </div>   
                ) : (           
                    <div  {...getRootProps({className:classes.dropzone})}>
                        <input {...getInputProps()} />
                        {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                    </div>
                )}
                <KButton loading={loading}                    
                    design="primary">UPLOAD</KButton>                                                     
            </form>
        </div>
    )
}


export default UploadDocument