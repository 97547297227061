import { Redirect } from "react-router-dom"
import { useQuery } from "../hooks/useQuery"
import { useEffect, useState } from "react"
import { AuthService, GraphQLService } from "@klutch-card/klutch-js"


export const TokenSwap: React.FC =  () => {

    const query = useQuery()

    const page = query.get("page")

    const token = query.get("token")

    const [authed, setAuthed] = useState(false)
    

    console.log("TOKEN", token)

    useEffect(() => {        
        if (!token) return
        (async () => {
            await AuthService.getAuthToken(token)
            setAuthed(true)
        })()
        
    }, [token])

    

    if (!page) {
        return (<></>)
    }

    if (token && !authed) {
        return (<></>)
    }
    
    return (
        <Redirect to={page} />
    )

}