import { FormEvent, useRef, useState } from "react"
import {KTextInput, KChangeEvent } from "@klutchcard/klutch-webcomponents"
import NavBody from "../NavBody"
import classes from "./VerifyMicroDeposits.module.scss"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { GraphQLException, TransferSourceService, TransferSourceStatus } from "@klutch-card/klutch-js"
import { useHistory,  useParams } from "react-router-dom"

export const VerifyMicroDeposits = (): JSX.Element => {

    const [deposit1, setDeposit1] = useState("")
    const [deposit2, setDeposit2] = useState("")
    const inputs = [useRef<KTextInput>(null), useRef<KTextInput>(null),]
    const [loading, setLoading] = useState(false)
    const [serverError, setServerError] = useState("")
    const [done, setDone] = useState(false)

    const { id } = useParams<{ id: string }>()
    const history = useHistory()

    const changeAmount = (dep: number, e: KChangeEvent) => {
        const value = e.target.value
        const currentValue = value.replace(/[^\d.]/g, "")

        if (dep == 1) {
            setDeposit1(previousValue => {
                if (!previousValue || value.length > previousValue.length) {
                    return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                return ""
            })
        } else {
            setDeposit2(previousValue => {
                if (!previousValue || value.length > previousValue.length) {
                    return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                return ""
            })
        }
    }

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        setServerError("")
        const validated = inputs.map(i => {
            const v = i.current?.validate(true)
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setLoading(false)
            return
        }

        try {
            const resp = await TransferSourceService.validateTransferSource(id, deposit1, deposit2)
            if (resp.status == TransferSourceStatus.ACTIVE) {
                setDone(true)
            } else {
                setServerError("Error validating your account")
            }

        } catch (e) {
            if (e instanceof GraphQLException) {
                setServerError(e.getAlloyDescriptions().join(", "))

            }
        }
        finally {
            setLoading(false)
        }
    }

    const drawBody = () => {
        if (!done) {
            return (
                <form onSubmit={handleFormSubmit}>
                    <p className={classes.error}>{serverError}</p>
                    <KTextInput
                        label="FIRST AMOUNT"
                        value={deposit1}
                        onChange={e => changeAmount(1, e)}
                        ref={inputs[0]}
                        name="deposit1"
                        validations={[
                            { type: ValidationType.required, errorMessage: "Deposit Amount is required" },
                            { type: ValidationType.number, errorMessage: "Deposit Amount has to be a number" },
                        ]}
                    />
                    <KTextInput
                        label="SECOND AMOUNT"
                        value={deposit2}
                        ref={inputs[1]}
                        onChange={e => changeAmount(2, e)}
                        name="deposit2"
                        validations={[
                            { type: ValidationType.required, errorMessage: "Deposit Amount is required" },
                            { type: ValidationType.number, errorMessage: "Deposit Amount has to be a number" },
                        ]}
                    />
                    <KButton design="primary" loading={loading}>VERIFY BANK</KButton>
                </form>
            )
        } else {
            return (
                <div className={classes.done}>
                    <span>Account verified sucessfully</span>
                    <span className={`material-symbols-outlined ${classes.checkmark}`}>done</span>                    
                    <KButton design="primary" onClick={() => history.replace("/payments")}>MAKE A PAYMENT</KButton>
                </div>
            )
        }
    }

    return (
        <NavBody title="Add Bank">
            <div className={classes.main}>
                <p>To validate ownership of the account, please enter the amount of the deposits we made to your account at the bank account provided.</p>
                <p>It may take up to 5 days for the micro-deposits to appear on your bank's statement</p>
                {drawBody()}
            </div>
        </NavBody>
    )
}