import { useParams } from "react-router-dom"
import NavBody from "../NavBody"
import { useEffect, useState } from "react"
import { Entity, RecipePanel, Transaction, TransactionService, TransactionStatus, TransactionType } from "@klutch-card/klutch-js"
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation"
import classes from "./TransactionsDetails.module.scss"
import { DateTime } from "luxon"
import { toCurrency } from "@klutchcard/klutch-webcomponents"
import { StatusBox } from "./TransactionsList"
import { usePanels } from "../../hooks/useRecipes"
import { MiniAppDynamicComponent } from "../../components/MiniAppDynamicComponent/MiniAppDynamicComponent"
import { MiniAppPanelSkeleton } from "../../components/Skeleton/MiniAppSkeleton"

export const TransactionsDetails: React.FC = () => {

    const {transactionId} = useParams<any>()
    const [transaction, setTransaction] = useState<Transaction>()

    const [openDetails, setOpenDetails] = useState(false)

    const panels = usePanels(new Entity({entityID: transactionId,type: "com.alloycard.core.entities.transaction.Transaction"}))?.sort((a: RecipePanel, b: RecipePanel) => (a.order - b.order))

    useEffect(() => {
        (async ()  => {
            const t = await TransactionService.getTransactionDetails(transactionId)
            setTransaction(t)
        })()
    }, [transactionId])


    const t = transaction

    if (!t) {
        return (<LoadingAnimation />)
    }

    const date = DateTime.fromJSDate(transaction.transactionDate)

    
    const cashback = t.transactionType == TransactionType.CHARGE && toCurrency(Math.floor(transaction.amount) * 0.01)
    const details = [
        {name: "Cash back (excludes promotions)", value: cashback},
        {name: "Decline Reason", value: transaction.declineReason.join(", ")},
        {name: "Status", value: transaction.transactionStatus},
        {name: "Appear as", value: transaction.merchantName},
        {name: "Merchant Category Code (MCC)", value: transaction.mcc.code},
        {name: "Merchant Category", value: transaction.mcc.category},
        {name: "Merchant Description", value: transaction.mcc.description},
        {name: "Original Amount", value: transaction.originalAmount},
        {name: "Postal Code", value: transaction.zipCode},
        {name: "Street Address", value: transaction.streetAddress},
        {name: "City", value: transaction.city},
        {name: "State", value: transaction.state},
        {name: "Entry Mode", value: transaction.entryMode},
        {name: "Card Present", value: transaction.cardPresent},
        {name: "Card Holder Present", value: transaction.cardHolderPresent},
        {name: "Merchant Page", value: transaction.merchantOfficialPage},
        {name: "Merchant Phone", value: transaction.merchantPhoneNumber},
        {name: "Merchant ID", value: transaction.merchantId},
        {name: "Card Acceptor ID", value: transaction.cardAcceptorId},
        {name: "Merchant Logo", image: transaction.merchantLogo},        
    ]


    return (
        <NavBody title={t.merchantName}>
            <div className={classes.mainBody}>
                <div className={classes.blackBox} onClick={() => setOpenDetails(c => !c)}>
                    <div className={classes.leftColumn}>
                        <span>{date.toFormat("cccc, LLLL dd, yyyy")}</span>
                        {t.city && <span>{`${transaction.city}, ${transaction.state}`}</span>}
                        <span className={classes.amount}>{toCurrency(t.amount)}</span>                        
                        <div>
                            <div className={classes.cardIcon} style={{backgroundColor: t.card?.color}}/>
                            {t.card && (<span>{t.card?.name} ({t.card.lastFour})</span>)}
                        </div>
                    </div>
                    <div className={classes.rightColumn}>
                        <StatusBox transaction={t} />
                        <div className={classes.arrow}>
                            <span className="material-symbols-outlined">arrow_forward</span>
                        </div>
                    </div>
                </div>

                {openDetails && (
                <div className={classes.detailsBox}>
                    {details.map( det => {
                        if (!det.value && !det.image) {
                            return (<></>)
                        }
                        return ( 
                            <div key={det.name} className={classes.detailsLine}>
                                <span className={classes.name}>{det.name}</span>
                                {det.value && (
                                    <span className={classes.value}>{det.value}</span>
                                )}
                                {det.image && (
                                    <div className={classes.value}>
                                    <img className={classes.merchantLogo} src={det.image as string} alt="merchant logo" />
                                    </div>
                                )}
                            </div>
                        )
                    })}                   
                </div>
                )}
                <div className={classes.miniApps}>
                {!panels && (
                    <>
                    <MiniAppPanelSkeleton />
                    <MiniAppPanelSkeleton />
                    <MiniAppPanelSkeleton />
                    </>
                )}
                    {panels?.map(p => (
                        <MiniAppDynamicComponent panel={p} key={p.id} />
                    ))}
                </div>
            </div>
        </NavBody>
    )

}