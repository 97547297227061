import { KTextInput, ValidationState, ValidationType, toCurrency } from "@klutchcard/klutch-webcomponents"
import NavBody from "../NavBody"
import classes from "./Statements.module.scss"
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react"
import { NewPasswordInput } from "../auth/NewPasswordInput"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { AuthService, RevolvingLoanService, Statement } from "@klutch-card/klutch-js"
import { DateTime } from "luxon"


export const Statements = () : JSX.Element => {


    const [statements, setStatements] = useState<Statement[]>()

    useEffect(() => {
        const run = async () => {
            const statements = await RevolvingLoanService.getStatements()
            setStatements(statements)
        }
        run()
    }, [])



    return (
        <NavBody title="Statements">
            <div className={classes.main}>               
                {statements?.map(s => (
                    <a key={s.id} className={classes.row} href={s.url}>
                        <span className={classes.month}>{DateTime.fromJSDate(s.closingDate).toFormat("MMMM, yyyy")}</span>
                        <span>${toCurrency(s.closingBalance)}</span>
                    </a>
                ))}
            </div>
        </NavBody>
    )
}