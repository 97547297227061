import { Redirect, Route, Switch } from "react-router-dom"
import AddAddress, { availableStates } from "./AddAddress"
import AddAnnualIncome from "./AddAnnualIncome"
import { AddGoals } from "./AddGoals"
import AddPhone from "./AddPhone"
import AddSSN from "./AddSSN"
import { CreditFrozen } from "./CreditFrozen"
import { DownloadApp } from "./DownloadApp"
import { PreApproved } from "./PreApproved"
import PullingCredit from "./PullingCredit"
import { Reject } from "./Reject"
import SecondaryInspection from "./SecondaryInspection"
import {StateNotAvailable} from "./StateNotAvaliable"
import { ThankYou } from "./ThankYou"
import VerifyPhone from "./VerifyPhone"
import Verifying from "./Veryfing"
import classes from "./index.module.scss"
import { useAccount, useUser } from "../../services/klutchHooks"
import {  AccountService, ApprovalStatus, LoanApprovalStatus, RevolvingLoan, RevolvingLoanService, RevolvingLoanType } from "@klutch-card/klutch-js"
import App from "../../App"
import UploadDocument from "./UploadDocument"
import { useEffect, useState } from "react"
import ChoosePlan from "./ChoosePlan"
import { SecurityDeposit } from "./SecurityDeposit"
import { PlaidSecurityDepositTransfer } from "./PlaidSecurityDepositTransfer"
import AdditionalVerificationDocuments from "./AdditionalVerificationDocuments"

export const ApplyRouter = (): JSX.Element => (
    <div className={classes.applyDiv}>
        <Switch>            
            <Route path="/apply/phone" component={AddPhone} />
            <Route path="/apply/verifyPhone" component={VerifyPhone} />
            <Route path="/apply/address" component={AddAddress} />
            <Route path="/apply/state-not-available" component={StateNotAvailable} />
            <Route path="/apply/ssn" component={AddSSN} />
            <Route path="/apply/annual-income" component={AddAnnualIncome} />
            <Route path="/apply/goals" component={AddGoals} />
            <Route path="/apply/verifying" component={Verifying} />
            <Route path="/apply/pulling-credit" component={PullingCredit} />
            <Route path="/apply/verify-document" component={SecondaryInspection} />
            <Route path="/apply/download-app" component={DownloadApp} />
            <Route path="/apply/pre-approved" component={PreApproved} />
            <Route path="/apply/thankyou" component={ThankYou} />
            <Route path="/apply/rejected" component={Reject} />
            <Route path="/apply/report-frozen" component={CreditFrozen} />
            <Route path="/apply/upload-documents" component={UploadDocument} />
            <Route path="/apply/additional-documents" component={AdditionalVerificationDocuments} />
            <Route path="/apply/choose-plan" component={ChoosePlan} />
            <Route path="/apply/security-deposit/plaid" component={PlaidSecurityDepositTransfer} />
            <Route path="/apply/security-deposit" component={SecurityDeposit} />            
            <Route path="/" component={ApplyDecide} />            
        </Switch>
    </div>
)



const ApplyDecide = (): JSX.Element => {
    const account = useAccount()
    const user = useUser()

    const [revolvingLoan, setRevolvingLoan] = useState<RevolvingLoan |  undefined | null>()
    
    useEffect(() => {
        const run = async () => {
            const rl = await RevolvingLoanService.getRevolvingLoan()
            setRevolvingLoan(rl)
        }
        run()
    }, [])
        

    if (!account || !user) {
        return (
            <div>Loading...</div>
        )
    }
    if (revolvingLoan === undefined) {
        return (
            <div>Loading...</div>
        )
    }
    

    console.log("STATUS: ", { account, revolvingLoan, user})


    if (!account || !user) {
        return (
            <Redirect to="/apply/phone" />
        )
    }


    if (account.approvalStatus == ApprovalStatus.SECONDARY_INSPECTION) {
        return (
            <Redirect to="/apply/verify-document" />
        )
    }
    

    if (account.approvalStatus == ApprovalStatus.REJECTED || account.approvalStatus == ApprovalStatus.CLOSED) {
        return (
            <Redirect to="/apply/rejected" />
        )
    }
    
    
    if (!user.phone || !user.phoneVerified) {
        return (
            <Redirect to="/apply/phone" />
        )
    }

    if (!user.birthDate) {
        return (
            <Redirect to="/apply/phone" />
        )
    }

    if (!account.address) {
        return (
            <Redirect to="/apply/address" />
        )
    }
    if (!availableStates.includes(account.address.state.toUpperCase())) {
        return (
            <Redirect to="/apply/state-not-available" />
        )
    }

    if (!user.hasSSN) {
        return (
            <Redirect to="/apply/ssn" />
        )
    }    

    if (account.approvalStatus == ApprovalStatus.NOT_VERIFIED) {
        return (<Redirect to="/apply/annual-income" />)
    }

    if (account.approvalStatus == ApprovalStatus.REQUESTED) {
        return (<Redirect to="/apply/verifying" />)
    }

    if (account.approvalStatus == ApprovalStatus.MANUAL_REVIEW) {
        return (<Redirect to="/apply/thankyou" />)
    }

 
    if (account.approvalStatus == ApprovalStatus.WAITING_DOCS) {
        return (<Redirect to="/apply/upload-documents" />)
    }


    if (account.approvalStatus == ApprovalStatus.APPROVED) {


         if (!revolvingLoan) {
            return (<Redirect to="/apply/pulling-credit" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.REJECTED) {
            return (<Redirect to="/apply/choose-plan" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.IN_PROGRESS) {
            return (<Redirect to="/apply/thankyou" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.APPROVED) {
            return (<Redirect to="/home" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.MANUAL_REVIEW) {
            return (<Redirect to="/apply/thankyou" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.PENDING) {
            return (<Redirect to="/apply/thankyou" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.REPORT_FROZEN) {
            return (<Redirect to="/apply/report-frozen" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.WAITING_AGREEMENT) {
            return (<Redirect to="/apply/pre-approved" />)
        } 
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.WAITING_DEPOSIT) {
            return (<Redirect to="/apply/security-deposit" />)
        }
        if (revolvingLoan.approvalStatus == LoanApprovalStatus.APPROVED) {
            return (<Redirect to="/home" />)
        }
    }

    return (
        <Redirect to="/apply/phone" />
    )
    
    
}

export default ApplyRouter
