import {  useHistory } from "react-router-dom"
import classes from "./TransactionsList.module.scss"
import { useEffect, useState } from "react"
import {  Transaction, TransactionFilter, TransactionService, TransactionSortOrder, TransactionStatus, TransactionType } from "@klutch-card/klutch-js"
import NavBody from "../NavBody"
import { DateTime } from "luxon"
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation"
import { TransactionFilterView } from "./TransactionFilterView"
import useCards from "../../hooks/useCards"
import { useTransactionCategories } from "../../hooks/useTransactions"
import _ from "lodash"
import { CategoryChooserView } from "./CategoryChooserView"
import { TransactionCategory } from "@klutch-card/klutch-js/lib/entities/TransactionCategory"
import { toCurrency } from "@klutchcard/klutch-webcomponents"

export const TransactionsList: React.FC = () => {
    const [categoryChooserVisible, setCategoryChooserVisible] = useState(false)
    const [transactionFilterVisible, setTransactionFilterVisible] = useState(false)
    const [currentSwipedTransaction, setCurrentSwipedTransaction] = useState<any>()
    const [refreshing, setRefreshing] = useState(false)
    const [transactions, setTransactions] = useState<Transaction[] | undefined>(undefined)
    const [nextCursor, setNextCursor] = useState<string>()
    const [sortOrder, setSortOrder] = useState<TransactionSortOrder>(TransactionSortOrder.TRANSACTION_DATE_DESC)

    const cards = useCards()
    const categories = useTransactionCategories()


    const DEFAULT_FILTER = {
        transactionStatus: [TransactionStatus.PENDING, TransactionStatus.SETTLED, TransactionStatus.DECLINED]
    }
    const [filter, setFilter] = useState<TransactionFilter>(DEFAULT_FILTER)

    const getTransactions = async (reset = false) => {
        setRefreshing(true)
        const resp = await TransactionService.getTransactionsWithFilter(filter, sortOrder, 10, reset ? "" : nextCursor)
        setNextCursor(resp.nextCursor)
        setTransactions(previous => {
            if (!previous || reset) {
                return resp.list
            }
            return previous.concat(resp.list)
        })

        setRefreshing(false)
    }

    useEffect(() => {
        getTransactions()
    }, [filter, sortOrder])


    const loadMore = () => {
        if (!transactions) {
            return
        }
        getTransactions()
    }

    const onFilterChanged = (filter: TransactionFilter, order?: TransactionSortOrder) => {
        console.log("FILTER:: ", filter)
        setTransactions([])
        setNextCursor("")
        setFilter(filter)
        setTransactionFilterVisible(false)
        if (order) {
            setSortOrder(order)
        }
    }

    const drawFilterButtons = () => {
        let comps: Array<string> = []
        if (filter.merchantName) {
            comps.push(filter.merchantName)
        }
        comps = comps.concat(filter.cardIds?.map(c => cards?.find(s => s.id == c)?.name || "") || [])
        comps = comps.concat(filter.categoryIds?.map(c => (categories?.find(s => s.id == c)?.name || "")) || [])
        if (!(_.isEqual(filter.transactionStatus, DEFAULT_FILTER.transactionStatus))) {
            comps = comps.concat(filter.transactionStatus || [])
        }
        comps = comps.concat(filter.transactionTypes || [])
        if (filter.startDate) {
            comps.push(DateTime.fromJSDate(filter.startDate).monthShort.toUpperCase())
        }
        return comps

    }

    const onCategoryChosen = (t: Transaction) => {
        setCurrentSwipedTransaction(t)
        setCategoryChooserVisible(true)
    }
    const onCategorySelected = async (t: Transaction, c: TransactionCategory) => {
        const tr = await TransactionService.categorizeTransaction(t.id, c.id)
        setCategoryChooserVisible(false)
        setRefreshing(true)
        setTransactions(newT => {
            const index = newT?.findIndex(f => f.id == tr.id)
            if (index == undefined || index < 0) {
                return
            }
            newT?.splice(index, 1, tr)
            return newT
        })
        setRefreshing(false)
    }

    return (
        <NavBody title="Transactions">
            <div className={classes.main}>
                <div className={classes.filter}>
                    <a className={classes.filterButton} onClick={() => setTransactionFilterVisible(true)}>FILTER</a>
                    {drawFilterButtons().map(c => (
                        <div className={classes.filterBox}>{c}</div>
                    ))}
                </div>
                <div className={classes.transactionList}>
                    {transactions?.map(t => (
                        <TransactionItem
                            key={`${t.id}-${t.category?.id}`}
                            transaction={t}
                            onChooseCategory={() => onCategoryChosen(t)} />
                    ))}
                    {refreshing && (<LoadingAnimation color="black" />)}
                    {nextCursor ? (
                        <a className={classes.loadMore} onClick={loadMore}>Load more...</a>
                    ) : (
                        <span>No more Transactions!</span>
                    )}

                </div>
            </div>
            <TransactionFilterView
                visible={transactionFilterVisible}
                filter={filter}
                sortOrder={sortOrder}
                onDismiss={() => setTransactionFilterVisible(false)}
                onSelected={onFilterChanged}
            />
            <CategoryChooserView
                visible={categoryChooserVisible}
                onDismiss={() => setCategoryChooserVisible(false)}
                onSelected={onCategorySelected}
                transaction={currentSwipedTransaction}
            />
        </NavBody>
    )
}

const FilterButton: React.FC<any> = ({ label, onPress }) => {
    return (
        <div onClick={onPress}>{label}</div>
    )
}


const TransactionItem: React.FC<{ transaction: Transaction, onChooseCategory: () => void }> = ({ transaction, onChooseCategory }) => {

    const t = transaction

    const history = useHistory()



    const dateFormated = (datetime: Date) => {
        const date = DateTime.fromJSDate(datetime)

        switch (date.toRelativeCalendar()) {
            case "today":
                return <span>Today at {date.toFormat("hh:mm:ss")}</span>
            case "yesterday":
                return <span>{date.toRelativeCalendar()}</span>
            default:
                return <span>{date.toFormat("LLL dd")}</span>
        }
    }

    return (
        <div className={classes.transactionItem} >
            <div onClick={onChooseCategory} className={`${classes.categories} ${t.category == null && classes.notCategorized}`}>
                {t.category?.name || "CATEGORIZE"}
            </div>
            <div className={classes.columnA} onClick={() => history.push(`/transactions/${t.id}`)}>
                <span className={classes.merchantName}>{t.merchantName}</span>
                <span className={classes.cardName}>{t.card?.name}</span>
                <span className={classes.date}>{dateFormated(t.transactionDate)}</span>
            </div>
            <div className={classes.columnB}>
                <span className={classes.amount}>{toCurrency(t.amount)}</span>
                <StatusBox transaction={t} />

            </div>
        </div>
    )

}

export const StatusBox: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
    const t = transaction
    return (
        <>
            {t.transactionStatus == TransactionStatus.PENDING && (
                <div className={classes.statusBox}>PENDING</div>
            )}
            {t.transactionStatus == TransactionStatus.DECLINED && (
                <div className={`${classes.statusBox} ${classes.declinedBox}`}>DECLINED</div>
            )}
            {t.transactionType == TransactionType.PAYMENT && (
                <div className={`${classes.statusBox} ${classes.paymentBox}`}>PAYMENT</div>
            )}
            {t.transactionType == TransactionType.REFUND && (
                <div className={`${classes.statusBox} ${classes.refundBox}`}>REFUND</div>
            )}
            {t.transactionStatus == TransactionStatus.REVERSED && (
                <div className={`${classes.statusBox}`}>REVERSED</div>
            )}
        </>
    )

}

export default TransactionsList