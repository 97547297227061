import { Transaction, TransactionService } from "@klutch-card/klutch-js"
import { TransactionCategory } from "@klutch-card/klutch-js/lib/entities/TransactionCategory"
import { Modal } from "@klutchcard/klutch-webcomponents"
import classes from "./CategoryChooserView.module.scss"
import { useTransactionCategories } from "../../hooks/useTransactions"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { useState } from "react"



export interface CategoryChooserViewProps {
    visible: boolean
    transaction: Transaction
    onDismiss: () => void,
    onSelected: (t: Transaction, category: TransactionCategory) => void
}

export const CategoryChooserView: React.FC<CategoryChooserViewProps> = ({transaction, visible, onDismiss, onSelected}) => { 

    const categories = useTransactionCategories()

    const [currentCategory, setcurrentCategory] = useState<TransactionCategory>({name: "", id: ""})
    const [showEditDelete, setShowEditDelete] = useState<"categories"| "edit" | "delete">("categories")
    

    const colors = [
        {color1: "#639FAF", color2: "#E0D0C2"},
        {color1: "#9FA3AF", color2: "#E2D5D4"},
        {color1: "#77A8C5", color2: "#BAC1D8"},
        {color1: "#6A81BC", color2: "#C6B1B1"},
        {color1: "#578C6D", color2: "#CCB9A8"},
        {color1: "#77A8C5", color2: "#BAC1D8"},
        {color1: "#77A8C5", color2: "#BAC1D8"},
    ]
    
    const editCategoryClicked = (c: TransactionCategory, e: React.MouseEvent ) => {
        e.preventDefault()
        e.stopPropagation()
        setcurrentCategory(c)
        setShowEditDelete("edit")
        return false
    }

    const deleteCategoryClicked = (c: TransactionCategory, e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setcurrentCategory(c)
        setShowEditDelete("delete")
        return false
    }



    const saveCategory = async () => {
        if (!currentCategory?.id) {
            await TransactionService.addTransactionCategory(currentCategory!.name.toUpperCase())
        } else {
            await TransactionService.renameTransactionCategory(currentCategory.id, currentCategory!.name.toUpperCase())            
        }
        setShowEditDelete("categories")
    }

    const performDeleteCategory = async () => {
        await TransactionService.deleteTransactionCategory(currentCategory.id)
        setShowEditDelete("categories")
    }

    const categorySelected = (c: TransactionCategory) => {
        onSelected && onSelected(transaction, c)
    }


    const drawCategories = () => (
        <div className={classes.categories}>
            {categories?.map((c, i) => (
                <div key={c.id} className={classes.itemRow} onClick={() => categorySelected(c)}>
                    <div className={classes.colorBox} style={{background: `linear-gradient(198deg, ${colors[i % colors.length].color1}, ${colors[i % colors.length].color2})`}}/>
                    <span key={c.id} className={classes.item}>{c.name}</span>
                    <div className={classes.toolbox}>
                        <span className="material-symbols-outlined" onClick={e => editCategoryClicked(c, e)}>edit</span>
                        <span className="material-symbols-outlined" onClick={e => deleteCategoryClicked(c, e)}>delete</span>
                    </div>
                </div>
            ))}
        </div>
    )

    const editCategory = () => (
        <div className={classes.editCategory}>
            <form>
                <label>NAME</label>
                <input
                    value={currentCategory?.name}
                    aria-label="NAME"
                    onChange={e => setcurrentCategory({...currentCategory, name: e.target.value})}
                 />
                <div className={classes.actionBar}>
                        <KButton design="primary" className={classes.button} onClick={() => setShowEditDelete("categories")}>CANCEL</KButton>
                        <KButton design="primary" className={classes.buttonApply} onClick={saveCategory} >SAVE</KButton>                        
                </div>                
            </form>
        </div>
    )

    const deleteCategory = () => (
        <div className={classes.editCategory}>
            <span>Are you sure you want to delete the Category: {currentCategory.name}?</span>
            <div className={classes.actionBar}>
                    <KButton design="primary" className={classes.button} onClick={() => setShowEditDelete("categories")}>CANCEL</KButton>
                    <KButton design="primary" className={classes.buttonDelete} onClick={performDeleteCategory} >DELETE</KButton>                        
            </div>                
        </div>
    )

    return (
        <Modal show={visible} onDismiss={onDismiss}>
            <div className={classes.mainBox}>
                <div className={classes.title}>
                    <span>CHOOSE A CATEGORY</span>
                    <div className={classes.add} onClick={() => { setcurrentCategory({name: "", id: ""}); setShowEditDelete("edit")}}>
                        <span className="material-symbols-outlined">add</span>
                    </div>
                </div>
                {showEditDelete  == "edit" && editCategory()}
                {showEditDelete  == "categories" && drawCategories()}
                {showEditDelete == "delete" && deleteCategory()}
                
            </div>
        </Modal>
    )
}


