import classes from "./LoadingAnimation.module.scss";

interface LoadingAnimationProps {
    color?: string
}

export default ({color}: LoadingAnimationProps) => {
    const c = color || "#ffffff"

    return (
    <div className={classes.ldsEllipsis}>
        <div style={{backgroundColor: c}}></div>
        <div style={{backgroundColor: c}}></div>
         <div style={{backgroundColor: c}}></div>
        <div style={{backgroundColor: c}}></div>
    </div>
)
}