import { TransferSource, TransferSourceService } from "@klutch-card/klutch-js"
import { useEffect, useState } from "react"

export function useTransferSources(callback?: (transferSources: TransferSource[]) => void) {
    const [transferSources, setTransferSources] = useState<TransferSource[] | null>(null)

    useEffect(() => {
        const subscription = TransferSourceService.addTransferSourcesSubscriber((a: TransferSource[]) => {
            setTransferSources(a)
            if (a) {
                callback && callback(a)
            }
        })
        return () => subscription.unsubscribe()
    })
    return transferSources
}