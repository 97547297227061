import { useEffect, useState } from "react"
import Plaidbutton from "../../components/PlaidButton/plaidbutton"
import NavBody from "../NavBody"
import classes from "./AddInstant.module.scss"
import {  TransferSourceService } from "@klutch-card/klutch-js"
import { useHistory } from "react-router-dom"



/* 
    MAKE SURE TO UPDATE PLAID REDIRECT URLs when UPDATING THIS PAGE.
 */
export const AddInstant = (): JSX.Element => {

    const [linkToken, setLinkToken] = useState("")
    const history = useHistory()
    const [serverError, setServerError] = useState("")

    useEffect(() => {        

        (async () => {
            const linkToken = (await TransferSourceService.getTransferSourceConfig("Plaid", {
                redirectUrl: window.location.href
            })).linkToken
            setLinkToken(linkToken)
        })()                        
    }, [])

    const onPlaidSuccess = () => {              
       history.replace("/transfersources")
    }

    const onPlaidError = (err: string) => {
       setServerError("An error occurred when trying to connect with Plaid.")
       console.log("Plaid Error" , err)
    }
    
    return (
        <NavBody title="Add Bank">
            <div className={classes.main}>
                <p className={classes.error}>{serverError}</p>
                <span>Klutch uses Plaid to verify your bank account information. This information will be used to pay off your Klutch balance.</span>
                <p>Your data is protected with bank-level security. Klutch never receives your bank's authentication information</p>                
                <Plaidbutton
                    token={linkToken}
                    onSuccess={onPlaidSuccess}
                    onError={onPlaidError} />
            </div>
        </NavBody>
    )
}