const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const retry = async (fn: () => Promise<any>, maxRetries: number, delayMs = 500): Promise<any> => {
    try {
        return await fn()
    } catch (err) {
        if (maxRetries <= 0) throw err

        console.log(`retry attempt ${maxRetries}`)
        await delay(delayMs)
        return await retry(fn, maxRetries - 1, delayMs)
    }
}

export { delay, retry }
