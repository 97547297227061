import classes from "./signupbar.module.scss"

export const SignUpBar = (): JSX.Element => {

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <h3>Build your own MiniApp</h3>
        <p>
          Be part of the card movement that changes the status quo.
        </p>
        <a className="signUpButtonContainer" href="http://dev.klutchcard.com">
          DEVELOPER PORTAL
        </a>
      </div>
      <div className={classes.column}>
        <h3>Check out other MiniApp Ideas</h3>
        <p>
          Vote on what we should build next or send us your ideas for miniapps
        </p>
        <a className="signUpButtonContainer" href="https://airtable.com/shrOtSnfiIztXLVw7/tblvMLJAhjVXFbP1J">
          MINIAPP IDEAS
        </a>
      </div>
    </div>
  )
}
