import { Account, AccountService, GraphQLException, UserService } from "@klutch-card/klutch-js"
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"

import classes from "./VerifyPhone.module.scss"
import { Analytics } from "../../services/analytics"
import { useQuery } from "../../hooks/useQuery"
import { Spinner } from "react-activity"
import { retry } from "../../helper"

const VerifyPhone: React.FC = () => {

    const query = useQuery()

    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)    
    const [code, setCode] = useState("")
    const [resendDisabled, setResendDisabled] = useState(false)
    const [resendTimer, setResendTimer] = useState(300)
    const [verifying, setVerifying] = useState(false)

    const phone = query.get("phone") || ""

    const history = useHistory()

    const onVerifyPressed = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        try {
            const user = await  await UserService.changePhone(phone, code)  
            if (user.phoneVerified) {
                Analytics.pushEvent("app_apply_phone_verified"); 
                setVerifying(true)
               // history.push("/apply/address")
                return
            }
        } catch (e) {  
            if (e instanceof GraphQLException) {
                const errorTypes = e.getAlloyCoreExceptionType()     
                if (errorTypes && errorTypes.includes("com.alloycard.core.entities.user.PhoneVerificationException")) {
                  setServerError("The code you typed does not match the one we sent you. Click the button to receive another one")
                } else if (errorTypes && errorTypes.includes("com.alloycard.core.entities.DuplicateEntityException")) {
                    setServerError("ALREADY_EXISTS")
                } else {
                  setServerError(e.message)
                }                
            } else {
                setServerError((e as Error).message)
              }        
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!verifying) {
            return
        }

        (async () => {
            try {
                const hasAddress = await retry(async () => {
                    console.log("retry attempt")                    
                    const account = await AccountService.getAccount()
                    if (account.address) {
                        return account.address
                    }
                    throw new Error()                                                            
                }, 6, 500)   
                history.push("/apply/address")
            } catch (e) {
                console.error(e)
                history.push("/apply/address")
            }    
        })()

    }, [verifying])

    const onResendPressed = async () => {
        setResendDisabled(true)
        await UserService.changePhone(phone)
        const i = setInterval(() => {
            setResendTimer(o => {
                const next = o - 1
                console.log(o)
                if (next < 0) {
                    setResendDisabled(false)
                    setResendTimer(300)
                    clearInterval(i)
                }
                return next
            })
        }, 1000)
    }

    const getResendCaption = () => {
        if (!resendDisabled) {
            return "RE-SEND CODE"
        } 
        const minutes = Math.floor(resendTimer / 60)
        let  seconds = "" + (resendTimer % 60)
        if (seconds.length == 1) {
            seconds = "0" + seconds
        }
        return `${minutes}:${seconds}`
    } 



    const changeCode = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value
        const currentValue = text.replace(/[^\d]/g, "")
        setCode(currentValue)
    }

    if (phone == null) {
        return null
    }

    if (verifying) {
        return (
            <div className={classes.containerVerifying}>            
                <h1>Verifying</h1>
                <span>Please wait while we verify your data. <br />This usually takes less than 5 seconds.</span>            
                <div className={classes.spinnerDiv}>
                    <Spinner size={30}/>
                </div>
                        
        </div>
        )
    }

    return (
        <div className={classes.container}>            
            <h1>Code</h1>
            <form onSubmit={onVerifyPressed}>
                {serverError == "ALREADY_EXISTS" ? (
                    <div className={classes.serverError}>There's already an account using this phone number.
                    To login to the other account, please click on the link below:                        
                        <a href="/auth/login">Log In with a different account</a>
                    </div>    
                ) : (
                    <div className={classes.serverError}>{serverError}&nbsp;</div>    
                )}
                
                <div>{`Please enter the code sent to XXX-XXX-${phone.substring(phone.length - 4)}`}</div>
                <input 
                    className={classes.inputCode}
                    placeholder="000000"
                    value={code}                    
                    onChange={changeCode}
                    maxLength={6}
                    inputMode="numeric"
                />
                <div className={classes.resendLine}>
                    DIDN'T RECEIVE OUR SMS TEXT?                    
                    <KButton loading={loading} design="outline" onClick={onResendPressed} type="button">{getResendCaption()}</KButton>                                                     
                </div>
                <div className={classes.buttonBar}>
                    <KButton loading={loading} className={classes.backButton} onClick={() => history.replace("/apply/phone")} design="outline" >BACK</KButton>                                                     
                    <KButton loading={loading} className={classes.verifyButton} design="primary" >VERIFY</KButton>                                                     
                </div>
            </form>
        </div>
    )
}


export default VerifyPhone