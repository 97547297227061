import { AccountService, AuthService, GraphQLException } from "@klutch-card/klutch-js"
import React, { ChangeEvent, FormEvent,  useRef, useState } from "react"
import { useHistory, useLocation, } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import  {KTextInput}  from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import { Logo } from "../../components/Logo"
import qs from "qs"

import classes from "./Signup.module.scss"
import { useCookies } from "react-cookie"
import { Analytics } from "../../services/analytics"


const ProspectPage: React.FC = () => {

    const loc = useLocation()
    const query = qs.parse(loc.search, {ignoreQueryPrefix: true})

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [referralCode, setReferralcode] = useState(query["referralCode"] as string|| "")

    
    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)

    const inputs =  [useRef<KTextInput>(null), useRef<KTextInput>(null), useRef<KTextInput>(null)]
    
    const [cookies, setCookie, removeCookie] = useCookies(["klutch_attribution"]);

    const [prospectCreated, setProspectCreated] = useState(false)

    const history = useHistory()
    


    const handleSubmit = async (e: FormEvent) => {
        setLoading(true)
        e.preventDefault()
        const validated = inputs.map( i => {
            const v = i.current?.validate(true)
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)
        
        if (!validated) {
            setLoading(false)
            return false
        }
        try {
            await AuthService.createProspect(referralCode, firstName, "", lastName, email)
            
            const attr = cookies["klutch_attribution"]
            if (attr) {
                await AccountService.addExtraData({ 
                    klutchAttributionCookie: attr || "",
                    userAgent: window.navigator.userAgent || "" })
            } else {
                await AccountService.addExtraData({ 
                    userAgent: window.navigator.userAgent || "" })
            }

                                    
            Analytics.pushEvent("app_apply_prospect_created"); 
            setProspectCreated(true)
        } catch (e) {
            if (e instanceof GraphQLException) {
                const [alloyException] = e.getAlloyCoreExceptionType()
                if (alloyException == "com.alloycard.core.entities.DuplicateEntityException") {
                    setServerError("EMAIL_EXISTS")
                    setLoading(false)
                    return
                }                
                if (alloyException == "com.alloycard.core.entities.user.InvalidInviteCodeException") {
                    setServerError("Invalid Referral Code")
                    setLoading(false)
                    return
                }
            }             
            setServerError((e as Error).message)
            setLoading(false)
        }        
        
    }




    const renderBody = () => {
        if (prospectCreated) {
            return (
                <div className={classes.thankYouForApplying}>
                    <span>
                        Thank you for your interest in Klutch.  <br /><br />
                        We will be in touch when it's your turn to apply for the card.                                        
                    </span>
                    <KButton                                    
                    design="primary" onClick={() => window.location.href = "https://www.klutchcard.com"}>BACK TO HOME</KButton>  
                </div>
            )
            
        } else {

            return (
                <form onSubmit={handleSubmit}>
                {serverError === "EMAIL_EXISTS" ? (
                    <div className={classes.serverError}>It looks like you already have an account. <a href="/auth/login">Click here to login</a></div>
                ) : (
                    <div className={classes.serverError}>{serverError}&nbsp;</div>
                )}
                
                <KTextInput 
                    label="FIRST NAME"
                    value={firstName}
                    name="firstName"
                    onChange={event => setFirstName(event.target.value)}
                    ref={inputs[0]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "First name is a required field"},
                        {type: ValidationType.minLength, config: 2, errorMessage: "Must contain at least 2 characters"},
                        {type: ValidationType.regex, config: /^([A-Za-z-\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]}                    
                     />
                <KTextInput 
                    label="LAST NAME"
                    value={lastName}
                    name="lastName"                    
                    onChange={event => setLastName(event.target.value)} 
                    ref={inputs[1]}
                    validations={[
                        {type: ValidationType.required, errorMessage: "Last name is a required field"},
                        {type: ValidationType.minLength, config: 2, errorMessage: "Must contain at least 2 characters"},
                        {type: ValidationType.regex, config: /^([A-Za-z-\s])*$/g, errorMessage: "Please enter a valid name"}
                    ]}

                    />                    
                <KTextInput 
                    label="EMAIL"
                    name="email"
                    value={email}                    
                    type="email"  
                    onChange={event => setEmail(event.target.value)} 
                    ref={inputs[2]}                   
                    validations={[
                        {type: ValidationType.required, errorMessage: "Email is required"},
                        {type: ValidationType.regex, 
                        config: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        errorMessage: "Please enter a valid email address"}
                        ]}                      
                    />      
                <KTextInput 
                    label="REFERRAL CODE (OPTIONAL)"
                    name="referralCode"
                    value={referralCode}                     
                    type="referralCode"  
                    onChange={event => setReferralcode(event.target.value?.toLocaleUpperCase())}                     
                    />                                       
                <KButton 
                    loading={loading}                
                    design="primary">JOIN THE WAITLIST</KButton>                                                                         
            </form>                
            )

        }
    }



    return (
        <div className={classes.container}>   
          <div className={classes.logoContainer}>
            <Logo />         
            <h4>Welcome to Klutch! Join our waitlist below.</h4>
          </div>
            {renderBody()}
            <span className={classes.disclaimer}>
                By joining, you accept our <a href="https://www.klutchcard.com/terms-of-service" target="_blank">Terms and Conditions</a> and acknowledge you have read the Klutch <a href="https://klutchcard.com/privacy-policy" target="_blank">Privacy Policy</a>
            </span>
            <span className={classes.version}>
                Version: 2.1.15
            </span>
        </div>
    )
}


export default ProspectPage