import React, { FormEvent, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import classes from "./AddGoals.module.scss"
import { RevolvingLoanService } from "@klutch-card/klutch-js"


export const CreditFrozen: React.FC = () => {
  const [serverError, setServerError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)

    try {
      await RevolvingLoanService.refreshReport()
      history.push("/apply/pulling-credit")
    } catch (e: any) {
      setServerError("Please try again later")
    }
    setLoading(false)
  }

  return (
    <div className={classes.container}>
      <h1 style={{ fontWeight: "bold" }}>CREDIT FROZEN</h1>
      <div style={{ textAlign: "center" }}>
        It looks like your credit is currently frozen
        <br />
        Please unfreeze your EQUIFAX credit report and press the retry button below.
      </div>

      <form onSubmit={handleFormSubmit} >
        <div className={classes.serverError}>{serverError}&nbsp;</div>
        <KButton loading={loading} design="primary">RETRY NOW</KButton>
      </form>
    </div>
  )
}
