import { Modal } from "@klutchcard/klutch-webcomponents"
import * as Share from "react-share";
import classes from "./ShareModal.module.scss";
import {KButton} from "@klutchcard/klutch-webcomponents";

export interface ShareModalProps {
    title: string
    show: boolean
    onDismiss: () => void
    shareUrl: string
    quote: string
    subject: string
}

export const ShareModal = ({show, onDismiss, title, shareUrl, quote, subject}: ShareModalProps) => {
    return (
        <Modal
                show={show}
                onDismiss={onDismiss}>
                <div className={classes.shareMain}>
                    <span className={classes.shareTitle}>{title}</span>
                    <div className={classes.shareIcons}>
                        <Share.EmailShareButton url={shareUrl} subject={subject} body={quote}>
                            <Share.EmailIcon size={32} round />
                        </Share.EmailShareButton>
                        <Share.FacebookShareButton url={shareUrl} quote={quote}>
                            <Share.FacebookIcon size={32} round />
                        </Share.FacebookShareButton>
                        <Share.TwitterShareButton url={shareUrl} title={quote}>
                            <Share.TwitterIcon size={32} round />
                        </Share.TwitterShareButton>
                        <Share.WhatsappShareButton url={shareUrl} title={quote}>
                            <Share.WhatsappIcon size={32} round />
                        </Share.WhatsappShareButton>
                        <Share.LinkedinShareButton url={shareUrl} title={quote}>
                            <Share.LinkedinIcon size={32} round />
                        </Share.LinkedinShareButton>
                        <Share.RedditShareButton url={shareUrl} title={quote}>
                            <Share.RedditIcon size={32} round />
                        </Share.RedditShareButton>
                    </div>
                    <div className={classes.shareUrl}>
                        <span onClick={() => navigator.clipboard.writeText(shareUrl)}>{shareUrl}</span>
                    </div>
                    <KButton design="primary" className={classes.shareCloseButton} onClick={onDismiss}>CLOSE</KButton>
                </div>
            </Modal>
    )
}