import { Route, Switch } from "react-router-dom"
import classes from "./NavBody.module.scss"
import { PropsWithChildren, useEffect } from "react"
import { RevolvingLoanService } from "@klutch-card/klutch-js"


export interface NavBodyProperties {
    title: string
    className?: string
}

export const NavBody: React.FC<PropsWithChildren<NavBodyProperties>> = ({title, children, className}) => {


    return (
        <div className={`${classes.container} ${className}`}>                   
           <h1>{title}</h1>           
           <div className={classes.body}>
                {children}
           </div>
        </div>
    )
}


export default NavBody