import { useHistory, useParams } from "react-router-dom"
import NavBody from "../NavBody"
import { useRecipe } from "../../hooks/useRecipes"
import classes from "./MiniappDetails.module.scss"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { useMemo, useState } from "react"
import { RecipeComment, RecipesService, SKU, SKURecurrence } from "@klutch-card/klutch-js"
import { DateTime } from "luxon"
import { Modal } from "@klutchcard/klutch-webcomponents"

import { min } from "lodash"
import { ShareModal } from "../../components/ShareModal/ShareModal"
import PaymentsModal from "../../components/Ecommerce/PaymentsModal"

export const MiniappDetails = (): JSX.Element => {

    const { id } = useParams<{ id: string }>()

    const [miniapp, updateMiniApp] = useRecipe(id)
    const isInstalled = miniapp?.isInstalled || false

    const installs = ((miniapp?.installCount || 0) < 1000) ? miniapp?.installCount : `${Math.round((miniapp?.installCount || 0 + 1000) / 1000)}k`

    const screenshots = miniapp?.files?.filter(c => c.fileName.startsWith("/screenshots") || c.fileName.startsWith("/images/screenshots")) ?? null
    const [comments, setComments] = useState<RecipeComment[]>()

    const [isChangingLikeBar, setChangingLikeBar] = useState(false)

    const [showShareModal, setShowShareModal] = useState(false)
    const [showCommentModal, setShowCommentModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [openSubscribeModal, setOpenSubscribeModal]  = useState(false)

    const history = useHistory()


    const findComments = async () => {
        if (miniapp?.id) {
            RecipesService.findComments(miniapp.id).then(c => {
                setComments(c)
            })
        }
    }

    
    useMemo(() => {
        findComments()
    }, [miniapp?.id])

    const likeBarButtonPressed = async (button: "like" | "dislike" | "report", add: boolean) => {
        if (!miniapp || !isInstalled) {
            return
        }
        if (isChangingLikeBar) {
            return;
        }
        setChangingLikeBar(true)
        let likeDate = miniapp.userRecipeMetrics?.likedDate
        let reportDate = miniapp.userRecipeMetrics?.reportedDate
        switch (button) {
            case "like": likeDate = add ? undefined : new Date(); break;
            case "report": reportDate = add ? undefined : new Date(); break
        }

        await RecipesService.changeRecipeMetrics(miniapp.id, likeDate, undefined, reportDate)
        updateMiniApp()
        setChangingLikeBar(false)
    }


    const uninstallMiniapp = async () => {
        if (loading || !miniapp) {return}        
        setLoading(true)                
        const recipeInstall = await RecipesService.findRecipeInstallByRecipe(miniapp?.id)
        await RecipesService.uninstallRecipe(recipeInstall.id)
        setLoading(false)
        updateMiniApp()
    }


    const performInstall = async () => {
        setOpenSubscribeModal(false)
        console.log("HERE!")
        await RecipesService.installRecipe(miniapp!, {})    
        if (miniapp?.version == 1) {
            history.push(`/miniapps/${miniapp?.id}/templates/Main.template`)
        } else {
            history.push(`/miniapps/${miniapp?.id}/main`)
        }
        
    }

    const openInstallClicked = () => {
        setSubmitting(true)
        
        if (isInstalled) {
            if (miniapp?.version == 1) {
                history.push(`/miniapps/${miniapp?.id}/templates/Main.template`)    
            } else {
                history.push(`/miniapps/${miniapp?.id}/main`)    
            }            
            return
        }

        if (miniapp?.sku) {
            setSubmitting(false)
            setOpenSubscribeModal(true)
        } else {
            performInstall()
        }
    }


    const shareUrl = `https://app.klutchcard.com/miniapps/${miniapp?.id}`


    const drawButton = () => {
        if ((miniapp?.version || 0) <= 1) {
            return ( <div className={classes.notAvailable}>AVAILABLE ONLY IN THE KLUTCH APP.</div>)
        }
        if (isInstalled) {
            return (<KButton className={classes.button} design="primary" onClick={openInstallClicked} loading={submitting}>OPEN</KButton>)
        }
        if (miniapp?.sku) {
            let label = ""
            switch (miniapp.sku?.recurrence) {
                case SKURecurrence.MONTHLY: label =  `$${miniapp.sku?.price} / Month`; break;
                case SKURecurrence.ANNUALLY: label =  `$${miniapp.sku?.price} / Year`; break;
                case SKURecurrence.ONE_TIME:  label =  `$${miniapp.sku?.price}`; break;
            }
            return (<KButton className={classes.button} design="primary" onClick={openInstallClicked} loading={submitting}>{label}</KButton>)
        } 
        return <KButton className={classes.button} design="primary" onClick={openInstallClicked} loading={submitting}>INSTALL</KButton>
    }


    return (
        <NavBody title={miniapp?.name || ""}>
            <div className={classes.main}>
                <div className={classes.header}>
                    <img className={classes.icon} src={miniapp?.iconUrl} alt={miniapp?.name} />
                    <span className={classes.description}>{miniapp?.description}</span>
                    {drawButton()} 
                    
                </div>
                <div className={classes.toolbar}>
                    <div className={classes.toolbutton} onClick={() => likeBarButtonPressed("like", !!miniapp?.userRecipeMetrics?.likedDate)}>
                        <span className={`material-symbols-outlined ${miniapp?.userRecipeMetrics?.likedDate ? "filled-icon" : ""}`}>thumb_up</span>
                        <span>{miniapp?.likeCount}</span>
                    </div>
                    <div className={classes.toolbutton} onClick={() => setShowShareModal(true)}>
                        <span className="material-symbols-outlined">share</span>
                        <span>Share</span>
                    </div>
                    <div className={classes.toolbutton}>
                        <span className={classes.install}>{installs}</span>
                        <span>Installs</span>
                    </div>
                    <a className={classes.toolbutton} target="_blank" href={`mailto:help@klutchcard.com?subject=Miniapp Fedeback: ${miniapp?.id}&body=Here is some feedback for this miniapp.`}>
                        <span className="material-symbols-outlined">comment</span>
                        <span>Feedback</span>
                    </a>
                </div>
                <div className={classes.screenshots}>
                    {screenshots?.map(s => (
                        <img src={s.url} key={s.eTag} alt="screenshot"  />
                    ))}
                </div>
                <div className={classes.longDescription}>
                    {miniapp?.longDescription}
                </div>
                <div className={classes.madeBy}>
                    Made by <b>{miniapp?.madeBy}</b>
                </div>
                <div className={classes.comments}>
                    <h4>COMMENTS</h4>
                    {comments?.map(c => (
                        <div className={classes.comment} key={c.id}>
                            <p className={classes.commentText}>{c.comment}</p>
                            <span className={classes.createdAt}>{DateTime.fromISO(c.createdAt as any).toRelative()}</span>
                            <span className={classes.author}>{c.authorDisplayName}</span>
                        </div>
                    ))}
                    <div className={classes.addComment} onClick={() => setShowCommentModal(true)}>
                        <p><span className="material-symbols-outlined">add</span></p>
                    </div>
                </div>
                {isInstalled && (
                    <KButton design="outline" loading={loading} onClick={() => uninstallMiniapp()}>UNISTALL</KButton>
                )}
            </div>
            <ShareModal 
                onDismiss={() => setShowShareModal(false)}
                quote={`Hey! checkout this MiniApp from the Klutch card\n"${miniapp?.description}"`}
                shareUrl={shareUrl}
                subject="Check out this miniapp"
                show={showShareModal}
                title={`Share ${miniapp?.name}`}                
                />            
            <NewCommentBox miniapp={miniapp} show={showCommentModal} onDismiss={() => setShowCommentModal(false)} onSaved={() => {findComments(); setShowCommentModal(false)}} />
            {miniapp?.sku && (
                <PaymentsModal
                    show={openSubscribeModal}
                    onPaid={performInstall}
                    note={miniapp?.description}
                    onPaymentCancelled={() => { setSubmitting(false); setOpenSubscribeModal(false) }}
                    sku={miniapp?.sku}
                />
            )}
        </NavBody>
    )
}


const NewCommentBox = ({miniapp, show, onDismiss, onSaved}: any) => {

    const [commentText, setCommentText] = useState("")
    const [loading, setLoading] = useState(false)

    const saveComment = async () => {
        if (loading) {
            return
        }
        setLoading(true)
        if (!miniapp) {
            return
        }
        await RecipesService.addComment(miniapp?.id, commentText)
        setLoading(false)
        onSaved()
    }

    return (
        <Modal show={show} onDismiss={onDismiss}>
            <div className={classes.commentModal}>
                <span>NEW COMMENT</span>
                <textarea
                    aria-label="comment"
                    value={commentText}
                    onChange={e => setCommentText(e.target.value)}
                /> 
                <KButton className={classes.commentCancel} design="outline" onClick={onDismiss}>CANCEL</KButton>
                <KButton className={classes.commentSave} design="primary" loading={loading} onClick={() => saveComment()}>SAVE</KButton>
            </div>
        </Modal>
    )
}