import React, { FormEvent, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"

import classes from "./AddGoals.module.scss"
import { useCookies } from "react-cookie"
import { AuthService } from "@klutch-card/klutch-js"
import { Analytics } from "../../services/analytics"





export const ThankYou: React.FC = () => {
  const [serverError, setServerError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const [cookies, setCookie, removeCookie] = useCookies(["provider"]);

  const [token, setToken] = useState<any>()


  useEffect(() => {
    Analytics.pushEvent("app_apply_manual_review"); 
    AuthService.addSubscriber(auth => {
      setToken(auth?.RefreshToken)
    })
  

  }, [])

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    if (cookies["provider"] == "mobile") {
      window.ReactNativeWebView.postMessage(JSON.stringify({"action": "goto", "page": "/miniapps", "auth":  token}))
    } else {
      history.push("/miniapps")
    }
    
    setLoading(false)
  }

  return (
    <div className={classes.container}>     
      <h1 style={{ fontWeight: "bold" }}>THANK YOU</h1>
      <div style={{ textAlign: "center" }}>
        We will get back to you shortly.
        <br />
        Check back here or your email for updates. In the meantime, you can see the latest MiniApps.
      </div>

      <form onSubmit={handleFormSubmit} >
        <div className={classes.serverError}>{serverError}&nbsp;</div>
        {/*<KButton loading={loading} design="primary">CHECK OUT MINIAPPS</KButton> */}
      </form>
    </div>
  )
}
