import { AccountService, UserService } from "@klutch-card/klutch-js"
import React, {   FormEvent, useRef, useState, useCallback } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import {KTextInput, KChangeEvent, Select, Dialog } from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"

import classes from "./AdditionalVerificationDocuments.module.scss"
import {useDropzone} from "react-dropzone"




const getBase64 = async (file: Blob): Promise<string | undefined> => {
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
  
    return new Promise((reslove, reject) => {
      reader.onload = () => reslove(reader.result as any);
      reader.onerror = (error) => reject(error);
    })
  }


const documents = [
    {label: "Drivers License", value:"driverslicense"},
    {label: "Passport", value: "passport"},
    {label: "State ID", value: "stateID"},
    {label: "SSN Card", value: "socialSecurityNumber"},
    {label: "Utility Bill", value: "utilityBill"},
    {label: "Phone Bill", value: "phonebill"},
    {label: "Proof of Income", value: "incomeProof"},
    {label: "Pay stub", value: "paystub"},
    {label: "Proof of Address", value: "addressProof"},
    {label: "Other Document", value: "other"},
]

const AdditionalVerificationDocuments: React.FC = () => {


    const [linkedIn, setLinkedIn] = useState("")
    const [twitter, setTwitter] = useState("")
    const [instagram, setInstagram] = useState("")
    const [github, setGithub] = useState("")
    const [tiktok, setTikTok] = useState("")

    const [documentType, setDocumentType] = useState("")
    const [file, setFile] = useState<File>()


    const onDrop = useCallback(async acceptedFiles => {
        console.log(acceptedFiles)
        setFile(acceptedFiles[0])
      }, [])
      const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div className={classes.container}>            
            <h1>Additional Verification</h1>
            <form>

                <h4>Our system uses a variety of information sources to make a decision. 
                    As with many automated system, ours is prone to errors too. 
                    All of those are optional, but they can help us verify your identity and approve you for the card.</h4>
                <div className={classes.fieldList}>
                    DOCUMENTS (OPTIONAL):
                    <KButton design="primary">ADD DOCUMENT</KButton>

                    SOCIAL MEDIA (OPTIONAL):
                    <KButton design="primary">CONNECT SOCIAL MEDIA</KButton>                                    
                </div>                                                
            </form>
            <Dialog
                buttons={[                    
                    {label: "CLOSE", type: "outline", onClick: (()=> console.log("SD"))},
                    {label: "UPLOAD", type: "primary", onClick: (()=> console.log("SD"))}                    
                ]}
                show
                title="Add Document"
                onDismiss={() => console.log("DISMISS")}
            >
                <div className={classes.documentsModalBody}>
                    <label>Document Type:</label>
                    <Select
                        options={documents}
                        className={classes.select}
                        optionsComponent={(op, selected) => <span className={classes.selectOption}>{op.label}</span>}
                        arrowColor="white"
                        value={documentType}
                        onValueChanged={e => setDocumentType(e.value)}
                    ></Select>                    
                    <div  {...getRootProps({className:classes.dropzone})}>
                        <input {...getInputProps()} />
                        {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                    </div>                    
                </div>
            </Dialog>
        </div>        
    )
}


export default AdditionalVerificationDocuments