import { FormEvent, ReactNode, useEffect, useRef, useState } from "react"
import classes from "./AddCard.module.scss"
import { Card, CardColor, CardLockState, CardMedia, CardShippingSpeed, CardStatus, CardsService, Entity, GraphQLException, GraphQLService, LoanApprovalStatus, RecipePanel, SKURecurrence, SKUStatus, SKUType, SpendingLimitPeriod, TransactionService } from "@klutch-card/klutch-js"
import { useHistory, useParams } from "react-router-dom";
import CardImage from "../../components/CardImage";
import {KTextInput} from "@klutchcard/klutch-webcomponents";
import {KButton} from "@klutchcard/klutch-webcomponents";
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import { AccumulatingOverPeriodRuleSpec, Period } from "@klutch-card/klutch-js/lib/entities/TransactionRule";
import NavBody from "../NavBody";
import { Select } from "@klutchcard/klutch-webcomponents";
import { toCurrency } from "@klutchcard/klutch-webcomponents"; 
import { LockSwitch } from "@klutchcard/klutch-webcomponents";
import { defer, filter, repeat, take } from "rxjs";
import gql from "graphql-tag";
import { create } from "lodash";
import PaymentsModal from "../../components/Ecommerce/PaymentsModal";
import { useAccount } from "../../services/klutchHooks";
import { useQuery } from "../../hooks/useQuery";
import { usePanels } from "../../hooks/useRecipes";
import { MiniAppDynamicComponent } from "../../components/MiniAppDynamicComponent/MiniAppDynamicComponent";
import useRevolvingLoan from "../../hooks/useRevolvingLoan";
import useCards from "../../hooks/useCards";

export const AddCard = (): JSX.Element => {

    const { cardId } = useParams<{ cardId: string }>();

    const query = useQuery()

    const revolvingLoan = useRevolvingLoan()

    const initialMedia = query.get("media") || CardMedia.VIRTUAL
    const redirectTo = query.get("redirectTo")

    const [card, setCard] = useState<Card>({
        name: "",
        status: CardStatus.PENDING,
        shippingSpeed: CardShippingSpeed.STANDARD,
        color: CardColor.BLACK,
        media: initialMedia
    } as any)

    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)

    const [cardNumber, setCardNumber] = useState<string | undefined>(undefined)
    const [cvv, setCvv] = useState<string | undefined>(undefined)
    const [expirationDate, setExpirationDate] = useState<string | undefined>(undefined)
    const [showPaymentModal, setShowPaymentModal] = useState(false)

    const panels = usePanels(new Entity({ entityID: cardId || "", type: "com.alloycard.core.entities.card.Card" }))?.sort((a: RecipePanel, b: RecipePanel) => (a.order - b.order))

    const inputs = [useRef<KTextInput>(null)]
    const history = useHistory()

    const cards = useCards()
    

    const editing = cardId != undefined


    const cardShippingOptions = [
        { label: "Standard", value: { name: CardShippingSpeed.STANDARD, cost: 6.00, speed: "1-2 weeks" } },
        { label: "Expedited", value: { name: CardShippingSpeed.EXPEDITED, cost: 45.09, speed: "1-3 business days" } },
        { label: "Overnight", value: { name: CardShippingSpeed.OVERNIGHT, cost: 98.36, speed: "1 business day" } }
    ]

    const GetCardSensitiveData = async (cardId: string): Promise<any> => {
        try {
            const resp = await GraphQLService.mutation(gql` 
            query($cardId: String) {
                card (id: $cardId) {
                    fhkjjkhldhjklas (param:"ghjslhdhjklgfsdjkl")
                }
            }`, { cardId },
        )

        return JSON.parse(resp.card.fhkjjkhldhjklas)
        } catch (e) {
            return undefined
        }
    }

    useEffect(() => {
        if (!cardId) {
            return
        }

        (async () => {
            const card = await CardsService.getCardDetails(cardId)
            setCard(card)
            const spendingControlRuleName = `SpendingRule-${cardId}`
            const rules = await TransactionService.getTransactionRules()
            const spendingLimitRule = rules.find(r => r.name == spendingControlRuleName)
            const p = (spendingLimitRule?.spec as AccumulatingOverPeriodRuleSpec)
            let period: SpendingLimitPeriod | null;
            switch (p?.period) {
                case Period.DAY: period = SpendingLimitPeriod.Day; break;
                case Period.MONTH: period = SpendingLimitPeriod.Month; break;
                case Period.WEEK: period = SpendingLimitPeriod.Week; break;
                case Period.YEAR: period = SpendingLimitPeriod.Year; break;
                default: period = null

            }
            setCard(c => new Card({ ...card, cardControls: { spendingLimit: { period: period, amount: p?.amount } } }))
        })();

       
        if (card.status != CardStatus.ACTIVE && card.status != CardStatus.PENDING && card.status != CardStatus.PROCESSING) {
            return
        }
        
        const source = defer(() => GetCardSensitiveData(cardId))

        const p = source.pipe(
            repeat({ count: 10, delay: 1500 }),
            filter(c => c?.cardNumber),
            take(1)
        )
        p.subscribe((sensitiveData: any) => {
            setCardNumber(sensitiveData.cardNumber)
            setCvv(sensitiveData.cvc)
            const exp = new Date(sensitiveData.exp)
            const expMonth = `${exp.getMonth() + 1}`.padStart(2, "0")
            const expYear = exp.getFullYear()
            setExpirationDate(`${expMonth}.${expYear}`)
        })

    }, [cardId, card.status])


    useEffect(() => {
        const pickAColor = async () => {
            if (editing) {
                return
            }

            if (card.media == CardMedia.PLASTIC) {
                setCard(c => new Card({ ...card, color: CardColor.BLACK }))
                return
            }
            const cards = await CardsService.getCards()
            const initColors = Object.values(CardColor).filter(c => c != CardColor.BLACK && c != CardColor.GRAY).map(c => ({ color: c, qty: 0 }))            
            const qtyPerColor = cards.map(c => c.color).reduce((p, c) => {
                const obj = p.find(x => x.color == c)                                
                if (obj) {
                    obj.qty += 1
                }          
                return p
            },
                initColors)
                .sort((a, b) => a.qty - b.qty)
            setCard(c => new Card({ ...card, color: qtyPerColor[0].color }))
        }
        pickAColor()
    }, [card.media])


    const createCard = async () => {

        try {
            let newCard;
            if (editing) {
                newCard = await CardsService.editCard(card, card.name, card.color, null)

            } else {
                newCard = await CardsService.addCard(card.name, card.media, null, card.color, card.shippingSpeed)
            }


            const spendingLimitPeriod = card.cardControls?.spendingLimit?.period
            const spendingControlRuleName = `SpendingRule-${newCard?.id}`
            if (spendingLimitPeriod) {
                let p = null
                switch (spendingLimitPeriod) {
                    case SpendingLimitPeriod.Day: p = Period.DAY; break;
                    case SpendingLimitPeriod.Week: p = Period.WEEK; break;
                    case SpendingLimitPeriod.Month: p = Period.MONTH; break;
                    case SpendingLimitPeriod.Year: p = Period.YEAR; break;
                }

                await TransactionService.createTransactionRule(spendingControlRuleName, "Spending Limit", [newCard.id],
                    new AccumulatingOverPeriodRuleSpec({ amount: card.cardControls?.spendingLimit?.amount, period: p }))
            } else {
                const currentTrs = await TransactionService.getTransactionRules()
                const rule = currentTrs.find(c => c.name == spendingControlRuleName)
                if (rule) {
                    await TransactionService.deleteTransactionRule(rule.id)
                }
            }
            if (redirectTo) {
                history.replace(redirectTo, newCard)
            } else {
                history.replace(`/cards/${newCard.id}`, newCard)
            }
        } catch (e: any) {
            if (e instanceof GraphQLException) {
                const [alloyException] = e.getAlloyCoreExceptionType()                
                if (alloyException == "com.alloycard.graphql.engine.NotAuthorizedException") {
                    setServerError(e.getAlloyDescriptions()[0])
                    setLoading(false)
                    return                    
                } 
                if (alloyException == "com.alloycard.graphql.engine.SystemAbuseException") {
                    setServerError(e.getAlloyDescriptions()[0])
                    setLoading(false)
                    return
                }
            }
            setServerError(e.message)
        } finally {
            setLoading(false)
        }
    }

    const spendingLimitPeriod = card.cardControls?.spendingLimit?.period

    const onLockStatusChanged = async (locked: boolean) => {
        setRefreshing(true)
        try {
            if (locked) {
                const c = await CardsService.lock(card)
                setCard(card => new Card({ ...card, ...c }))
            }
            else {
                const c = await CardsService.unlock(card)
                setCard(card => new Card({ ...card, ...c }))
            }
        } finally {
            setRefreshing(false)
        }
    }


    const formSubmitted = async (e: FormEvent) => {        
        setServerError("")
        if (loading) return
        setLoading(true)
        e.preventDefault()
        e.stopPropagation()

        const validated = inputs.map(i => {
            const v = i.current?.validate(true)
            if (!i.current) {
                return true
            }

            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setLoading(false)
            return false
        }

        if (!editing && card.media == CardMedia.PLASTIC) {
            if ((cards?.filter(c =>c.media == CardMedia.PLASTIC).length || 0) > 1) {
                setShowPaymentModal(true)
                return    
            }
        }
        await createCard()
    }

    const account = useAccount()

    const { city, state, street, streetNumber, zipCode, complement } = account?.address || {}

    const sku = { id: "PhysicalCard", description: "Order a new Physical card", name: "Physical Card", price: 6.0, skuType: SKUType.FEATURE, status: SKUStatus.PUBLISHED, iconUrl: "https://klutch-public.s3.amazonaws.com/images/cardicon.png", recurrence: SKURecurrence.ONE_TIME };

    switch (card.shippingSpeed) {
        case CardShippingSpeed.EXPEDITED:
            sku.id = "PhysicalCard-Expedited"
            sku.description = "Order a new Physical card (Expedited Fee)"
            sku.price = cardShippingOptions[1].value.cost
            break;
        case CardShippingSpeed.OVERNIGHT:
            sku.id = "PhysicalCard-Overnight"
            sku.description = "Order a new Physical card (Overnight Fee)"
            sku.price = cardShippingOptions[2].value.cost;
            break;

    }

    return (
        <NavBody title={editing ? card.name : "NEW CARD"}>
            <div className={classes.main}>
                <div className={classes.container}>
                    <CardImage
                        card={card}
                        cardNumber={cardNumber}
                        cvv={cvv}
                        expirationDate={expirationDate}
                    />
                    <form className={classes.form} onSubmit={formSubmitted}>
                        <div className={classes.serverError}>{serverError}</div>
                        <KTextInput
                            label="NickName"
                            value={card.name}
                            ref={inputs[0]}
                            onChange={event => setCard(new Card({ ...card, name: event.target.value.toUpperCase() }))}
                            name="name"
                            disabled={editing && card.media == CardMedia.PLASTIC}
                            validations={[
                                { type: ValidationType.required, errorMessage: "Nickname is required" },
                                {
                                    type: ValidationType.minLength,
                                    config: 3,
                                    errorMessage: "Nickname must be at least 3 chars long"
                                }
                            ]}
                        />
                        {!editing && (
                            <>
                                <label>TYPE</label>
                                <div className={classes.selectType}>
                                    <span className={card.media == CardMedia.VIRTUAL ? classes.selected : ""} onClick={() => setCard(c => new Card({ ...c, media: CardMedia.VIRTUAL }))}>VIRTUAL</span>
                                    <span className={card.media == CardMedia.PLASTIC ? classes.selected : ""} onClick={() => setCard(c => new Card({ ...c, media: CardMedia.PLASTIC }))}>PHYSICAL</span>
                                </div>
                            </>
                        )}

                        {editing && (
                            <>
                                <label>LOCK CARD</label>
                                <LockSwitch
                                    locked={card.lockState == CardLockState.LOCKED || card.lockState == CardLockState.LOCKING}
                                    onLockStatusChanged={locked => onLockStatusChanged(locked)}
                                    refreshing={refreshing} />
                            </>
                        )}

                        <div className={classes.spendingLimitDiv}>
                            <label>SPENDING LIMIT</label>
                            <label className={` ${spendingLimitPeriod ? "" : classes.hideInput}`}>AMOUNT</label>
                            <Select
                                value={spendingLimitPeriod}
                                className={`${classes.select} ${classes.spendingLimitSelect}`}
                                arrowColor="#6B6B6B"
                                width="100%"
                                optionsComponent={(option, selected) => <div className={`${classes.option}`}>{option.label}</div>}
                                options={[
                                    { label: "NO LIMIT", value: null },
                                    { label: "PER WEEK", value: SpendingLimitPeriod.Week },
                                    { label: "PER MONTH", value: SpendingLimitPeriod.Month },
                                    { label: "PER YEAR", value: SpendingLimitPeriod.Year },
                                ]}
                                onValueChanged={(option => setCard(new Card({ ...card, cardControls: { spendingLimit: { ...card.cardControls?.spendingLimit, period: option.value } } })))}
                            />
                            <input
                                className={`${spendingLimitPeriod ? "" : classes.hideInput}`}
                                type="number"
                                placeholder="0.00"
                                value={card.cardControls?.spendingLimit?.amount || ""}
                                onChange={event => setCard(new Card({ ...card, cardControls: { spendingLimit: { ...card.cardControls?.spendingLimit, amount: event.target.value } } }))}
                            />
                        </div>
                        {card.media == CardMedia.VIRTUAL ? (
                            <div className={classes.colorDiv}>
                                <label>COLOR</label>
                                <Select
                                    value={card.color}
                                    arrowColor="#6B6B6B"
                                    className={`${classes.select} ${classes.colorSelect}`}
                                    onValueChanged={option => setCard(new Card({ ...card, color: option.value }))}
                                    options={Object.values(CardColor).map(c => ({ label: c, value: c }))}
                                    labelComponent={(option: any) => <div className={classes.cardColor} style={{ marginLeft: -10, backgroundColor: option?.value || "" }} />}
                                    optionsComponent={(option, selected) => (
                                        <div className={classes.colorRow}>
                                            <span className={classes.cardColor} style={{ backgroundColor: option.value }}></span>
                                        </div>)}
                                />
                            </div>
                        ) : !editing && (
                            <div className={classes.shippingSpeed}>
                                <label>SHIPPING</label>
                                <Select
                                    value={cardShippingOptions.find(o => o.value.name == card.shippingSpeed)?.value}
                                    className={`${classes.select} ${classes.spendingLimitSelect}`}
                                    arrowColor="#6B6B6B"
                                    width="100%"

                                    optionsComponent={(option, selected) =>
                                        <div className={`${classes.option} ${classes.shippingOption}`}>
                                            <span >{option.label}</span>
                                            <span>${toCurrency(option.value.cost)}</span>
                                            <span className={classes.shippingSpeedValue}>{option.value.speed}</span>
                                        </div>}
                                    options={cardShippingOptions}
                                    onValueChanged={(option => setCard(new Card({ ...card, shippingSpeed: option.value.name })))}
                                />
                            </div>
                        )}
                        <div className={classes.addToWallet}>
                            You can add this card to your mobile wallet using the Klutch App.
                        </div>
                        <div className={classes.buttonBar}>
                        {revolvingLoan?.approvalStatus == LoanApprovalStatus.APPROVED ? 
                            (
                                <KButton design="primary" loading={loading}>{editing ? "SAVE" : "CREATE"}</KButton>
                            ) : (
                                <div className={classes.notapproved}>FINISH YOUR APPLICATION TO CREATE A CARD</div>
                            )}


                        </div>
                    </form>
                </div>
                <div className={classes.miniApps}>
                    {panels?.map(p => (
                        <MiniAppDynamicComponent panel={p} key={p.id} />
                    ))}
                </div>
            </div>
            <PaymentsModal
                show={showPaymentModal}
                onPaid={createCard}
                note={`Your new card will be shipped to the following address: \n${streetNumber} ${street} - ${complement} ${city}, ${state} ${zipCode}`}
                onPaymentCancelled={() => { setLoading(false); setShowPaymentModal(false); }}
                sku={sku}
            />
        </NavBody>
    )
}

