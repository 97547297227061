import { useHistory, useParams } from "react-router-dom";
import CardImage from "../../components/CardImage"
import NavBody from "../NavBody"
import classes from "./ActivateCard.module.scss"
import { useCard } from "../../hooks/useCards";
import {KTextInput, KChangeEvent } from "@klutchcard/klutch-webcomponents"
import { useState } from "react";
import {KButton} from "@klutchcard/klutch-webcomponents";
import { CardsService } from "@klutch-card/klutch-js";


export const ActivateCard = (): JSX.Element => {
    const { cardId } = useParams<{ cardId: string }>();

    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [serverError, setServerError] = useState("")
    const history = useHistory()    

    const card = useCard(cardId)
    

    const changeValue = (e: KChangeEvent) => {
        const value = e.target.value

        setValue(previousValue => {
            if (value.length > 4) {
                return value.substring(value.length - 4)
            }
            return value
        })
    }

    const activateClicked = async () => {
        setLoading(true)
        if (!card) return
        try {
          await CardsService.activateCard(card, value)      
          history.replace(`/cards/${card.id}`)
        } catch (e: any) {
          const errorTypes = e.getAlloyCoreExceptionType()     
          if (errorTypes && errorTypes.includes("com.alloycard.core.entities.card.CardActivationFailedException")) {
            setServerError("Invalid Entry: The number should match the last four numbers on your physical card")
          } else {
            setServerError(e.message)
          }
          
        }
        setLoading(false)
    }


    return (
        <NavBody title="Activate Card">
            <div className={classes.container}>
                <span className={classes.error}>{serverError}</span>
                <CardImage card={card} />
                <p>When you receive your card in the mail, enter the last four digits of your card number here:</p>
                <input
                    type="number"
                    placeholder="0000"
                    value={value}
                    onChange={changeValue}
                    className={classes.fourLast}
                    maxLength={4}
                />
                <KButton 
                    design="primary" 
                    loading={loading}
                    className={classes.button}
                    onClick={activateClicked}>ACTIVATE</KButton>
            </div>
        </NavBody>
    )
}