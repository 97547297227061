import { AuthService, User, UserService } from "@klutch-card/klutch-js"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import * as Sentry from "@sentry/browser"

let currentUser: User | undefined = undefined

export const Analytics = {
  
  startAnalytics: (): void => {
    const loc = useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {
      console.log("CurrentPage :>> ", loc.pathname)

      let  vPath = loc.pathname
    
      if (vPath.startsWith("/cards/")) {
        vPath = vPath.replace(/\/cards(\/[a-f0-9-]+)?(.*)/gm, "/cards/details$2")
      }
      if (vPath.startsWith("/transactions/")) {
        vPath = vPath.replace(/\/transactions(\/[a-f0-9-]+)?(.*)/gm, "/transactions/details$2")
      }
      if (vPath.startsWith("/transfersources/")) {
        vPath = vPath.replace(/\/transactions(\/[a-f0-9-]+)?(.*)/gm, "/transfersources/details$2")
      }

      if (loc.pathname == "/") { //do not log "/" to not confuse with marketing website
        return
      }


      window.dataLayer.push({
        "event": "page_changed",
        "page_path": loc.pathname,             
        "vPath": vPath,
        "user_id": currentUser?.id
      })
    }, [loc.pathname])

    useEffect(() => {
      const subscription = AuthService.addSubscriber(auth => {
        const run = async () => {
          await (new Promise(e => { setTimeout(() => { e(1) }, 3000) }))
          setIsAuthenticated(!!auth?.AccessToken)
        }
        run()
      })
      return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
      const fun = async () => {
        if (!isAuthenticated) {
          return
        }


        const subs = UserService.addSubscriber(user => {      
          if (user) {
            currentUser = user
            window.dataLayer.push({
                event: "app_identify", 
                "user_id": user?.id,
                email: user?.email, 
                firstName: user?.firstName, 
                lastName: user?.lastName,
                phone: user?.phone              
            })
            Sentry.setUser({ id: user?.id, email: user?.email })

          }
        })

        return () => subs.unsubscribe()
      }
      fun()
    }, [isAuthenticated])
  }, 

  pushEvent: (eventName: string): void =>  {
    const layerObj = {
      "event": eventName
    }
    console.log("LayerObj", layerObj)
    window.dataLayer.push(layerObj)
  }

}





