import { User, UserService } from "@klutch-card/klutch-js"
import { useEffect, useState } from "react"

let prevUser: User | null = null

export default function  useUser(callback?: (user: User) => void, refreshCache = false)  {    
    const [user, setUser] =  useState<User | null>(prevUser)
    useEffect(() => { 
        if (!user) {
            UserService.getUser()
        }        
        const subscription = UserService.addSubscriber(u => {
            setUser(u)
            prevUser = u
            if (u) {                
                if (callback) {
                    callback(u)                
                }                
            }
        }, refreshCache)
        return () => subscription.unsubscribe()                        
    }, [user?.id])

    return user
}