import { Route, Switch } from "react-router-dom"
import { BankAccounts } from "./BankAccounts"
import { AddBank } from "./AddBank"
import { AddInstant } from "./AddInstant"
import { AddManual } from "./AddManual"
import { VerifyMicroDeposits } from "./VerifyMicroDeposits"


export const TransferSourcesRouter =  () : JSX.Element => (
    <Switch>
        <Route path="/transfersources/add/instant" component={AddInstant} />
        <Route path="/transfersources/:id/verify" component={VerifyMicroDeposits} />
        <Route path="/transfersources/add/manual" component={AddManual} />
        
        <Route path="/transfersources/add" component={AddBank} />
        <Route path="/transfersources" component={BankAccounts} />
    </Switch>
)