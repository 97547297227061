
import { BrowserRouter, Route, Switch, Redirect, useParams } from "react-router-dom"
import classes from "./ChromeExtension.module.scss"
import {AuthRouter} from "./pages/auth"
import { useEffect, useState } from "react"
import AlloyJS, { AuthenticationKeys, AuthService } from "@klutch-card/klutch-js"
import config from "./config"
import { useLocation } from "react-router-dom";
import { CardsRouter } from "./pages/cards"

function ChromeExtension(): JSX.Element {

  const [authState, setAuthState] = useState<AuthenticationKeys | null>()
  const [ready, setReady] = useState(false)
 

  const loc = useLocation()  

  useEffect(() => {
    AlloyJS.configure({ 
      serverUrl: config.klutchServerUrl,
      userPoolClientId: config.userPoolClientId,
      userPoolServer: config.userPoolServer,      
     });
     

   (async () => {
      const loadedAuth = (await chrome.storage.local.get(`${config.userPoolClientId}-AUTH_KEY`))[`${config.userPoolClientId}-AUTH_KEY`]
      if (loadedAuth && loadedAuth != "") {        
       await AuthService.getAuthToken(loadedAuth)
      }    
      setReady(true)   
    })();

     
  }, [])

  const env = process.env.NODE_ENV; 





  useEffect(() => {
      const subscription = AuthService.addSubscriber(async auth => {
        setAuthState(auth)   
        if (auth?.RefreshToken)  {
          const obj: any = {}
          obj[`${config.userPoolClientId}-AUTH_KEY`] = auth?.RefreshToken || "";
          await chrome.storage.local.set(obj)
        }
        if (auth?.AccessToken == "") {
          // user logged out
          await chrome.storage.local.remove(`${config.userPoolClientId}-AUTH_KEY`)
        } 
      })
      return () => subscription.unsubscribe()
  }, [authState])
  




  if (!ready) {
    return (<div className={classes.mainDiv}>    <h4>loading...</h4> </div>)
  }


  const authenticatedRoutes = () => {
    if (authState && authState != null) {
      return (
        <>
        <Route path="/cards" component={CardsRouter} />
        <Route path="/">
            <Redirect to="/cards" />
        </Route>
        </>
      )
    } else {
      return (
        <Redirect to="/auth/login?redirectTo=/cards" />
      )
    }
  }
  

  return (
    <>       
      <div className={classes.mainDiv}>       
          <Switch>
              <Route path="/r/:code" component={AuthRouter} />
              <Route path="/auth" component={AuthRouter} />  
              {authenticatedRoutes()}                                       
          </Switch> 
      </div>
    </>  
  )
}

export default () => (
  <BrowserRouter>
      <ChromeExtension />
  </BrowserRouter>    
)
