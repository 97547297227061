import { AccountService, AuthService, GraphQLException } from "@klutch-card/klutch-js"
import React, { ChangeEvent, FormEvent,  useRef, useState } from "react"
import { useHistory, useLocation, } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import  {KTextInput}  from "@klutchcard/klutch-webcomponents"
import { ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import { Logo } from "../../components/Logo"
import qs from "qs"

import classes from "./Signup.module.scss"
import { useCookies } from "react-cookie"
import { NewPasswordInput } from "./NewPasswordInput"

import * as Sentry from "@sentry/browser";

const ForgotPassword: React.FC = () => {

    const loc = useLocation()

    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [password, setPassword] = useState("")

    
    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)

    const inputs =  [useRef<KTextInput>(null)]
    const passwordinput = useRef<KTextInput>(null)
    
    const history = useHistory()


    const handleSubmit = async (e: FormEvent) => {
        setLoading(true)
        e.preventDefault()
        const validated = inputs.map( i => {
            const v = i.current?.validate(true)
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)
        
        if (!validated) {
            setLoading(false)
            return false
        }
        try {
            await AuthService.forgotPassword(email)
            setEmailSent(true)     
            setLoading(false)  
            
        } catch (e) {
            setServerError((e as Error).message)
            setLoading(false)
        }        
        
    }


    const handleCodeSubmit = async (e: FormEvent) => {
        setLoading(true)
        e.preventDefault()
        const validated = passwordinput?.current?.validate(true)
        
        if (!validated) {
            setLoading(false)
            return false
        }
        try {
            await AuthService.resetPassword(email, code, password)
            history.replace("/auth/login")
        } catch (e: any) {
            console.error(e)
            if (e.response.data.__type == "CodeMismatchException") {
                setServerError("Invalid Code, please check your email and try again")
                return
            }
            if (e.response.data.__type == "LimitExceededException") {
                setServerError("You tried too many times, please try again later.")
                return
            }
            setServerError("An error occurred, please contact support at support@klutchcard.com")
            Sentry.captureException(e);
            
        } finally {
            setLoading(false)
        }
        
        
    }




    if (emailSent) {
        return (
        <div className={classes.container}>   
        <div className={classes.logoContainer}>
          <Logo />         
          <h4>If there's an account, we just sent an email to you. Please enter the code you received on your email:</h4>
        </div>
          <form onSubmit={handleCodeSubmit}>
              <div className={classes.serverError}>{serverError}&nbsp;</div>    
              <KTextInput 
                    label="CODE"
                    name="code"
                    value={code}                    
                    type="number"  
                    onChange={event => setCode(event.target.value)} 
                    ref={inputs[0]}                                       
                    />                
                <NewPasswordInput
                    value={password}
                    label="NEW PASSWORD"
                    onChange={text => setPassword(text)}
                    ref={passwordinput}

                />        
              <KButton 
                  loading={loading}                
                  design="primary">LOGIN</KButton>                                                                         
          </form>
      </div>)  
    }

    return (
        <div className={classes.container}>   
          <div className={classes.logoContainer}>
            <Logo />         
            <h4>Please type your email below, we will send you an email to reset your password.</h4>
          </div>
            <form onSubmit={handleSubmit}>
                <div className={classes.serverError}>{serverError}&nbsp;</div>
                <KTextInput 
                    label="EMAIL"
                    name="email"
                    value={email}                    
                    type="email"  
                    onChange={event => setEmail(event.target.value)} 
                    ref={inputs[0]}                   
                    validations={[
                        {type: ValidationType.required, errorMessage: "Email is required"},
                        {type: ValidationType.regex, 
                        config: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        errorMessage: "Please enter a valid email address"}
                        ]}                      
                    />                                                           
                <KButton 
                    loading={loading}                
                    design="primary">SUBMIT</KButton>                                                                         
            </form>

        </div>
    )
}


export default ForgotPassword