import { useState } from "react"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { ShareModal } from "../../components/ShareModal/ShareModal"
import { useUser } from "../../services/klutchHooks"
import NavBody from "../NavBody"
import classes from "./ReferAFriend.module.scss"

export const ReferAFriend  = (): JSX.Element => {

    const user = useUser()

    const code = user?.inviteCode 

    const [showShareModal, setShowShareModal] = useState(false)
    
    return (
        <NavBody title="Invite Friends">
            <div className={classes.main}>
                <span>Invite your friends and family to the Klutch credit card. Your unique referral code is</span>
                <span className={classes.inviteCode}>{code}</span>
                <span>Press the button below to share your unique link</span>
                <KButton design="primary" onClick={() => setShowShareModal(true)}>SHARE MY LINK</KButton>
                <span>Both you and your friend get $20 when your friend does their first transaction.</span>                
            </div>
            <ShareModal 
                onDismiss={() => setShowShareModal(false)}
                quote={"Hey there! I think you would love this new credit card i've been using. Its called Klutch! Apply using this link. Both of us get $20 after your first transaction."}
                shareUrl={`https://app.klutchcard.com/r/${code}`}
                subject="Check out the Klutch Credit Card!"
                show={showShareModal}
                title={"Invite a Friend"}                
                /> 
        </NavBody>
    )
}

