import React, {  FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { KChangeEvent, KTextInput } from "@klutchcard/klutch-webcomponents"
import {  ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import classes from "./PullingCredit.module.scss"
import { AccountService, ApprovalStatus, LoanApprovalStatus, UserService, RevolvingLoanService } from "@klutch-card/klutch-js"
import {DateTime} from "luxon"
import { Spinner } from "react-activity"
import "react-activity/dist/library.css";
import { retry } from "../../helper"


const PullingCredit : React.FC = () => {

    const history = useHistory()

    const decideNextStep = async () => {
        const revolvingLoan = await RevolvingLoanService.getRevolvingLoan()          
        switch (revolvingLoan?.approvalStatus) {
            case LoanApprovalStatus.WAITING_AGREEMENT:
                history.replace("/apply/pre-approved")                
                break;
            case LoanApprovalStatus.WAITING_DEPOSIT:
                history.replace("/apply/security-deposit")
                break;                
            case LoanApprovalStatus.REJECTED:
                history.replace("/apply/choose-plan")
                break;
            case LoanApprovalStatus.REPORT_FROZEN:
                history.push("/apply/report-frozen")
                break;
            case LoanApprovalStatus.MANUAL_REVIEW:
                history.push("/apply/thankyou")
                break;                
            case LoanApprovalStatus.PENDING:
                history.push("/apply/thankyou")
                break
        }  
    }
    
    useEffect(() => {
        const run = async () => {
            try {
                const revolvingLoanStatus = await retry(async () => {
                    console.log("retry attempt")
                    const revolvingLoan = await RevolvingLoanService.getRevolvingLoan()                    
                    if ([LoanApprovalStatus.PENDING, LoanApprovalStatus.IN_PROGRESS, undefined].includes(revolvingLoan?.approvalStatus)) {
                        throw new Error()                    
                    }
                    return revolvingLoan?.approvalStatus
                }, 6, 5000)                
                decideNextStep()
            } catch (e) {
                console.error(e)
                decideNextStep()
            }
        }
        run()
    }, [])



    return (
        <div className={classes.container}>            
            <h1>Verifying</h1>
            <span>Please wait while we verify your identity. <br />This usually takes less than 30 seconds.</span>            
            <div className={classes.spinnerDiv}>
                <Spinner size={30}/>
            </div>
            
            
        </div>
    )
}


export default PullingCredit