import React, {  FormEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {KButton} from "@klutchcard/klutch-webcomponents"
import { KChangeEvent, KTextInput } from "@klutchcard/klutch-webcomponents"
import {  ValidationState, ValidationType } from "@klutchcard/klutch-webcomponents"
import classes from "./AddSSN.module.scss"
import { AccountService, UserService } from "@klutch-card/klutch-js"
import { GraphQLException } from "@klutch-card/klutch-js"

import { Analytics } from "../../services/analytics"


const AddSSN: React.FC = () => {

    const [serverError, setServerError] = useState("")
    const [loading, setLoading] = useState(false)

    const [ssn, setSSN] = useState("")

    const inputs = [useRef<KTextInput>(null)]
    const ssnField = useRef<HTMLInputElement>(null)

    const history = useHistory()
    
    const [showEdit, setShowEdit] = useState(true)

    const [cardType, setCardType] = useState("UNSECURED")

    useEffect(() => {
        (async () => {
            const cardType = await AccountService.getExtraData("cardType")
            setCardType(cardType)            
            const last4 = await UserService.getLast4()
            
            setSSN(last4)
            if (last4) {
                setShowEdit(false)
            }
        })()
    }, [])



  const normalizeSSNInput = (value: string, previousValue: string) => {
    if (!value) return value 
  
    const currentValue = value.replace(/[^\d]/g, "")
    const cvLength = currentValue.length 
  
    if (!previousValue || value.length > previousValue.length) {
          
      if (cvLength < 2) return currentValue       
      if (cvLength < 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`       
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(5, 9)}` 
    }
  }

  const changeSSN = (e: KChangeEvent) => {
    const text = e.target.value
    setSSN(prev => {
        const normalized = normalizeSSNInput(text, prev) ?? text

        return normalized 
    })
  }






    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        const validated = inputs.map( i => {
            const v = i.current?.validate(true) 
            if (v === undefined) {
                return true
            }
            return v == ValidationState.VALID
        }).reduce((prev, curr) => prev && curr)

        if (!validated) {
            setLoading(false)
            return
        }

        const goodSSN = ssn.replace(/[^\d]/g, "").trim()
        if (!goodSSN || goodSSN.length != 9) {
            setServerError("Invalid SSN")
            setLoading(false)
            return
        }
        try {  

            await UserService.changeSSN(goodSSN)
                            
            Analytics.pushEvent("app_apply_ssn_added"); 
            history.push("/apply/annual-income")
        } catch (e) {
            if (e instanceof GraphQLException) {
                const [alloyException] = e.getAlloyCoreExceptionType()
                if (alloyException == "com.alloycard.core.entities.DuplicateEntityException") {
                    setServerError("An account with this SSN already exists")
                    setLoading(false)
                    return
                }                
            }              
            console.error("Error on sending SSN", e)
            setServerError((e as Error).message)                

        }                
        setLoading(false)
    }

    const adultAgo =  new Date((Date.now() - (1000 * 60 * 60 * 24 * 365.25 * 18)))
    const oldestAgo = new Date((Date.now() - (1000 * 60 * 60 * 24 * 365.25 * 100)))

    if (!showEdit) {
        return (
            <div className={classes.container}>            
            <h1>Pre Approval</h1>           
            <h4>{cardType == "UNSECURED" ? "This will not impact your credit score. A hard pull on your credit score only happens after approval and accepting our terms and conditions." : 
                    "This will not impact your credit score. We use this information to verify your identity."}</h4>            
          
                <div className={classes.ssn}>
                <span className={`material-symbols-outlined ${classes.lock}`}>lock</span>                    
                    <label>SSN:</label>
                    <span>****-**-{ssn}</span>
                </div>
                <div className={classes.spacer}></div>
                {cardType == "UNSECURED" && (
                    <>
                        <div className={classes.consentTitle}>Your Consent</div>                
                        <div className={classes.consent}>                
                        You understand that by clicking on “submit” button below,
                        you are providing 'written instructions' to us under the Fair Credit Reporting Act
                        authorizing us to obtain information from your personal credit report or other information from Equifax.
                        You authorize Klutch to obtain such information solely to conduct a pre-qualification for credit.
                        </div>                    
                    </>
                )}

                <div className={classes.buttonBar}>
                    <KButton loading={loading} design="outline" onClick={() => {setSSN(""); setShowEdit(true)}} >CHANGE</KButton>                                                     
                    <KButton loading={loading} design="primary" onClick={() => history.push("/apply/annual-income")} >LOOKS GOOD</KButton>                                                     
                </div>
            </div>
        )
    }


    return (
        <div className={classes.container}>            
            <h1>Pre Approval</h1>
            <h4>This will not impact your credit score. A hard pull on your credit score only happens after approval and accepting our terms and conditions. </h4>            
            <form onSubmit={handleFormSubmit} >
                <div className={classes.serverError}>{serverError}&nbsp;</div>

                <div className={classes.lockPad}>
                    <svg fill="none" width={12} height={14} >
                        <path d="M10.083 6.417H1.917c-.645 0-1.167.522-1.167 1.166v4.083c0 .645.522 1.167 1.167 1.167h8.166c.645 0 1.167-.522 1.167-1.167V7.583c0-.644-.522-1.167-1.167-1.167Z"
                                stroke="#6B6B6B"
                                strokeWidth={1.167}
                                strokeLinecap="round"
                                strokeLinejoin="round" />
                        <path d="M3.083 6.417V4.082a2.917 2.917 0 1 1 5.834 0v2.333"
                                stroke="#6B6B6B"
                                strokeWidth={1.167}
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                    </svg>
                    <KTextInput 
                        label="SSN #"
                        value={ssn}
                        name="ssn"
                        onChange={changeSSN}
                        inputMode="numeric"
                        ref={inputs[0]}
                        inputRef={ssnField}
                        maxLength={11}
                        validations={[
                            {type: ValidationType.required, errorMessage: "SSN is a required field"},
                            {type: ValidationType.regex, config: /^\d{3}-\d{2}-\d{4}$/gm , errorMessage: "SSN format should be xx-xxx-xxxx"}
                        ]} />
                </div>                    
                <div className={classes.consentTitle}>Your Consent</div>                
                <div className={classes.consent}>
                You understand that by clicking on “submit” button below,
                you are providing 'written instructions' to us under the Fair Credit Reporting Act
                authorizing us to obtain information from your personal credit report or other information from Equifax.
                You authorize Klutch to obtain such information solely to conduct a pre-qualification for credit.
                </div>
                <KButton loading={loading} design="primary" >SUBMIT</KButton>                                                     
            </form>
        </div>
    )
}


export default AddSSN