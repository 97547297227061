import { Account, AccountService, User,  UserService } from "@klutch-card/klutch-js"
import { useEffect, useState } from "react"

export function useAccount(): Account | null | undefined {
    const [account, setAccount] = useState<Account | null>()

    useEffect(() => {
        const s = AccountService.addSubscriber(a => {
            setAccount(a)
        })
        return () => s.unsubscribe()
    }, [])

    return account
}

export function useUser(): User | null | undefined {
    const [user, setUser] = useState<User | null>()

    useEffect(() => {
        const s = UserService.addSubscriber(u => {
            setUser(u)
        })
        return () => s.unsubscribe()
    }, [])

    return user
}

