import { useHistory } from "react-router-dom"
import NavBody from "../NavBody"
import classes from "./AddBank.module.scss"

export const AddBank = () : JSX.Element => {


    const history = useHistory()

    return (
        <NavBody title="Add Bank">
            <div className={classes.main}>
                <span>How would you like to link your account?</span>
                <div className={classes.optionBox} onClick={() => history.push("/transfersources/add/instant")}>
                    <h4>Instant Verification (fastest)</h4>
                    <p>Sign in to your bank instantly and verify your bank acount using Plaid</p>
                    <span className="material-symbols-outlined">arrow_forward</span>
                </div>
                <div className={classes.optionBox} onClick={() => history.push("/transfersources/add/manual")}>
                    <h4>Manual Verification</h4>
                    <p>Use your bank's routing and account number. Verification can take up to 4 business days.</p>
                    <span className="material-symbols-outlined">arrow_forward</span>                    
                </div>
                <div className={classes.optionBox}>
                    <h4>Pay via Wire</h4>
                    <p>Pay off yur card from you bank direclty using Klutch's account details</p>
                    <span className="material-symbols-outlined">arrow_forward</span>
                </div>
            </div>            
        </NavBody>
    )
}